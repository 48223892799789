import { getVariable, isNotOffice } from '@/common/env'
import type { DeviceType } from '@/modules/activity/ActInterface'
import { getUserId, getUserToken } from '@/modules/user/storage'
import { api } from '@/services/api'
import type { Response } from '@/services/types'
import atomGtag from '@/utils/gtag/atom-gtag'
import { getN1ServiceBuryData } from '@/utils/tools'
import { projectName } from '@/websites/current/property.json'

import type { AuthQueryArgs } from '../user/userApi'
import type {
  BankruptRechargeCfg,
  CurrencyExchangeRates,
  RechargeFormItem,
  RechargeResponseData,
  WithdrawalMethodDetail,
  WithdrawArgsData,
} from './interface'
import type { RechargeConfigProps } from './walletInterface'
import { isFeatureEnabledV2 } from '@/common/featureSwiitch'

const IS_NOT_OFFICE = isNotOffice()
const OFFICE_METHOD = getVariable('OFFICE_METHOD')
const NOT_OFFICE_METHOD = getVariable('NOT_OFFICE_METHOD')
const API_TYPE = IS_NOT_OFFICE ? NOT_OFFICE_METHOD : OFFICE_METHOD
export const walletApi = api.injectEndpoints({
  endpoints: builder => ({
    // 充值参数
    rechargeArgs: builder.query<
      Response<WithdrawArgsData>,
      AuthQueryArgs & { type: string; currency: string }
    >({
      query: ({ user_id, token, type, currency }) => ({
        url: `/user/recharge_args`,
        method: API_TYPE,
        data: {
          user_id,
          token,
          currency,
        },
      }),
    }),
    // 充值
    recharge: builder.mutation<
      Response<RechargeResponseData>,
      AuthQueryArgs & {
        amount: string
        task_id: string
        device?: DeviceType
        currency: string
        data: Record<string, any>
        task_gift?: number
      }
    >({
      query: data => {
        const actualData = {
          ...data,
          ...getN1ServiceBuryData(),
        }
        try {
          if (projectName === 'N1') {
            atomGtag('event', 'recharge', {
              event_name: 'recharge',
              event_label: 'recharge',
              user_id: actualData.user_id,
              ...(actualData?.adjust || {}),
            })
          }

          /**
           * GA 默认推荐事件
           */
          atomGtag('event', 'purchase', {
            currency: actualData.currency,
            transaction_id: new Date().getTime(),
            value: actualData.amount,
          })
        } catch (error) {}
        return {
          url: `/user/recharge`,
          method: 'POST',
          data: actualData,
        }
      },
      invalidatesTags: ['Wallet', 'UserVipInfo'],
    }),
    // 提现参数
    withdrawArgs: builder.query<
      Response<WithdrawArgsData[]>,
      AuthQueryArgs & { type: string }
    >({
      query: ({ type, user_id, token, currency }) => ({
        url: `/user/withdraw_args`,
        method: API_TYPE,
        data: {
          user_id,
          token,
          currency,
        },
      }),
    }),
    // 提现
    withdraw: builder.mutation<
      any,
      AuthQueryArgs & {
        amount: number
        currency: string
        data: Record<string, string | number>
      }
    >({
      query: data => {
        const actualData = {
          ...data,
          ...getN1ServiceBuryData(),
        }
        try {
          if (projectName === 'N1') {
            atomGtag('event', 'withdraw', {
              event_name: 'withdraw',
              event_label: 'withdraw',
              api_json: JSON.stringify(actualData),
              user_id: actualData.user_id,
              ...(actualData?.adjust || {}),
            })
          }
        } catch (error) {}
        return {
          url: `/user/withdraw`,
          method: 'POST',
          data: actualData,
        }
      },
      invalidatesTags: ['Wallet', 'UserInfo', 'withdrawways'],
    }),
    // 提现方式
    withdrawalMethods: builder.query<
      Response<WithdrawalMethodDetail[]>,
      AuthQueryArgs
    >({
      query: data => ({
        url: isFeatureEnabledV2('withdraw_info_unique')
          ? '/api/v1/platform/asset_order/withdraw_info/listv2'
          : `/user/withdraw_pays`,
        method: 'POST',
        data,
      }),
      providesTags: ['withdrawways'],
    }),
    // /user/recharge_info
    // 充值状态
    rechargeStatus: builder.mutation<Response, { order_id: string }>({
      query: data => ({
        url: `/user/recharge_info`,
        method: 'POST',
        data: {
          ...data,
          token: getUserToken(),
          user_id: getUserId(),
        },
      }),
    }),
    // 汇率
    withdrawExchangeRates: builder.query<
      Response<CurrencyExchangeRates>,
      {
        from: string
        to: string
        amount: number
      }
    >({
      query: data => ({
        url: `/api/v2/other/currency_change`,
        method: 'POST',
        data,
      }),
    }),
    formList: builder.query<
      Response<RechargeFormItem[]>,
      { formType: 'recharge' | 'withdraw' }
    >({
      query: ({ formType }) => ({
        url: `/api/v1/platform/asset_order/form/${formType}`,
        method: 'GET',
      }),
    }),
    agentAllAsset: builder.query<any, any>({
      query: () => ({
        url: '/user/get_all_asset',
        method: 'POST',
        data: {
          token: getUserToken(),
          user_id: getUserId(),
        },
      }),
      providesTags: ['Wallet', 'withdrawways'],
    }),
    //用户打码量
    userGiftList: builder.query<
      any,
      { currency: string; page_last: string; size: string }
    >({
      query: data => ({
        url: `/user/gift_list?page_last=${data.page_last}&size=${data.size}`,
        method: 'POST',
        data: {
          currency: data.currency,
          token: getUserToken(),
          user_id: getUserId(),
        },
      }),
    }),
    //增删查 设置默认
    dealUsersaveInfo: builder.mutation<
      any,
      {
        data: { [key: string]: string; type: string }
      }
    >({
      query: data => ({
        url: `/api/v1/platform/asset_order/withdraw_info/${data.type}`,
        method: 'POST',
        data: {
          ...data.data,
          token: getUserToken(),
          user_id: getUserId(),
        },
      }),
      invalidatesTags: ['withdrawways'],
    }),
    //充值活动 /api/v1/recharge-task/list
    rechargeTaskList: builder.query<any, any>({
      query: data => ({
        url: `/api/v1/recharge-task/list`,
        method: 'POST',
        data: {
          token: getUserToken(),
          user_id: getUserId(),
        },
      }),
      providesTags: ['Auth'],
    }),
    // P2专属：是否有提现免费
    withdrawFreeList: builder.query<any, any>({
      query: data => ({
        url: '/api/v1/platform/asset_order/withdraw_free_config/list',
        method: 'POST',
        data: {
          token: getUserToken(),
          user_id: getUserId(),
          page_off: true,
        },
      }),
    }),
    // 获取充值限额配置信息
    getWalletConfigInfo: builder.query<
      Response<RechargeConfigProps>,
      { currency: string }
    >({
      query: data => ({
        url: `/api/v1/platform/asset_order/pay_mut_config/get_by_currency?currency=${
          data?.currency || ''
        }`,
        method: 'GET',
      }),
    }),
    //增删查 充值信息
    getUserRechargeInfo: builder.mutation<
      any,
      {
        data: { [key: string]: string; type: string }
      }
    >({
      query: data => ({
        url: `/api/v1/platform/asset_order/behavior/get_recharge_cpf_cache`,
        method: 'POST',
        data: {
          token: getUserToken(),
          user_id: getUserId(),
        },
      }),
    }),
    //增删查 充值信息
    setUserRechargeInfo: builder.mutation<
      any,
      {
        data: { [key: string]: string; type: string }
      }
    >({
      query: data => ({
        url: `/api/v1/platform/asset_order/behavior/set_recharge_cpf_cache`,
        method: 'POST',
        data: {
          ...data,
          token: getUserToken(),
          user_id: getUserId(),
        },
      }),
    }),
    // pix是否可编辑
    pixIsEdit: builder.mutation<any, { withdraw_pix_info_id: number }>({
      query: data => ({
        url: `/api/v1/platform/asset_order/withdraw_info/is_can_edit`,
        method: 'POST',
        data: {
          token: getUserToken(),
          user_id: getUserId(),
          ...data,
        },
      }),
    }),
    // pix是否可编辑
    bankruptRechargeConfigs: builder.query<
      Response<BankruptRechargeCfg[]>,
      AuthQueryArgs & { device: string }
    >({
      query: data => ({
        url: '/api/v1/bankrupt/top',
        method: 'POST',
        data,
      }),
    }),
    // 提现信息时候可以修改
    getWithdrawToastSwitch: builder.mutation<Response<any>, any>({
      query: () => ({
        url: `/feat_switch/withdraw_toast`,
        method: 'GET',
        data: {},
      }),
    }),
    /** 获取我的充值信息 */
    getPlayerRechargeInfo: builder.query<Response<any>, any>({
      query: () => ({
        url: `/api/v1/platform/asset_order/recharge_info/my`,
        method: 'POST',
        data: {
          token: getUserToken(),
          user_id: getUserId(),
        },
      }),
      providesTags: ['RechargeInfo'],
    }),
    /** 新增编辑我的充值信息 */
    editPlayerRechargeInfo: builder.mutation<Response<any>, any>({
      query: data => ({
        url: `/api/v1/platform/asset_order/recharge_info/save`,
        method: 'POST',
        data: {
          token: getUserToken(),
          user_id: getUserId(),
          ...data,
        },
      }),
      invalidatesTags: ['RechargeInfo'],
    }),
    /**
     * 获取后台是否启用了gcash_tips_opt
     */
    getGcashTipsOpt: builder.query<Response<{ enable: number }>, void>({
      query: () => ({
        url: '/feat_switch/gcash_tips_opt',
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useRechargeArgsQuery,
  useRechargeMutation,
  useWithdrawMutation,
  useWithdrawArgsQuery,
  useWithdrawalMethodsQuery,
  useRechargeStatusMutation,
  useWithdrawExchangeRatesQuery,
  useFormListQuery,
  useAgentAllAssetQuery,
  useUserGiftListQuery,
  useLazyUserGiftListQuery,
  useDealUsersaveInfoMutation,
  useGetUserRechargeInfoMutation,
  useSetUserRechargeInfoMutation,
  useRechargeTaskListQuery,
  useWithdrawFreeListQuery,
  useGetWalletConfigInfoQuery,
  usePixIsEditMutation,
  useBankruptRechargeConfigsQuery,
  useGetWithdrawToastSwitchMutation,
  useGetPlayerRechargeInfoQuery,
  useEditPlayerRechargeInfoMutation,
  useGetGcashTipsOptQuery,
} = walletApi
