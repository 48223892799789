import beforeLevel from '@/assets/images/vip/before-level.png'
import cashBackImage_1 from '@/assets/images/vip/cash-back-image-1.png'
import cashBackImage_2 from '@/assets/images/vip/cash-Back-image-2.png'
import cashBackImage_3 from '@/assets/images/vip/cash-back-image-3.png'
import cashBackPachImage_1 from '@/assets/images/vip/cash-back-pach-image-1.png'
import cashBackPachImage_2 from '@/assets/images/vip/cash-back-pach-image-2.png'
import cashBackPachImage_3 from '@/assets/images/vip/cash-back-pach-image-3.png'
import next from '@/assets/images/vip/next.png'
import nextLevel from '@/assets/images/vip/next-level.png'
import prev from '@/assets/images/vip/prev.png'
import telegram from '@/assets/images/vip/telegram.png'
import telegramBig from '@/assets/images/vip/telegram-big.png'
import thisLevel from '@/assets/images/vip/this-level.png'
import turnLeft from '@/assets/images/vip/turn-left.png'
import turnRight from '@/assets/images/vip/turn-right.png'
import appConfig from '@/websites/current/pages/layout/app-config.json'

import type { VipInfo } from '../user/types'

export const cashBackImage = [cashBackImage_1, cashBackImage_2, cashBackImage_3]

export const cashBackPachImage = [
  cashBackPachImage_1,
  cashBackPachImage_2,
  cashBackPachImage_3,
]

export const controlIconImage = {
  next: next,
  prev: prev,
}

export const giftCardImage = {
  beforeLevel: beforeLevel,
  thisLevel: thisLevel,
  nextLevel: nextLevel,
}

export const telegramImage = {
  telegram: telegram,
  telegramBig: telegramBig,
}

export const controlPageImage = {
  turnLeft: turnLeft,
  turnRight: turnRight,
}

export function getVipLevelIconSrc(level: number) {
  const vipLevelIcons = appConfig.vip.levelIcons
  const validLevel = Math.min(vipLevelIcons.length - 1, Math.max(level, 0))
  return vipLevelIcons[validLevel]
}

export type GiftData = {
  giftList: number[]
  progressValue: number
}
/**
    level:当前用户等级
  
    isMobile:是否移动端
  
    levelLength:等级数据的长度
  
    移动端展示3张,PC端展示5张
  
    PC端倒数5张进度条计算生成,展示为倒数5张,否则为当前等级前后数5张,进度条始终为10
  
  */
export const formatGiftInfo = (
  level: number,
  isMobile: boolean,
  levelLength: number,
): GiftData => {
  const result: GiftData = {
    giftList: [],
    progressValue: 0,
  }
  const showCardNumber = !isMobile ? 5 : 3
  if (level <= levelLength - showCardNumber) {
    for (let i = level; i <= level + showCardNumber - 1; i++) {
      result.giftList.push(i)
    }
    result.progressValue = isMobile ? 12 : 10
  } else {
    for (let i = levelLength - showCardNumber; i < levelLength; i++) {
      result.giftList.push(i)
    }
    const isTopLevel = level === levelLength - 1
    const isHalfLeval = level > levelLength - 2
    const calcProgress = 100 - ((levelLength - level) % 5) * 20 + 10
    // 满等级为100
    // 非移动端 根据等级算出
    // 移动端超过中间等级为50否则为12
    result.progressValue = isTopLevel
      ? 100
      : !isMobile
      ? calcProgress
      : isHalfLeval
      ? 12
      : 50
  }
  return result
}

/**
 * 根据vipInfo计算出下一级所需的加注
 *
 * */

export type VipInfoType = {
  bet: number
  bet_refund: number
  bet_refund_map: unknown
  recharge: number
  withdraw_count: number
  withdraw_free_amount: number
}

export type LevelConfigType = {
  level: number
  recharge: number
  bet: number
  gift: number
  g_crash: number
  g_plinko: number
  g_dice: number
  g_limbo: number
  g_double: number
  g_roulette: number
  g_mines: number
  g_ring: number
  g_keno: number
  g_fierybot: number
  g_stairs: number
  g_live_casino: number
  g_evoplay: number
  g_fundist: number
  g_coinflip?: number
  withdraw: number
  fee_rate: number
  max_refund: number
  max_withdraw_one: number
  free_amount: number
  min_recharge: number
  index: number
  withdraw_amount: number
}

export type HandleVipInfoType = {
  recharge: string
  rechargeNext: string
  percentRecharge: number
  bet: string
  betNext: string
  percentBet: number
  needRecharge: number
  needBet: number
}

export const convert = (n: number): string =>
  n
    .toFixed(0)
    .replace(/(\d{1,3})((\d{3})*)$/, (_: string, b: string, c: string) => {
      const t = ['', 'k', 'M', 'G'][c.length / 3]
      return t ? `${b}.${c.slice(0, 1)}${t}` : b
    })

// 获取Bonus的展示数据
export type IMessageItem = {
  _id: string
  content: string
  message_type: string
  recharge: {
    amount: number
  }
  withdraw: {
    amount: number
    state: string
    reason: string
  }
  gift_amount: {
    amount: number
  }
  title?: string
  read_at?: string
}

export function getCurrencySign(type: string): string {
  switch (type ? type.toLowerCase() : '') {
    case 'brl':
      return 'R$'
    default:
      return ' '
  }
}

// 返回的string一定 <= num
export function toFixed2Safe(num: number): string {
  num = Math.floor(num * 100) / 100
  const strNum = String(num)
  const idd = strNum.indexOf('.')
  if (idd >= 0) {
    return strNum.slice(0, idd + 3).padEnd(idd + 3, '0')
  } else {
    if (strNum.indexOf('e') >= 0) {
      return strNum
    } else {
      return strNum + '.00'
    }
  }
}

export function formatCurrency(type: string, value: number): string {
  return `${getCurrencySign(type)} ${toFixed2Safe(value)}`
}

export type TransMapTpye = {
  [key in string]: {
    titleKey: string
    contentKey: string
    valMap: {
      num?: (item: IMessageItem) => string
      reason?: (item: IMessageItem) => string
      amount?: (item: IMessageItem) => string
    }
    claim?: string
  }
}

export const TRANS_MAP: TransMapTpye = {
  recharge: {
    titleKey: 'NOTIFY_TITLE_DEPOSIT',
    contentKey: 'NOTIFY_CONTENT_DEPOSIT',
    valMap: {
      num: item => formatCurrency('brl', item.recharge?.amount || 0),
    },
  },
  withdraw_ok: {
    titleKey: 'NOTIFY_TITLE_WITHDRAW_OK',
    contentKey: 'NOTIFY_CONTENT_WITHDRAW_OK',
    valMap: {
      num: item => formatCurrency('brl', item.withdraw?.amount || 0),
    },
  },
  withdraw_fail: {
    titleKey: 'NOTIFY_TITLE_WITHDRAW_FAIL',
    contentKey: 'NOTIFY_CONTENT_WITHDRAW_FAIL',
    valMap: {
      num: item => formatCurrency('brl', item.withdraw?.amount || 0),
      reason: item => item.withdraw.reason,
    },
  },
  gift_money: {
    titleKey: 'NOTIFY_TITLE_GIFT_MONEY',
    contentKey: 'NOTIFY_CONTENT_GIFT_MONEY',
    valMap: {
      num: item => formatCurrency('brl', item.gift_amount?.amount || 0),
    },
  },
  spread_recharge_gift: {
    titleKey: 'NOTIFY_TITLE_SPREAD_RECHARGE_GIFT',
    contentKey: 'NOTIFY_CONTENT_SPREAD_RECHARGE_GIFT',
    valMap: {
      num: item => formatCurrency('brl', item.gift_amount?.amount || 0),
    },
  },
  spread_upgrade_gift: {
    titleKey: 'NOTIFY_TITLE_SPREAD_UPGRADE_GIFT',
    contentKey: 'NOTIFY_CONTENT_SPREAD_UPGRADE_GIFT',
    valMap: {
      num: item => formatCurrency('brl', item.gift_amount?.amount || 0),
    },
  },
  spread_bet_gift: {
    titleKey: 'NOTIFY_TITLE_SPREAD_BET_GIFT',
    contentKey: 'NOTIFY_CONTENT_SPREAD_BET_GIFT',
    valMap: {
      num: item => formatCurrency('brl', item.gift_amount?.amount || 0),
    },
  },
  vip_bet_gift_amount: {
    titleKey: 'NOTIFY_TITLE_VIP_GIFT',
    contentKey: 'NOTIFY_CONTENT_VIP_GIFT',
    valMap: {
      amount: item => formatCurrency('brl', item.gift_amount?.amount || 0),
    },
    claim: 'needFetch',
  },
  vip_upgrade_gift_amount: {
    titleKey: 'NOTIFY_TITLE_VIP_UPGRADE',
    contentKey: 'NOTIFY_CONTENT_VIP_UPGRADE',
    valMap: {
      amount: item => formatCurrency('brl', item.gift_amount?.amount || 0),
    },
    claim: 'needFetch',
  },
}

const LOCAL_TYPE: Record<string, string> = {
  brl: `pt-BR`,
  sol: `es-PE`,
  php: `zh-CN`,
  common: `pt-BR`,
  zh: 'zh-CN',
}

// 金钱 以及符号
export const formatLocalMoney = (value: number, ntype = 'php') => {
  if (!(value * 1)) return 0
  return (value * 1).toLocaleString(LOCAL_TYPE[ntype])
}

function toInteger(floatNum: number) {
  const ret = { times: 1, num: 0 }
  // 负数
  const isNegative = floatNum < 0
  // 相同直接输出
  if (Math.floor(floatNum) === floatNum) {
    ret.num = floatNum
    return ret
  }
  const strfi = floatNum.toString()
  // 获取小数点后的值的长度
  const len = strfi.substring(strfi.indexOf('.') + 1).length
  const times = Math.pow(10, len)
  let intNum = parseInt((Math.abs(floatNum) * times + 0.5).toString(), 10)
  ret.times = times
  if (isNegative) {
    intNum = -intNum
  }
  ret.num = intNum
  return ret
}

// 补0
export const addZero = (target: number, magnitude: number) => {
  const o1 = toInteger(target)
  const o2 = toInteger(magnitude)
  const n1 = o1.num
  const n2 = o2.num
  const t1 = o1.times
  const t2 = o2.times
  return (n1 * n2) / (t1 * t2)
}
