import { isEmpty, max, min, toNumber } from 'lodash-es'
import type { FC } from 'react'
import { memo, useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { useAppSelector } from '@/modules/app/store'
import type { AmountControlProps } from '@/modules/inhouse/common/components/amount-control'
import { selectUserCurrencySign } from '@/modules/user/userSlice'

import type { Option } from '../../../common/components/amount-control/AmountControl'
import { AmountControl } from '../../../common/components/amount-control/AmountControl'
import {
  balanceInfoAtom,
  gameInfoAtom,
  walletInfoAtom,
} from '../../atoms/common-state'
import {
  useAmountControl,
  useAutoBet,
  useBetTmp,
  useFormat,
  useGameBet,
} from '..'

export const LogicAmountControl: FC<Pick<AmountControlProps, 'disabled'>> = ({
  disabled,
}) => {
  const { currency } = useRecoilValue(walletInfoAtom)
  const sign = useAppSelector(selectUserCurrencySign)
  const balance = useRecoilValue(balanceInfoAtom)
  const { config, gameId } = useRecoilValue(gameInfoAtom)
  const { addSign, getAddSignPattern } = useFormat()
  const { value, toMax, toMin, toDouble, toHalf, to } = useAmountControl()
  const [shouldAmountFormat, setShouldAmountFormat] = useState(true)
  const betAmountFormat = useCallback(
    (v: string | number) => {
      if (shouldAmountFormat) {
        return addSign(
          currency.decimalFormat(toNumber(v)),
          sign || currency.sign,
        )
      }

      return addSign(v, currency.sign)
    },
    [addSign, currency, shouldAmountFormat, sign],
  )

  const minValue = config.minBetAmount
  const maxValue = useMemo(
    () => max([min([balance, config.maxBetAmount]), minValue]),
    [balance, config.maxBetAmount, minValue],
  )

  const balanceText = useMemo(
    () => addSign(currency.decimalFormat(toNumber(balance)), currency.sign),
    [addSign, balance, currency],
  )

  const options: [Option, Option, Option, Option] = useMemo(
    () => [
      {
        key: 'min',
        label: 'Min',
        onClick: () => {
          toMin()
        },
      },
      {
        key: 'max',
        label: 'Max',
        onClick: () => toMax(),
      },
      {
        key: 'half',
        label: '1/2',
        onClick: () => toHalf(),
      },
      {
        key: 'double',
        label: '2x',
        onClick: () => toDouble(),
      },
    ],
    [toDouble, toHalf, toMax, toMin],
  )

  const parse = useCallback(
    (value: string) => {
      return value.replace(getAddSignPattern(currency.sign), '')
    },
    [currency.sign, getAddSignPattern],
  )

  const handleFocus = useCallback(() => setShouldAmountFormat(false), [])
  const handleBlur = useCallback(() => setShouldAmountFormat(true), [])
  const handleChange = useCallback(
    (v: number) => {
      to(v || 1)
    },
    [to],
  )

  /**
   * !!! to should ignore
   *
   */
  useLayoutEffect(() => {
    if (!isEmpty(gameId) && config.defaultBetAmount) {
      to(config.defaultBetAmount)
    }
  }, [gameId, config.defaultBetAmount])

  return (
    <AmountControl
      disabled={disabled}
      value={value}
      maxValue={maxValue}
      minValue={minValue}
      subText={balanceText}
      options={options}
      onFocus={handleFocus}
      onBlur={handleBlur}
      integer={config?.integer ?? true}
      valueFormat={betAmountFormat}
      parse={parse}
      onChange={handleChange}
    />
  )
}
const MemoLogicAmountControl = memo(LogicAmountControl)

const ConditionAmountControl: FC<{
  disabled?: boolean
}> = ({ disabled: propsDisabled }) => {
  const { inputDisabled } = useAutoBet()
  const [betTmp] = useBetTmp()
  const { betRequestState } = useGameBet()

  const { loaded: beted } = betRequestState

  // let realDisabled = beted || !!betTmp || inputDisabled
  // if (propsDisabled !== undefined) realDisabled = realDisabled || propsDisabled
  const realDisabled =
    propsDisabled === undefined
      ? beted || !!betTmp || inputDisabled
      : propsDisabled

  return <MemoLogicAmountControl disabled={realDisabled} />
}
const MemoConditionAmountControl = memo(ConditionAmountControl)

export default MemoConditionAmountControl
