import type { ComponentStyleConfig } from '@chakra-ui/react'

import { NAME, PARTS } from '@/modules/inhouse/logic/games/plinko/AmountContro'

const parts = [...PARTS]
const componentStyle: ComponentStyleConfig = {
  parts,
  baseStyle: {
    box: {
      bgColor: 'gray.400',
    },
  },
}

export const name = NAME
export default componentStyle
