import { Box } from '@chakra-ui/react'

import vip0 from './vip-0.png'
import vip1 from './vip-1.png'
import vip2 from './vip-2.png'
import vip3 from './vip-3.png'
import vip4 from './vip-4.png'
import vip5 from './vip-5.png'
import vip6 from './vip-6.png'
import vip7 from './vip-7.png'
import vip8 from './vip-8.png'
import vip9 from './vip-9.png'
import vip10 from './vip-10.png'
import vip11 from './vip-11.png'
import vip12 from './vip-12.png'

export const vipIcon = [vip0, vip1, vip2, vip3, vip4, vip5, vip6, vip7, vip8, vip9, vip10, vip11, vip12]

export default function NewVipLevelIcon({
  level,
  size = 56,
  defaultWidth,
  padding,
}: {
  level: number
  size?: number
  defaultWidth?: number,
  padding?: string
}) {
  const height = size
  const width = defaultWidth ?? (100 * size) / 56

//   const x = level * width * -1

  return (
    <Box
      w={`${width}px`}
      h={`${height}px`}
      background={`url(${vipIcon[level]}) no-repeat`}
      backgroundSize={`auto ${height}px`}
      padding={padding}
    //   backgroundPosition={`${x}px 0`}
    />
  )
}
