import type { EventName } from '../const'
import { TrackerBuilderAdjustOnlyHybird } from './TrackerBuilderAdjust'
import { TrackerBuilderPixel } from './TrackerBuilderPixel'
import { TrackerShuShu } from './TrackerShuShu'
import type { ITrackerBase } from './types'

export class Track {
  constructor(private _trackers: (ITrackerBase | null)[]) {}

  track(eventName: EventName, data?: any) {
    this._trackers.forEach(tracker => {
      if (!tracker) return
      const events = tracker.eventMap[eventName]
      if (!events || events.length === 0) return
      events.forEach(event => tracker.trackSingleEvent(event, eventName, data))
    })
  }
}

let _trackInstance: Track | null = null

export const getTrackInstance = () => {
  if (!_trackInstance) {
    const trackers = [
      new TrackerBuilderPixel().build(),
      new TrackerBuilderAdjustOnlyHybird().build(),
    ].filter(Boolean) as ITrackerBase[]
    // shushu Need to be the last one, because it will report all the channels
    _trackInstance = new Track([...trackers, new TrackerShuShu(trackers)])
  }
  return _trackInstance
}
