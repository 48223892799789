import { Box, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AtomEmpty from '@/components/atom-empty/AtomEmpty'
import AtomPagination from '@/components/atom-pagination/AtomPagination'
import type { TitledDialogProps } from '@/components/modal/TitledDialog'
import TitledDialog from '@/components/modal/TitledDialog'
import { getColorModeValue } from '@/utils/tools'

import { useGetRecordListQuery } from '../api/drawActivityApi'
import { prizeTextFormatter } from '../utils'

function RecordModal(
  props: Pick<TitledDialogProps, 'isOpen' | 'onClose'> & { taskId?: number },
) {
  const { isOpen, onClose, taskId } = props
  const { t } = useTranslation()
  // const [offset, setOffset] = useState(0)
  // const [current, setCurrent] = useState(1)
  const [pagination, setPagination] = useState({ offset: 0, current: 1 })
  const LIMIT = 10
  const { data } = useGetRecordListQuery({
    task_id: taskId || 0,
    list_option: { limit: LIMIT, offset: pagination.offset },
  })

  useEffect(() => {
    if (isOpen) {
      setPagination({ offset: 0, current: 1 })
    }
  }, [isOpen])

  const list = data?.data?.list || []

  const total = data?.data.paginate?.total ?? 0
  // const hasNext = total > offset + list.length
  // const hasNext = total > current * LIMIT
  const hasNext = useMemo(() => {
    return total > pagination.current * LIMIT
  }, [total, pagination])

  // const onNext = () => {
  //   setCurrent(prev => prev + 1)
  //   const nextOffset = current * LIMIT - 10
  //   setOffset(nextOffset)
  // }

  const onNext = useCallback(() => {
    setPagination(prev => ({
      ...prev,
      current: prev.current + 1,
      offset: prev.offset + LIMIT,
    }))
  }, [])

  const hasPrev = useMemo(() => {
    return pagination.current > 1
  }, [pagination])

  const onPrev = useCallback(() => {
    setPagination(prev => ({
      ...prev,
      current: prev.current - 1,
      offset: prev.offset - LIMIT,
    }))
  }, [])

  return (
    <TitledDialog
      title={t('RECORD_TITLE')}
      isOpen={isOpen}
      onClose={() => {
        setPagination({ offset: 0, current: 1 })
        onClose()
      }}
      colorScheme='multilayer'
      bodyProps={{ p: '2.5', pt: '1' }}
    >
      <Box
        w='full'
        borderRadius='lg'
        px='5'
        pb='5'
        bgColor={getColorModeValue('gray.700', 'gray.300')}
      >
        {list.length === 0 ? (
          <AtomEmpty />
        ) : (
          <>
            <Table variant='variants' width='100%' mb='5' layout='fixed'>
              <Thead>
                <Tr>
                  {[t('WHEELTIME'), t('Prize')].map((title, index) => (
                    <Th
                      key={index}
                      textAlign='center'
                      py='3'
                      textStyle='h7'
                      color='text.base'
                      textTransform='capitalize'
                    >
                      {t(title)}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {list?.map((item, index) => (
                  <Tr key={index}>
                    <Td color='gray.700' textStyle='text6' px='0' noOfLines={1}>
                      {dayjs((item.created_at ?? 0) * 1000).format(
                        'YYYY/MM/DD HH:mm:ss',
                      )}
                    </Td>
                    <Td color='text.moneyPrim' textAlign='center'>
                      {prizeTextFormatter(item)}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <AtomPagination
              onNext={onNext}
              hasNext={hasNext}
              hasPrev={hasPrev}
              onPrev={onPrev}
              current={pagination.current}
            />
          </>
        )}
      </Box>
    </TitledDialog>
  )
}

export default RecordModal
