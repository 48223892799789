import type { GameId } from '../../gameIds'
import type { GamePlinko } from '../../logic/games/plinko/core/game'

export function hasWindowGameInstance(
  gameid: Extract<GameId, 'plinko' | 'crash'>,
  gameInstance: any,
): gameInstance is GamePlinko {
  const gameMap: Record<typeof gameid, string> = {
    crash: 'Crash',
    plinko: 'Plinko',
  }

  if (
    gameInstance.context.game.gameClassName === gameMap[gameid] &&
    gameInstance.context.game.gameStart
  ) {
    return true
  }
  return false
}
