import { Box, Flex, Highlight, keyframes, Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { formatLocalMoney } from '@/utils/tools'

// eslint-disable-next-line react-refresh/only-export-components
export const scrollToRight = keyframes`
to {
  transform: translateX(-50%);
}`

const generateResult = (number?: number) => {
  const mockNumber = number ?? 12
  return Array.from({ length: mockNumber })
    .fill(0)
    .map(_ => {
      const randomUserName = Math.floor(Math.random() * 1000000)
      return {
        userName: randomUserName,
        rewardAmount: 100,
      }
    })
}

const mockResult = generateResult()

function LoopResult() {
  const { t } = useTranslation()
  return (
    <Box overflow='hidden'>
      <Flex
        mt='3'
        px='10px'
        transform='translateX(0px)'
        animation={`${scrollToRight} 30s linear infinite`}
        flexWrap='nowrap'
        whiteSpace='nowrap'
        width='max-content'
      >
        {mockResult.concat(mockResult).map((result, index) => {
          const { userName, rewardAmount } = result
          const rewardText = formatLocalMoney(rewardAmount)
          return (
            <Box
              w='auto'
              h='6'
              borderRadius='80px'
              bg='rgba(0,0,0,0.2)'
              lineHeight='6'
              px='3'
              py='1'
              mr='2'
              whiteSpace='nowrap'
              display='inline-flex'
              key={index}
            >
              <Text color='#fff' textStyle='h7'>
                <Highlight styles={{ color: 'four.500' }} query={rewardText}>
                  {String(`User${userName}...`) +
                    t('RECEIVE') +
                    ' ' +
                    rewardText}
                </Highlight>
              </Text>
            </Box>
          )
        })}
      </Flex>
    </Box>
  )
}

const LoopResultMemo = React.memo(LoopResult)
export default LoopResultMemo
