import { useCallback, useMemo } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import type { CommonBetTmp } from '../atoms/common-state'
import {
  balanceInfoAtom,
  commonBetTmpAtom,
  walletInfoAtom,
} from '../atoms/common-state'

export function useBetTmp(): [typeof betTmp, typeof setCommonBetTmp] {
  const [betTmp, setBetTmp] = useRecoilState(commonBetTmpAtom)
  const { showRecharge } = useRecoilValue(walletInfoAtom)
  const balance = useRecoilValue(balanceInfoAtom)

  const setCommonBetTmp = useCallback(
    (tmp: CommonBetTmp | undefined) => {
      if (tmp === undefined) {
        setBetTmp(tmp)
      } else {
        const { value } = tmp

        if (value.amount > balance) {
          showRecharge()
        } else {
          setBetTmp(tmp)
        }
      }
    },
    [balance, showRecharge, setBetTmp],
  )

  return useMemo(() => {
    return [betTmp, setCommonBetTmp]
  }, [betTmp, setCommonBetTmp])
}

export default useBetTmp
