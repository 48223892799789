import type { SystemStyleObject } from '@chakra-ui/react'
import {
  Box,
  Button,
  Center,
  Flex,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react'
import { floor } from 'lodash-es'
import { type FC, memo, useCallback, useMemo } from 'react'
import { type RecoilState, useRecoilState, useRecoilValue } from 'recoil'

import { useCustomizedStyle } from '@/utils/hooks'
import { useLargerThanMobile } from '@/utils/hooks/useLargerThanMobile'

import {
  balanceInfoAtom,
  gameInfoAtom,
  walletInfoAtom,
} from '../../atoms/common-state'
import { useFormat } from '../../hooks'
import { useFinalMaxMin } from '../../hooks/useMaxMin'
import AmountInput from './AmountInput'
import LogicBet, { getColorAtoms } from './LogicBet'
import type { PlinkColors } from './state'

export interface PlinkAmountControlProps {
  atom: RecoilState<number>
}

const box: SystemStyleObject = {
  bgColor: '#fff',
  borderRadius: '10px',
  overflow: 'hidden',
  padding: ['12px 7px 7px', '12px 5px 5px'],
  position: 'relative',

  _disabled: {
    cursor: 'not-allowed',
  },
}

export const PARTS = [
  'box',
  'input',
  'text',
  'button',
  'switch',
  'switchTag',
] as const
export const NAME = 'PlinkAmountControl'

const PlinkAmountControl: FC<PlinkAmountControlProps> = ({ atom }) => {
  const color = atom.key as PlinkColors
  const { isAutoStarted: isAutoStartedAtom } = getColorAtoms(color)
  const isAutoStarted = useRecoilValue(isAutoStartedAtom)
  const { box: boxStyle } = useCustomizedStyle(NAME, { box }, PARTS)
  const disabled = isAutoStarted
  return (
    <>
      <Box sx={boxStyle} aria-disabled={disabled}>
        <Box>
          <AmountInput color={color} />
        </Box>

        <Box mt='10px'>
          <BalanceText />
        </Box>

        <Box mt='14px'>
          <ConditionSwitchBar color={color} />
        </Box>

        {disabled && (
          <Box
            position='absolute'
            left='0'
            top='0'
            w='100%'
            h='100%'
            zIndex='1'
            bgColor='rgba(255,255,255,.3)'
            cursor='not-allowed'
          />
        )}
      </Box>

      <LogicBet color={color} />
    </>
  )
}

const MemoPlinkAmountControl = memo(PlinkAmountControl)
export default MemoPlinkAmountControl

/** balance text */
export function BalanceText() {
  const { text: textStyle } = useCustomizedStyle(
    NAME,
    {
      text: {
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: '700',
        lineHeight: '18px',
        color: 'gray.700',
      },
    },
    PARTS,
  )
  const { currency } = useRecoilValue(walletInfoAtom)
  const balance = useRecoilValue(balanceInfoAtom)
  const { addSign } = useFormat()

  return (
    <Text sx={textStyle}>
      {addSign(currency.decimalFormat(balance), currency.sign)}
    </Text>
  )
}

/** switch bar */
function SwitchBar({
  isAutoChecked,
  setIsAutoChecked,
  onHalf,
  onDouble,
}: {
  isAutoChecked: boolean
  setIsAutoChecked?: (checked: boolean) => void
  onHalf?: () => void
  onDouble?: () => void
}) {
  const { button: buttonStyle, switch: switchStyle } = useCustomizedStyle(
    NAME,
    {
      button: {
        width: '47px',
        height: '25px',
        background: 'gray.200',
        borderRadius: '6px',
        fontSize: '12px',
        fontWeight: '700',
      },
      switch: {
        width: '100%',
        height: '30px',
        borderRadius: '8px',
        background: 'gray.200',
      },
    },
    PARTS,
  )
  const isLargerThanMobile = useLargerThanMobile()

  // const switchStyle = {
  //   width: '100%',
  //   height: '30px',
  //   borderRadius: '8px',
  //   background: 'gray.200',
  // }

  const halfBtmElem = useMemo(
    () => (
      <Button size='xs' onClick={onHalf} variant='shortcut' sx={buttonStyle}>
        1/2
      </Button>
    ),
    [onHalf],
  )

  const handleSwitchChange = useCallback(
    (e: any) => setIsAutoChecked && setIsAutoChecked(e.target.checked),
    [],
  )

  const switchElem = useMemo(
    () => (
      <Flex justifyContent='space-around' width='100%'>
        <Text as='span' fontSize='12px'>
          Auto
        </Text>
        <Switch isChecked={isAutoChecked} onChange={handleSwitchChange} />
      </Flex>
    ),
    [handleSwitchChange, isAutoChecked],
  )

  const doubleBtnElem = useMemo(
    () => (
      <Button size='xs' onClick={onDouble} variant='shortcut' sx={buttonStyle}>
        2x
      </Button>
    ),
    [onDouble],
  )

  if (isLargerThanMobile) {
    return (
      <Flex justifyContent='space-evenly'>
        {halfBtmElem}
        <div>{switchElem}</div>
        {doubleBtnElem}
      </Flex>
    )
  } else {
    return (
      <VStack>
        <Center sx={switchStyle}>{switchElem}</Center>
        <Flex width='full' justifyContent='space-between'>
          {halfBtmElem}
          {doubleBtnElem}
        </Flex>
      </VStack>
    )
  }
}

function AbleSwitchBar({ color }: { color: PlinkColors }) {
  const { isAuto: autoAtom, amount: amountAtom } = getColorAtoms(color)
  const [isAutoChecked, setIsAutoChecked] = useRecoilState(autoAtom)

  const [amount, setAmount] = useRecoilState(amountAtom)
  const { getMax, getMin } = useFinalMaxMin()
  const { config } = useRecoilValue(gameInfoAtom)
  const integer = config.integer ?? true

  const handleHalfClick = useCallback(() => {
    let result = amount / 2
    if (result < getMin()) result = getMin()
    setAmount(integer ? floor(result) : result)
  }, [amount, setAmount, integer, getMin])

  const handleDoubleClick = useCallback(() => {
    let result = amount * 2
    if (result > getMax()) result = getMax()
    setAmount(integer ? floor(result) : result)
  }, [amount, setAmount, integer, getMax])

  return (
    <SwitchBar
      isAutoChecked={isAutoChecked}
      setIsAutoChecked={setIsAutoChecked}
      onHalf={handleHalfClick}
      onDouble={handleDoubleClick}
    />
  )
}

function DisabledSwitch() {
  return <SwitchBar isAutoChecked={true} />
}

function ConditionSwitchBar({ color }: { color: PlinkColors }) {
  const { isAutoStarted: isAutoStartedAtom } = getColorAtoms(color)
  const isAutoStarted = useRecoilValue(isAutoStartedAtom)

  if (isAutoStarted) return <DisabledSwitch />

  return <AbleSwitchBar color={color} />
}
