import type { ComponentStyleConfig } from '@chakra-ui/react'

import { PARTS } from '@/modules/inhouse/common/components/amount-control/AmountControl'

import theme from './inhouse'

const { amountColor } = theme
const parts = [...PARTS]
const componentStyle: ComponentStyleConfig = {
  parts,
  baseStyle: {
    box: {
      bgColor: 'gray.400',
    },
    betAmount: {
      color: amountColor,
    },
    radio: {
      bgColor: 'gray.500',
      color: 'gray.900',
      _checked: {
        bgColor: 'prim.500',
        color: 'gray.900',
      },
    },
  },
}

export default componentStyle
