import { atom } from 'recoil'

import type { types } from '@/utils/atom-shared'

import type { AutoConfig } from '../../common/components/auto-bet'
import type { RequestRes } from '../../common/request'
import FixedSizeArray from '../../common/utils/FixedSizeArray'

/** bet amount */
export const commonAmountAtom = atom<number>({
  key: 'commonAmountAtom',
  default: 10,
})

export type CommonBetTmp = CommonBetInfo
export const commonBetTmpAtom = atom<CommonBetTmp | undefined>({
  key: 'commonBetTmpAtom',
  default: undefined,
})

export interface CommonBetInfo {
  /** unique id, bet 成功的时候会自动生成*/
  id: number
  value: {
    /** bet amount */
    amount: number

    /** odds */
    odds: number

    [key: string]: any
  }
}
export const commonBetInfoAtom = atom<CommonBetInfo | undefined>({
  key: 'commonBetInfoAtom',
  default: undefined,
})

export interface CommonBetResult {
  id: number
  /** 最终结果，可正可负 */
  value: number
}
export const commonBetResultAtom = atom<CommonBetResult | undefined>({
  key: 'commonBetResultAtom',
  default: undefined,
})

export interface AutoModeGameRecords {
  /** first amount value in auto */
  initAmount: number

  /** prev bet amount */
  prevAmount: number

  /** cumulative bet amounts */
  totalAmount: number

  /** cumulative results 可正可负 */
  totalResult: number

  /** if initCount === 0 => ∞ */
  initCount: number
}

export const commonAutoModeAtom = atom<'on' | 'loading' | 'off'>({
  key: 'commonAutoMode',
  default: 'off',
})

export const defaultCommonAutoGameRecord = {
  initCount: -1,
  initAmount: 0,
  prevAmount: 0,
  totalAmount: 0,
  totalResult: 0,
}

/** auto bet computed status */
export const commonAutoGameRecordsAtom = atom<AutoModeGameRecords>({
  key: 'commonAutoGameRecordsAtom',
  default: {
    initCount: -1,
    initAmount: 0,
    prevAmount: 0,
    totalAmount: 0,
    totalResult: 0,
  },
})

export const commonAutoConfigAtom = atom<AutoConfig>({
  key: 'commonAutoConfigAtom',
  default: {
    count: 0,
    stopWin: 0,
    stopLoss: 0,
    max: 0,
    raiseWin: 0,
    raiseLoss: 0,
    raiseWinOn: false,
    raiseLossOn: false,
  },
})

export interface UserInfo {
  userId: string
  userName: string
  balance: Balance
}
export interface User {
  token: string | null
  userInfo?: UserInfo
}

export interface Balance {
  amount: number
  currency: string
  withdrawable_amount: number
  bet_progress: number
  bet_target: number
}

export type RequestState<P, R, S extends boolean = boolean> = {
  loading: boolean
  loaded: boolean
  loadfail: S
  params?: P
  data?: S extends true ? RequestRes<R> : R
}

export const ARRAY_MAX = 30
export const commonBetRequestAtom = atom<RequestState<unknown, unknown>>({
  key: 'commonBetRequestAtom',
  default: {
    loading: false,
    loaded: false,
    loadfail: false,
  },
})
export const commonHistoryAtom = atom<FixedSizeArray<any>>({
  key: 'commonHistoryAtom',
  default: new FixedSizeArray(ARRAY_MAX),
})

export const commonMyBetAtom = atom<FixedSizeArray<any>>({
  key: 'commonMyBetAtom',
  default: new FixedSizeArray(ARRAY_MAX),
})

export const userInfoAtom = atom<types.UserInfo>({
  key: 'userInfoAtom',
  default: {
    isLoggedIn: false,
    userId: '',
    nickName: '',
    token: '',
    showLogin: () => null,
    showSignUp: () => null,
  },
})

export const walletInfoAtom = atom<types.WalletInfo>({
  key: 'walletInfoAtom',
  default: {
    needBindCurrency: false,
    currency: {
      code: 'PHP',
      sign: '₱',
      integerFormat: (value: number) => value.toFixed(0),
      decimalFormat: (value: number) => value.toFixed(2),
    },
    updateBalance: (delta: number) => null,
    showRecharge: () => null,
    showBindCurrency: () => null,
  },
})

export const balanceInfoAtom = atom<number>({
  key: 'balanceInfoAtom',
  default: 0,
})

export const gameInfoAtom = atom<types.GameInfo>({
  key: 'gameInfoAtom',
  default: {
    gameId: '' as any,
    uniqueGameId: '',
    config: {
      defaultBetAmount: 5,
      minBetAmount: 1,
      maxBetAmount: Infinity,
      maxWithdrawAmount: Infinity,
    },
    apiBaseUrl: '',
    wssBaseUrl: '',
    gameBaseUrl: '',
    projectName: '',
  },
})

export interface BetInfo {
  /** id */
  _id: string //ID

  /** 游戏id */
  game_id: string

  /** game name */
  game_name: string

  channel: string

  /** user balance */
  balance: number //用户余额

  user_id: string //用户ID

  user_name: string

  /** 胜win,败lost,平draw */
  result: string | number
  state: string //状态，pending,done

  /** 赔率 */
  multiplier: number

  /** 投注金额 */
  bet_amount: number

  /** 实际投注，即扣除金额后 */
  bet_actual_amount: number

  /** 返奖 */
  pay_out: number

  /** 货币 */
  currency: string

  /** 公平验证 */
  f_seed: [string, string]

  /** next cash */
  next_hash: string
  create_at: any
  update_at: string

  /** 手续费 */
  fee_amount: number

  /** 费率 */
  fee_rate: number
  spread_parent: string
  crash_result: number
  prediction: number
  ship_id: number
  hash: string

  [key: string]: any
}
