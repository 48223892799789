import type { AuthQueryArgs } from '@/modules/user/userApi'
import { api } from '@/services/api'
import type { Response } from '@/services/types'

import getIsSupportLevelProtection from './features/vip-level-protection/get-is-support-level-protection'
import type {
  IVipConfigList,
  ResponseVipInfoRsp,
  VipRewardItem,
  VipTaskModelVipSetting,
} from './types'

export const newVipApi = api.injectEndpoints({
  endpoints: builder => ({
    getUserVipInfo: builder.query<Response<ResponseVipInfoRsp>, AuthQueryArgs>({
      query: data => {
        const isSupportLevelProtection = getIsSupportLevelProtection()
        const url = isSupportLevelProtection
          ? '/api/v1/vip-user/infov2'
          : '/api/v1/vip-user/info'

        return {
          url,
          method: 'POST',
          data,
        }
      },
      providesTags: ['UserVipInfo'],
    }),

    getVipConfigList: builder.query<Response<IVipConfigList | null>, string>({
      query: user_id => {
        return {
          url: `/api/v1/vip-config/list`,
          method: 'get',
          params: { user_id },
        }
      },
      providesTags: ['newVip'],
    }),

    getVipRewardList: builder.query<Response<VipRewardItem[]>, AuthQueryArgs>({
      query: data => {
        return {
          url: '/api/v1/vip-reward/list',
          method: 'POST',
          data,
        }
      },
      providesTags: ['newVip'],
    }),

    getVipCommonConfig: builder.query<
      Response<VipTaskModelVipSetting[]>,
      undefined
    >({
      query: () => {
        return {
          url: '/api/v1/vip-config/setting_list',
          method: 'GET',
        }
      },
    }),
    onClaimVipReward: builder.mutation<
      Response,
      AuthQueryArgs & {
        biz_id: string
      }
    >({
      query: data => ({
        url: '/api/v1/vip-reward/claim',
        method: 'POST',
        data,
      }),
    }),
    onClaimVipRewardAll: builder.mutation<
      Response,
      AuthQueryArgs & {
        biz_id: string[]
      }
    >({
      query: data => ({
        url: '/api/v1/vip-reward/claim_all',
        method: 'POST',
        data,
      }),
    }),
    /**
     * 获取后台是否启用了vip每日奖励显示的开关
     */
    getVipDailyRewardSwitch: builder.query<Response<{ enable: number }>, void>({
      query: () => ({
        url: '/feat_switch/vip_daily_reward',
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useGetVipConfigListQuery,
  useGetUserVipInfoQuery,
  useGetVipRewardListQuery,
  useOnClaimVipRewardMutation,
  useOnClaimVipRewardAllMutation,
  useGetVipCommonConfigQuery,
  useGetVipDailyRewardSwitchQuery,
} = newVipApi
