import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { clamp, groupBy, toNumber } from 'lodash-es'

import {
  selectUserBetAmount,
  selectUserRechargeAmount,
  selectUserVipInfo,
  selectVipLevel,
} from '@/modules/user/userSlice'

import type { RootState } from '../app/store'
import { newVipApi } from './newVipApi'
import type { Config, VipConfigItem, VipRewardItem } from './types'
import { numtofiexed } from './utils'

export type NewVipState = {
  rewardList: VipRewardItem[]
  vipConfigList: VipConfigItem[]
  activeLevel: number
  activeTab: 'bonus' | 'cashback' | 'benefits'
  isUserVipInfoInvalid: boolean
  dailyRewardSwitchStatus: boolean
}

export interface Progress {
  current: number
  target: number
  diff: number
  percentage: number
}

const initialState: NewVipState = {
  rewardList: [],
  vipConfigList: [],
  // 当前激活的vip等级
  activeLevel: 0,
  // 当前激活的tab
  activeTab: 'bonus',
  isUserVipInfoInvalid: true,
  /** 每日签到开关 */
  dailyRewardSwitchStatus: false,
}

export const newVipSlice = createSlice({
  name: 'newVip',
  initialState,
  reducers: {
    setRewardList(state, action: PayloadAction<VipRewardItem[]>) {
      state.rewardList = action.payload
    },
    setActiveLevel(state, action: PayloadAction<number>) {
      state.activeLevel = action.payload
    },
    setActiveTab(
      state,
      action: PayloadAction<'bonus' | 'cashback' | 'benefits'>,
    ) {
      state.activeTab = action.payload
    },
    changeActiveLevel(state, action: PayloadAction<number>) {
      const targetConfig = state.vipConfigList[action.payload]
      if (targetConfig) {
        state.activeLevel = targetConfig.level
      }
    },
    invalidUserVipInfoInfo(state) {
      if (!state.isUserVipInfoInvalid) {
        state.isUserVipInfoInvalid = true
      }
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        newVipApi.endpoints.getVipRewardList.matchFulfilled,
        (state, { payload }) => {
          state.rewardList = payload.data
        },
      )
      .addMatcher(
        newVipApi.endpoints.getVipConfigList.matchFulfilled,
        (state, { payload }) => {
          state.vipConfigList =
            payload.data?.sort((a, b) => a.level - b.level) ?? []
        },
      )
      .addMatcher(newVipApi.endpoints.getUserVipInfo.matchFulfilled, state => {
        state.isUserVipInfoInvalid = false
      })
      .addMatcher(
        newVipApi.endpoints.onClaimVipReward.matchFulfilled,
        (state, { meta }) => {
          const id = meta.arg.originalArgs.biz_id
          const index = state.rewardList.findIndex(item => item.biz_id === id)
          if (index !== -1) {
            state.rewardList[index].is_claim = 1
          }
        },
      )
      .addMatcher(
        newVipApi.endpoints.onClaimVipRewardAll.matchFulfilled,
        (state, { meta }) => {
          const ids = meta.arg.originalArgs.biz_id
          const idsMap = new Map(ids.map(id => [id, true]))
          state.rewardList.forEach(item => {
            if (idsMap.has(item.biz_id)) {
              item.is_claim = 1
            }
          })
        },
      )
      .addMatcher(
        newVipApi.endpoints.getVipDailyRewardSwitch.matchFulfilled,
        (state, { payload }) => {
          const statusSwitch = !!(payload.data.enable === 1)
          state.dailyRewardSwitchStatus = statusSwitch
        },
      )
  },
})

export const { changeActiveLevel, invalidUserVipInfoInfo } = newVipSlice.actions

export const selectNewVip = (state: RootState) => state.newVip

export const selectNewVipActiveLevel = createSelector(
  selectNewVip,
  vipState => vipState.activeLevel ?? 0,
)

/** 每日签到奖励开关 */
export const selectDailyRewardSwitchStatus = createSelector(
  selectNewVip,
  vipState => vipState.dailyRewardSwitchStatus,
)

export const selectNewVipActiveTab = (state: RootState) =>
  selectNewVip(state).activeTab

export const selectVipRewardList = (state: RootState) =>
  selectNewVip(state).rewardList

export const selecVipConfigList = createSelector(
  selectNewVip,
  vipState => vipState.vipConfigList,
)

export const setIsUserVipInfoInvalid = createSelector(
  selectNewVip,
  vipState => vipState.isUserVipInfoInvalid,
)

export const selecLastVipLevel = createSelector(
  selecVipConfigList,
  vipConfigList => vipConfigList[vipConfigList.length - 1]?.level ?? 0,
)

export const selectNextVipLevel = (state: RootState) =>
  Math.min(selectVipLevel(state) + 1, selecVipConfigList(state).length)

export const selectVipRewardsNum = createSelector(
  selectVipRewardList,
  (rewardList: VipRewardItem[]) => {
    const vipRewardsNum = rewardList.filter(
      item =>
        !item.is_claim && item.reward_type !== 0 && item.reward_type !== 4,
    ).length
    return vipRewardsNum
  },
)

const getLevelFloor = (
  lv: number,
  configs: VipConfigItem[],
  type: 'recharge' | 'bet',
) => {
  const MAX = 9999999999
  const conf = configs[lv]?.config
  if (!conf) {
    return MAX
  } else {
    return Number(
      type === 'recharge' ? conf.recharge_upgrade : conf.bet_upgrade,
    )
  }
}

const getVipProgress =
  (type: 'recharge' | 'bet', level?: number) =>
  (
    configs: VipConfigItem[],
    curLevel: number,
    userVipRecharge: number,
    userVipBet: number,
  ) => {
    const result: Progress = {
      current: 0,
      target: 0,
      diff: 0,
      percentage: 0,
    }

    if (level === undefined) {
      level = curLevel
    }

    const currLevelFloor = getLevelFloor(level, configs, type)
    const nextLevelFloor = getLevelFloor(level + 1, configs, type)
    result.target = Math.max(0, nextLevelFloor - currLevelFloor)
    const currValue = type === 'recharge' ? userVipRecharge : userVipBet
    result.current = clamp(Number(currValue) - currLevelFloor, 0, result.target)
    result.percentage =
      result.target !== 0
        ? Math.floor(clamp((result.current * 100) / result.target, 0, 100))
        : 0
    result.diff = result.target - result.current
    return result
  }

export const createVipProgressSelector =
  (type: 'recharge' | 'bet', level?: number) =>
  (state: RootState): Progress => {
    const result = {
      current: 0,
      target: 0,
      diff: 0,
      percentage: 0,
    }

    const getLevelFloor = (lv: number) => {
      const MAX = 9999999999
      const conf = configs[lv]?.config
      if (!conf) {
        return MAX
      } else {
        return Number(
          type === 'recharge' ? conf.recharge_upgrade : conf.bet_upgrade,
        )
      }
    }

    const vipInfo = selectUserVipInfo(state)
    if (!vipInfo) return result
    const configs = selecVipConfigList(state)
    if (level === undefined) {
      level = selectVipLevel(state)
    }

    const currLevelFloor = getLevelFloor(level)
    const nextLevelFloor = getLevelFloor(level + 1)
    result.target = Math.max(0, nextLevelFloor - currLevelFloor)
    const currValue = type === 'recharge' ? vipInfo.recharge : vipInfo.bet
    result.current = clamp(Number(currValue) - currLevelFloor, 0, result.target)
    result.percentage =
      result.target !== 0
        ? Math.floor(clamp((result.current * 100) / result.target, 0, 100))
        : 0
    result.diff = result.target - result.current
    return result
  }

export const { setRewardList } = newVipSlice.actions

export const newVipReducer = newVipSlice.reducer

/**
 * @description group reward list by reward type
 */
export const selectRewardListGroupByType = createSelector(
  selectVipRewardList,
  (rewardList: VipRewardItem[]) =>
    groupBy(rewardList, item => item.reward_type),
)

export const selectTypeMapRewardList = selectRewardListGroupByType

export const userVipConfigListSelector = createSelector(
  selecVipConfigList,
  selectVipLevel,
  selectUserRechargeAmount,
  selectUserBetAmount,
  (vipConfigList, curLevel, userVipRecharge, userVipBet) => {
    return vipConfigList.map(item => {
      const recharge_temp = getVipProgress('recharge', item.level)(
        vipConfigList,
        curLevel,
        userVipRecharge,
        userVipBet,
      )
      const bet_temp = getVipProgress('bet', item.level)(
        vipConfigList,
        curLevel,
        userVipRecharge,
        userVipBet,
      )
      const vipInfo = {
        recharge_progress: recharge_temp,
        bet_progress: bet_temp,
      }
      const reward = item.config.reward
      const refund = item.config.refund
      const config = item.config
      const newConfig = {
        recharge_upgrade: config.recharge_upgrade,
        recharge_relegation: toNumber(config.recharge_relegation),
        bet_upgrade: config.bet_upgrade,
        bet_relegation: toNumber(config.bet_relegation),
        monthly_free_fee_quota: config.monthly_free_fee_quota,
        withdraw_fee_rate: Number(config.withdraw_fee_rate),
      } as Config // maybe need declare all field

      const inhouseGameId = [
        'coinflip',
        'crash',
        'dice',
        'double',
        'double_speed',
        'fierybot',
        'keno',
        'limbo',
        'mines',
        'plinko',
        'ring',
        'roulette',
        'stairs',
      ]

      const inhouse_game_refund = [] as number[]
      if (refund.proprietary_game) {
        for (const key in refund.proprietary_game || {}) {
          const value = refund.proprietary_game?.[key] || 0
          if (inhouseGameId.includes(key)) {
            inhouse_game_refund.push(Number(value) || 0)
          }
        }
      }

      const valuesOfProprietaryGame = inhouse_game_refund
      const proprietary_game_average =
        valuesOfProprietaryGame.length > 0
          ? valuesOfProprietaryGame.reduce((a, b) => Number(a) + Number(b)) /
            valuesOfProprietaryGame.length
          : 0
      const cashbackMap = {
        ORIGINAL: numtofiexed(proprietary_game_average),
        SLOT: numtofiexed(refund.third_party_game?.evoplay ?? '0'),
        SPORTS: numtofiexed(refund.third_party_game?.slotegrator ?? '0'),
        LIVECASINO: numtofiexed(refund.third_party_game?.live_casino ?? '0'),
      }

      const offORIGINAL =
        cashbackMap.ORIGINAL === '0' || cashbackMap.ORIGINAL === '0.00'
      const offSLOT = cashbackMap.SLOT === '0' || cashbackMap.SLOT === '0.00'
      const offSPORTS =
        cashbackMap.SPORTS === '0' || cashbackMap.SPORTS === '0.00'
      const offLIVECASINO =
        cashbackMap.LIVECASINO === '0' || cashbackMap.LIVECASINO === '0.00'

      return {
        level: item.level,
        vipInfo,
        cashbackMap,
        offCashback: offORIGINAL && offSLOT && offSPORTS && offLIVECASINO,
        reward,
        config: newConfig,
      }
    })
  },
)
