import {
  Box,
  createStylesContext,
  Flex,
  Icon,
  Image,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react'
import type {
  ChakraStylesConfig,
  OptionProps,
  SingleValueProps,
} from 'chakra-react-select'
import { components, Select } from 'chakra-react-select'
import React, { createContext, useContext } from 'react'

import { getIcon } from '@/utils/get-icon'
import { handleS3SourceDomain } from '@/utils/tools'

import LazyImage from '../lazy-image/LazyImage'

const AtomSelectContext = createContext<{
  hiddenOnlyInOption: boolean
  useImage: boolean
  valuePrefix?: React.ReactNode
}>({
  hiddenOnlyInOption: false,
  useImage: false,
})

export interface AtomOption<V = unknown> extends Record<string, unknown> {
  value: V
  label?: string
  icon?: string | React.ReactNode
}

const [StylesProvider, useStyles] = createStylesContext('AtomSelect')

function CustomSingleValue<Option extends AtomOption>({
  children,
  ...props
}: SingleValueProps<Option>) {
  const styles = useStyles()
  const { useImage, valuePrefix } = useContext(AtomSelectContext)
  const icon = props?.data?.icon
  const text = props?.data?.text || null
  return (
    <components.SingleValue {...props}>
      {useImage ? (
        <Flex alignItems='center' w='100%' justifyContent='space-between'>
          {/* ?使用  LazyImage 默认值的logo无法显示*/}
          {/* <LazyImage
            src={(icon as string) || ''}
            alt={(props?.data.value as string) || ''}
            height='26px'
          /> */}
          <Image
            src={handleS3SourceDomain((icon as string) || '')}
            alt={(props?.data.value as string) || ''}
            height='26px'
          />
          {text && (
            <Text color='gray.600' fontSize='sm' ml='4'>
              {text}
            </Text>
          )}
        </Flex>
      ) : (
        <Flex sx={styles.singleValue}>
          {valuePrefix}
          {icon && typeof icon === 'string' ? (
            <Icon as={getIcon(icon)} boxSize='5' sx={styles.optionIcon} />
          ) : (
            icon
          )}
          <Text sx={styles.optionText}>{children}</Text>
        </Flex>
      )}
    </components.SingleValue>
  )
}

function CustomOption<Option extends AtomOption>({
  innerProps,
  innerRef,
  data,
  isSelected,
}: OptionProps<Option>) {
  const styles = useStyles()
  const { useImage, hiddenOnlyInOption } = useContext(AtomSelectContext)
  const textStyle = data?.text
    ? {
        w: '100%',
        justifyContent: 'space-between',
        padding: '0 6px',
      }
    : {
        justifyContent: 'center',
        background: 'gray.100',
        _hover: { borderColor: 'prim.500', background: 'gray.100' },
        border: isSelected ? '1px solid' : 'none',
        borderColor: isSelected ? 'prim.500' : 'none',
      }

  const iconRender = () => {
    if (data.icon && typeof data.icon === 'string') {
      if (!hiddenOnlyInOption) {
        return (
          <Icon as={getIcon(data.icon)} boxSize='5' sx={styles.optionIcon} />
        )
      }
    } else {
      return data.icon
    }
  }

  return (
    <Box ref={innerRef} {...innerProps} role='group'>
      {useImage && data?.icon ? (
        <Flex
          alignItems='center'
          height='64px'
          cursor='pointer'
          borderRadius='md'
          {...textStyle}
        >
          <LazyImage
            src={data.icon as string}
            width='100px'
            height='auto'
            alt={(data.value as string) || ''}
          />
          {data?.text && <Text fontSize='sm'>{data?.text}</Text>}
        </Flex>
      ) : (
        <Flex
          px='3'
          py='1.5'
          overflow='hidden'
          sx={styles.option}
          aria-selected={isSelected}
        >
          {iconRender()}
          <Text
            whiteSpace='nowrap'
            textOverflow='ellipsis'
            overflow='hidden'
            sx={styles.optionText}
          >
            {data.label}
          </Text>
        </Flex>
      )}
    </Box>
  )
}

export const NAME = 'AtomSelect'
export default function AtomSelect<Option extends AtomOption>(
  props: Omit<React.ComponentProps<typeof Select<Option>>, 'size'> & {
    hasBorder?: boolean
    useImage?: boolean
    hiddenOnlyInOption?: boolean
    useText?: boolean
    isSearchable?: boolean
    valuePrefix?: React.ReactNode
    size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
  },
) {
  const {
    size,
    hasBorder,
    useImage = false,
    useText = false,
    hiddenOnlyInOption = false,
    isSearchable = false,
    valuePrefix,
    ...restProps
  } = props
  const styles = useMultiStyleConfig(NAME, { size })
  const bgColor = hasBorder ? 'gray.50' : 'bg.control'
  const menuListStyle = useImage
    ? {
        padding: '8px',
        display: 'grid',
        gridGap: '8px',
        gridTemplateColumns: useText ? 'repeat(1,auto)' : 'repeat(2,auto)',
        // justifyContent: 'space-around',
      }
    : {}
  const selectChakraStyles: ChakraStylesConfig<Option> = {
    valueContainer: provided => ({
      ...provided,
      background: bgColor,
      caretColor: 'transparent',
      ...styles.valueContainer,
    }),
    dropdownIndicator: provided => ({
      ...provided,
      background: bgColor,
      ...styles.dropdownIndicator,
    }),
    menuList: provided => ({
      ...provided,
      boxShadow: 'none',
      ...styles.menuList,
      ...menuListStyle,
    }),
    control: (provided, state) => ({
      ...provided,
      background: bgColor,

      borderColor: hasBorder
        ? 'gray.400'
        : state.selectProps.menuIsOpen
        ? 'prim.500'
        : 'transparent',
      _hover: {
        background: bgColor,
      },
      _focusVisible: {
        background: bgColor,
      },
      ...styles.control,
    }),
    option: (provided, state) => ({
      ...provided,
      ...styles.option,
      color: state.isSelected ? 'text.accent' : 'text.secondary',
    }),
    singleValue: provided => ({
      ...provided,
      ...styles.singleValue,
    }),
    placeholder: provided => ({
      ...provided,
      ...styles.placeholder,
    }),
  }

  return (
    <StylesProvider value={styles}>
      <AtomSelectContext.Provider
        value={{
          hiddenOnlyInOption,
          useImage,
          valuePrefix,
        }}
      >
        <Select<Option>
          chakraStyles={selectChakraStyles}
          variant='filled'
          isSearchable={isSearchable}
          components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
          menuPortalTarget={document.body}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          {...restProps}
        />
      </AtomSelectContext.Provider>
    </StylesProvider>
  )
}
