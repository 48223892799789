import '@/styles/ckeditor.css'

import { Box, HStack, Image, Text, useDisclosure } from '@chakra-ui/react'
import { Prose } from '@nikolovlazar/chakra-ui-prose'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'

import TitledDialog from '@/components/modal/TitledDialog'
import { useAppSelector } from '@/modules/app/store'
import {
  selectUserCurrencySign,
  selectUserOriginalCurrencySign,
} from '@/modules/user/userSlice'
import { getCompatibleColors, getConfigLng, isFromAd } from '@/utils/tools'

import useClaimReward from '../../hooks/useClaimRewards'
import useDeleteNotification from '../../hooks/useDeleteNotification'
import { type Message, MessageShowType } from '../../types'
import NewMessageButtonArea from './NewMessageButtonArea'
import NewMessageEvent from './NewMessageEvent'
import NewMessageLink from './NewMessageLink'
import NewMessageReward from './NewMessageReward'

function NewMessageDetailModal() {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [message, setMessage] = useState<Message | null>(null)

  const originalSign = useAppSelector(selectUserOriginalCurrencySign)
  const fromAd = isFromAd()
  const currency = useAppSelector(selectUserCurrencySign)
  const lng = getConfigLng()

  const renderMessage = useMemo(() => {
    if (!message) return { title: '', message: '' }
    const CUSTOM_MESSAGE_VERSION = 1
    if (message.message_version === CUSTOM_MESSAGE_VERSION)
      return {
        title: message.message?.[lng]?.title,
        message: message.message?.[lng]?.content,
        img: message.message?.[lng]?.img,
      }
    return {
      title:
        message.title_key && fromAd
          ? t(message.title_key, message.params ?? {}).replaceAll(
              originalSign!,
              currency!,
            )
          : t(message.title_key!, message.params ?? {}),
      message:
        message.message_key && fromAd
          ? t(message.message_key, message.params ?? {}).replaceAll(
              originalSign!,
              currency!,
            )
          : t(message.message_key!, message.params ?? {}),
    }
  }, [currency, fromAd, message, originalSign])
  const isShowReceiveButton = useMemo(() => {
    const expireTimestamp = dayjs(
      message?.created_at ? message.created_at * 1000 : '',
    ).add(message?.rewards?.expire_day || 0, 'day')

    const nowTimestamp = dayjs().valueOf()
    if (expireTimestamp.valueOf() <= nowTimestamp) {
      return false
    }
    return (
      message?.show_type === MessageShowType.Reward &&
      !message?.is_claim_rewards
    )
  }, [
    message?.created_at,
    message?.is_claim_rewards,
    message?.rewards?.expire_day,
    message?.show_type,
  ])

  useEffect(() => {
    NewMessageEvent.on('openMessageDetail', message => {
      setMessage(message)
      onOpen()
    })

    return () => {
      NewMessageEvent.off('openMessageDetail')
    }
  })

  const { claimReward, isClaiming } = useClaimReward()

  const { handleDeleteNotification, isMarkMsgDeling } = useDeleteNotification()

  return (
    <Box>
      <TitledDialog
        isOpen={isOpen}
        onClose={onClose}
        title={t('MESSAGE_DETAIL')}
        isCentered
        size='md'
      >
        <Box>
          <Text
            textStyle='text6'
            color={getCompatibleColors('notification.detail.info', '#55657E')}
          >
            {t('MESSAGE_TEXT2')}
          </Text>
          <Text
            textStyle='text6'
            color={getCompatibleColors('notification.detail.info', '#55657E')}
            mt='1'
          >
            {t('MESSAGE_TEXT3')}:
            {dayjs(message?.created_at ? message.created_at * 1000 : '').format(
              'YYYY/MM/DD HH:mm:ss',
            )}
          </Text>
          {renderMessage.img && (
            <Image
              src={renderMessage?.img}
              width='full'
              height='auto'
              mt='3'
              borderRadius='10px'
              objectFit='cover'
            />
          )}
          <Box mt='3' maxH='400px' overflowY='auto'>
            <Prose
              // as='div'
              className='ck-content'
              wordBreak='break-word'
              // color='notification.detail.content'
              sx={{
                color: getCompatibleColors(
                  'notification.detail.content',
                  '#FFFFFF',
                ),
              }}
              dangerouslySetInnerHTML={{ __html: renderMessage?.message || '' }}
            />
          </Box>

          {message?.jump_url ? <NewMessageLink message={message} /> : null}

          {message?.show_type === MessageShowType.Reward ? (
            <Box mt='4'>
              <NewMessageReward message={message!} scene='detail' />
            </Box>
          ) : null}
          <HStack w='full' h='44px' gap='12px' mt='4'>
            <NewMessageButtonArea
              showReceiveButton={isShowReceiveButton}
              receiveBtnLoading={isClaiming}
              delBtnLoading={isMarkMsgDeling}
              receiveButtonContext={t('MESSAGE_BUTTON3').toString()}
              deleteButtonContext={t('MESSAGE_BUTTON4').toString()}
              receiveButtonClickCb={async () => {
                await claimReward(message)
                onClose()
              }}
              deleteButtonClickCb={async () => {
                handleDeleteNotification(message, onClose)
              }}
            />
          </HStack>
        </Box>
      </TitledDialog>
    </Box>
  )
}

export default NewMessageDetailModal
