import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

import {
  AspectRatio,
  Box,
  Flex,
  Skeleton,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react'
import type { FC } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Autoplay } from 'swiper/modules'
import type { SwiperProps } from 'swiper/react'
import { Swiper, SwiperSlide } from 'swiper/react'

import { AtomSectionHeading } from '@/components/atom-section-heading/AtomSectionHeading'
import BuiltInAvatar from '@/components/built-in-avatar/BuiltInAvatar'
import LazyImage from '@/components/lazy-image/LazyImage'
import { useCustomizedStyle } from '@/utils/hooks'
import { MAX_MOBILE_WIDTH } from '@/utils/hooks/useLargerThanMobile'
import { projectName } from '@/websites/current/property.json'

import type { TwentyFourHoursRankItemProps } from './types'
import { useTwentyFourHoursRank } from './useTwentyFourHoursRank'

enum BreakPoints {
  base = 0,
  sm = MAX_MOBILE_WIDTH,
  md = 960,
  lg = 1200,
}

const swiperProps: SwiperProps = {
  modules: [Autoplay],
  grabCursor: true,
  autoplay: true,
  observer: true,
  observeParents: true,
  freeMode: true,
}

// eslint-disable-next-line react-refresh/only-export-components
export function isH24HoursRankVariant(v: unknown): v is H24HoursRankVariant {
  return typeof v === 'string' && ['landscape', 'portriat'].includes(v)
}

const breakpoints = {
  [BreakPoints.base]: {
    slidesPerView: 3,
    spaceBetween: 10,
  },
  [BreakPoints.sm]: {
    slidesPerView: 4,
    spaceBetween: 10,
  },
  [BreakPoints.md]: {
    slidesPerView: 5,
    spaceBetween: 16,
  },
  [BreakPoints.lg]: {
    slidesPerView: 7,
    spaceBetween: 16,
  },
}

const baseItemStyles = {
  position: 'relative',
  borderRadius: 'md',
  overflow: 'hidden',
}

const baseBinWinStyles = {
  alignItems: 'flex-end',
  position: 'absolute',
  isolation: 'isolate',
}

const baseBinWinTextStyles = {
  position: 'relative',
  zIndex: -1,
  textStyle: 'h9',
}

const baseAmountStyles = {
  w: 'full',
  h: '26px',
  px: '2',
  alignItems: 'center',
  bg: 'seco.500',
  color: 'white',
  textStyle: 'h8',
}

const variants: any = {
  landscape: {
    item: {},
    binWin: {
      left: 2,
      bottom: '22px',
      zIndex: 1,
    },
    binWinText: {
      pl: 3,
      pr: 1,
      ml: -3,
      bg: 'white',
      transform: 'skewX(-10deg)',
      color: 'prim.500',
    },
    amount: {
      whiteSpace: 'nowrap',
    },
  },
  portriat: {
    item: {},
    binWin: {
      top: 0,
      left: 0,
      px: '2.5',
      py: '0.5',
      w: 'full',
      backdropFilter: 'blur(12px)',
      bgColor: 'blackAlpha.300',
    },
    binWinText: {
      color: 'white',
      ml: 2,
    },
    amount: {
      whiteSpace: 'nowrap',
    },
  },
}

type H24HoursRankVariant = 'landscape' | 'portriat'
export function H24HoursRank(props: {
  variant?: H24HoursRankVariant | string
}) {
  const { variant = 'landscape' } = props
  const { t } = useTranslation()
  const items = useTwentyFourHoursRank()

  const [isLargerThanBase] = useMediaQuery(`(min-width: ${BreakPoints.base}px)`)
  const [isLargerThanSm] = useMediaQuery(`(min-width: ${BreakPoints.sm}px)`)
  const [isLargerThanMd] = useMediaQuery(`(min-width: ${BreakPoints.md}px)`)
  const [isLargerThanLg] = useMediaQuery(`(min-width: ${BreakPoints.lg}px)`)

  const loop = useMemo(() => {
    const arr: [BreakPoints, boolean][] = [
      [BreakPoints.base, isLargerThanBase],
      [BreakPoints.sm, isLargerThanSm],
      [BreakPoints.md, isLargerThanMd],
      [BreakPoints.lg, isLargerThanLg],
    ]
    let lastMatchBreakPoint = arr[0][0]
    for (const [bp, isMatch] of arr) {
      if (isMatch) {
        lastMatchBreakPoint = bp
      }
    }
    return breakpoints[lastMatchBreakPoint].slidesPerView < items.length
  }, [
    isLargerThanBase,
    isLargerThanLg,
    isLargerThanMd,
    isLargerThanSm,
    items.length,
  ])

  if (!items || items?.length === 0) {
    return null
  }

  return (
    <VStack spacing='4' alignItems='stretch'>
      <AtomSectionHeading icon='Cup'>{t('24H TOP Bonus')}</AtomSectionHeading>
      <LazySwiper
        loop={loop}
        data={items}
        variant={variant as H24HoursRankVariant}
      />
    </VStack>
  )
}

interface LazySwiperProps {
  loop: boolean
  data: TwentyFourHoursRankItemProps[]
  variant: H24HoursRankVariant
}
const LazySwiper: FC<LazySwiperProps> = ({ loop, data, variant }) => {
  const [init, setInit] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setInit(true)
    }, 300)
  }, [])

  if (data.length === 0) return null

  if (!init)
    return (
      <AspectRatio ratio={[3.868, 5.227, 6.666, 7.06]}>
        <Skeleton w='100%' h='100%' />
      </AspectRatio>
    )
  return (
    <Swiper
      style={{ width: '100%' }}
      breakpoints={breakpoints}
      slidesPerGroup={1}
      {...swiperProps}
      loop={loop}
    >
      {data.map((item, index: number) => {
        return (
          <SwiperSlide key={index} onClick={item.onClick}>
            <ItemRender item={item} index={index} variant={variant} />
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

interface ItemRenderProps {
  item?: TwentyFourHoursRankItemProps
  index: number
  variant: H24HoursRankVariant
}
const ItemRender: FC<ItemRenderProps> = ({ item, index, variant }) => {
  const styles = useCustomizedStyle(
    'H24HoursRank',
    variants[variant],
    ['item', 'binWin', 'binWinText', 'amount'],
    { variant },
  )

  if (!item) {
    return null
  }

  const { gameCover, amountFormated } = item

  const imageRatio = variant === 'portriat' ? 1 : 132 / 83

  return (
    <Box sx={{ ...baseItemStyles, ...styles.item }}>
      <AspectRatio ratio={['N1'].includes(projectName) ? 1 : imageRatio}>
        <LazyImage src={gameCover} />
      </AspectRatio>
      <Flex sx={{ ...baseBinWinStyles, ...styles.binWin }}>
        <BuiltInAvatar
          avatarId={(index % 6).toString()}
          size='xs'
          width='25px'
          height='25px'
          borderWidth='2px'
        />
        {['N1'].includes(projectName) ? (
          <LazyImage
            src='/assets/images/games/bigwin.png'
            height='25px'
            marginLeft='-6px'
          />
        ) : (
          <Text sx={{ ...baseBinWinTextStyles, ...styles.binWinText }}>
            BIGWIN
          </Text>
        )}
      </Flex>
      <Flex sx={{ ...baseAmountStyles, ...styles.amount }}>
        <Text>{amountFormated}</Text>
      </Flex>
    </Box>
  )
}

export default H24HoursRank
