/**
 *
 * @param balance user balance
 * @param configMax max value from project global config
 * @param autoMax auto mode max
 */
export function getMax(
  balance: number,
  configMax: number,
  autoMax?: number,
): number {
  const maxArray = []
  maxArray.push(configMax > 0 ? configMax : Infinity)
  maxArray.push(balance > 0 ? balance : 0)
  if (autoMax !== undefined) maxArray.push(autoMax > 0 ? autoMax : Infinity)

  return Math.min(...maxArray)
}
