import { Button, useToast } from '@chakra-ui/react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { VerifyCodeCountDownRef } from '@/modules/login/New/components/VerifyCodeCountDown'
import VerifyCodeCountDown from '@/modules/login/New/components/VerifyCodeCountDown'

import {
  type LoginVerifyCodeType,
  type UnloginVerifyCodeType,
  useSendCodeLoginMutation,
  useSendCodeUnloginMutation,
} from '../loginApi'
import { getLoginCodeErrorMessage } from '../tools'

type VerifyCodeType = {
  /**
   * 执行获取验证码的前置校验
   * @returns 返回的是boolean true为有存在错误
   */
  handlePrecalibration?: () => Promise<boolean>
  /**
   * 按钮样式覆盖
   */
  btnStyles?: Record<string, any>
  /**
   * 验证码收取账号
   */
  account: () => string
  /**
   * 未登录获取验证码类型
   */
  /**
   * 请求验证码flowId
   */
  reqFlowId?: string
  /**
   * uvctVerifyPhone/uvctBindingPhone 用于区分个人中心(profile)或者 提现操作(withdraw)
   */
  from?: 'profile' | 'withdraw'
}

type UnloginVerifyCodeProps = {
  unLoginType: UnloginVerifyCodeType
  loginType?: never
}

type LoginVerifyCodeProps = {
  loginType: LoginVerifyCodeType
  unLoginType?: never
}

export default function useVerifyCode({
  handlePrecalibration,
  account,
  btnStyles = {},
  loginType,
  unLoginType,
  reqFlowId,
  from,
}:
  | (VerifyCodeType & UnloginVerifyCodeProps)
  | (VerifyCodeType & LoginVerifyCodeProps)) {
  const toast = useToast()
  const { t } = useTranslation()
  const [errorCode, setErrorCode] = useState(null)

  const storageScene = useMemo(() => {
    if (loginType) {
      return `login_verify_code_${loginType}`
    }

    if (unLoginType) {
      return `unlogin_verify_code_${unLoginType}`
    }

    return ''
  }, [loginType, unLoginType])

  const [isDisabled, setIsDisabled] = useState(false)

  const countDownRef = useRef<VerifyCodeCountDownRef>(null)
  const [flowId, setFlowId] = useState<string>('')

  /**
   * 获取登录态验证码
   */
  const [sendCodeLogin, { isLoading: isSendCodeLoginLoading }] =
    useSendCodeLoginMutation()

  /**
   * 获取未登录态验证码
   */
  const [sendCodeUnLogin, { isLoading: isSendCodeUnloginLoading }] =
    useSendCodeUnloginMutation()

  useEffect(() => {
    if (isSendCodeLoginLoading || isSendCodeUnloginLoading) {
      setIsDisabled(true)
    }
  }, [isSendCodeLoginLoading, isSendCodeUnloginLoading])

  useEffect(() => {
    setIsDisabled(
      !!(
        Number(localStorage.getItem(storageScene) ?? 0) > new Date().getTime()
      ),
    )
  }, [storageScene])

  const handleSendCode = async (unToast?: boolean) => {
    if (isDisabled && !unToast) {
      return 'is disabled'
    }

    if (handlePrecalibration) {
      if (await handlePrecalibration()) {
        return 'precalibration failed'
      }
    }
    if (!account()) {
      return 'account is empty'
    }
    try {
      const params = {
        account_value: account(),
        verify_type: loginType || unLoginType,
        flow_id: reqFlowId || flowId || '',
        from,
      }
      setIsDisabled(true)
      const { data } = await (loginType ? sendCodeLogin : sendCodeUnLogin)(
        params,
      ).unwrap()
      setFlowId(data.flow_id)
      if (!unToast) {
        const type = account().includes('@') ? 'email' : 'phone'
        toast({
          title: t(
            type === 'email' ? 'SENDED_CODE_BY_EM' : 'SENDED_CODE_BY_PH',
            { phonenumber: account() },
          ),
          status: 'success',
        })
      }

      countDownRef?.current?.startCountDown()
      return true
    } catch (e: any) {
      setErrorCode(e.status)
      let title = t(getLoginCodeErrorMessage(e.status) || 'ABNORMAL_OPERATION')
      if (e.status === 6) {
        title = t('ACCOUNT_REGISTED')
      }
      if (e.status === 5) {
        title = t('WRONG_REGISTER_NO')
      }
      setIsDisabled(false)
      toast({
        title,
        status: 'error',
      })
      return 'error'
    }
  }

  const BtnRender = useMemo(() => {
    const BtnRender = () => (
      <Button
        flexShrink={0}
        color='white'
        ml='2'
        bg='seco.500'
        fontSize='16px'
        w='116px'
        borderRadius='8px'
        _disabled={{
          bg: 'gray.600',
          cursor: 'not-allowed',
          _hover: {
            opacity: 1,
          },
        }}
        colorScheme={'fill-seco'}
        height='46px'
        isDisabled={isDisabled}
        onClick={() => {
          handleSendCode()
        }}
        {...btnStyles}
      >
        <VerifyCodeCountDown
          ref={countDownRef}
          countDownEndCb={() => {
            setIsDisabled(false)
          }}
          scene={storageScene}
        />
      </Button>
    )
    BtnRender.displayName = 'BtnRender'
    return BtnRender
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [btnStyles, isDisabled, storageScene])

  return {
    BtnRender,
    flowId,
    errorCode,
    handleSendCode,
  }
}
