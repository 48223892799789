import { InfoOutlineIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  Link,
  SimpleGrid,
  Text,
  useToast,
} from '@chakra-ui/react'
import { t } from 'i18next'
import { isEmpty } from 'lodash-es'
import type { FocusEventHandler } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getAppConfig } from '@/common/app-config'
import { getVariable } from '@/common/env'
import { FeatureToggle, isFeatureEnabled } from '@/common/feature'
import { getCurrencyCode } from '@/common/locale/currency'
import PerformanceMarkTag from '@/common/pmsdk/performance-mark-tag'
import { FormItem } from '@/components/forms/DynamicForms'
import { FormType } from '@/components/forms/types'
// import AtomSelect from '@/components/atom-select/AtomSelect'
import LazyImage from '@/components/lazy-image/LazyImage'
import { PaymentSelect } from '@/components/payment-select/PaymentSelect'
import { SplitLine } from '@/modules/activity/RechargePackageModal'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { useUserAuth, useWalletConfig } from '@/utils/hooks'
import { formatLocalMoney, getDeviceType } from '@/utils/tools'
import property from '@/websites/current/property.json'

import { useAppDispatch, useAppSelector } from '../app/store'
import { openModal, selectModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import {
  selectUserIsLogin,
  selectUserOriginalCurrencySign,
} from '../user/userSlice'
import GcashTipsModal from './GcashTipsModal'
import MxnEmailModal from './mxnEmailModal'
import WalletJoinAct from './rechargeJoinAct'
import RechargeTaskPrivate from './RechargeTaskPrivate'
import { useRecharge } from './useRecharge'
import useRechargeActivity from './useRechargeActivity'
import { useRechargeWays } from './useRechargeWays'
import { useWalletTutorial } from './useWalletTutorial'
import { verifyPix } from './verifyFunction'
import WalletAmountItem from './walletAmountItem'
import {
  useGetPlayerRechargeInfoQuery,
  useRechargeArgsQuery,
} from './walletApi'
import WalletButton from './walletButton'
import WalletService from './walletService'
import {
  getJoinActFlag,
  getRechargePaymentOptions,
  openParamsModal,
  selectActivedQuickRechargeItemIndex,
  selectIsFromAct,
  selectRechargeAmount,
  selectRechargeInfo,
  selectWallet,
  selectWalletConfig,
  selectWalletDurationLoaded,
  setDefaultRechargeAmount,
  setHandleType,
  setJoinActFlag,
  setQuestType,
  setRechargeAmount,
  setRechargeAmountList,
  setShowMxnRecahrgeEmail,
} from './walletSlice'

const { IS_MXN_RECAHRGE = false, rechargeJoinActType } =
  property.features as any

const FORMAT_STR = /[^\w^\s^\u4e00-\u9fa5]/gi

const rechargeInfoNeed = isFeatureEnabled('rechargeInfoNeed')

function RechargeInfo({
  rechargeInfoLoading,
}: {
  rechargeInfoLoading: boolean
}) {
  const dispatch = useAppDispatch()
  const rechargeInfo = useAppSelector(selectRechargeInfo)
  const rechargeParams = useAppSelector(
    state => selectWallet(state).rechargeParams,
  )
  const isLogin = useAppSelector(selectUserIsLogin)

  useGetPlayerRechargeInfoQuery({}, { skip: !rechargeInfoNeed || !isLogin })

  const handleOpenEditModal = () => {
    dispatch(
      openModal({
        key: GlobalModalKey.WalletConfirmForm,
        data: {
          type: 'recharge_edit',
          initialValues: {
            ...rechargeInfo,
            ...(Object.values(rechargeInfo || {})?.some(value => value)
              ? {}
              : rechargeParams),
          },
        },
      }),
    )
  }

  if (
    !rechargeInfoNeed ||
    rechargeInfoLoading ||
    !Object.keys(rechargeParams).some(
      key => !['pay_method', 'typ'].includes(key),
    )
  ) {
    return null
  }

  return (
    <Box>
      <FormLabel
        color='text.base'
        textStyle='text3'
        px='1.5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        {t('DEPOSIT_INFO')}

        {/* <FeatureToggle feature='withdrawalVideoTutorials'>
          <WithdrawalVideoTutorialsButton />
        </FeatureToggle> */}
      </FormLabel>
      <HStack justifyContent='space-between'>
        <Flex
          flex='1 1 '
          backgroundColor={'var(--chakra-colors-bg-control)'}
          borderRadius='10px'
          p='0 12px 0 16px'
          alignItems='center'
          h='46px'
          overflow={'hidden'}
        >
          <Icon boxSize='24px' as={SharedIcon.Wallet} />
          <Text
            textStyle='text4'
            ml='8px'
            textOverflow={'ellipsis'}
            overflow={'hidden'}
            whiteSpace={'nowrap'}
          >
            {rechargeInfo?.name ||
              rechargeInfo?.first_name ||
              t('GAME_NO_INFORMATION')}
          </Text>
        </Flex>
        <Button
          colorScheme={getAppConfig(
            'wallet.walletButton.colorScheme',
            'fill-seco',
          )}
          onClick={handleOpenEditModal}
          size='lg'
          fontWeight='normal'
          fontSize='sm'
        >
          {t('EDIT_CHANGE')}
        </Button>
      </HStack>
    </Box>
  )
}

function RechargePanel() {
  const [isSwitchRechargeMethod, setSwitchRechargeMethod] = useState(false)
  const walletDurationLoaded = useAppSelector(selectWalletDurationLoaded)
  // 是否是来自充值活动充值
  const is_from_recharge_act = useAppSelector(selectIsFromAct)
  const CURRENCY = getCurrencyCode() || ''
  const [showInput, setShowInput] = useState(false)
  // 新的充值活动=============================================================
  const RECHARGE_BY_POST = getVariable('RECHARGE_BY_POST')
  const PIX_RECHARGE = getVariable('IS_PIX_RECHARGE') || false
  const { getRechargInfoList, rechargeSaveInfo, paramasErrorHandal } =
    useRechargeWays()
  const joinActFlag = useAppSelector(getJoinActFlag)
  const toast = useToast()
  const [amountError, setAmountError] = useState<string | null>('')
  const [user_id, token] = useUserAuth()
  const dispatch = useAppDispatch()
  const rechargeAmount = useAppSelector(selectRechargeAmount)
  const { getBonusByAmount, getActItemByAmount } = useRechargeActivity()
  const rechargeParams = useAppSelector(
    state => selectWallet(state).rechargeParams,
  )

  /**
   * 充值补充信息
   */

  const rechargeInfo = useAppSelector(selectRechargeInfo)

  const { recharge_btn_rule_config } = useAppSelector(selectWalletConfig)
  const { showRechargeTutorial, openTutorialModal } = useWalletTutorial()

  // 充值方式options
  const options = useAppSelector(getRechargePaymentOptions)

  // 这里用的是原始货币符号 用于充值金额以及增金额的显示
  const CURRENCY_SYMBOL = useAppSelector(selectUserOriginalCurrencySign)
  const { data: modalData } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.Wallet],
  )

  const activedItem = useMemo(() => {
    return getActItemByAmount(Number(rechargeAmount))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rechargeAmount])

  useEffect(() => {
    setSwitchRechargeMethod(false)
  }, [is_from_recharge_act])

  const {
    AMOUNT_LIST,
    MAX_RECHARGE_AMOUNT,
    MIN_RECHARGE_AMOUNT,
    DEFAULT_AMOUNT,
    DEFAULT_INDEX,
  } = useWalletConfig(CURRENCY, {
    is_from_recharge_act,
  })

  const activedQuickRechargeItemIndex = useAppSelector(
    selectActivedQuickRechargeItemIndex,
  )
  useEffect(() => {
    dispatch(setRechargeAmountList(AMOUNT_LIST))
    !isSwitchRechargeMethod &&
      dispatch(
        setDefaultRechargeAmount({
          default_amount: DEFAULT_AMOUNT,
          default_index: DEFAULT_INDEX,
        }),
      )
  }, [
    AMOUNT_LIST,
    DEFAULT_AMOUNT,
    DEFAULT_INDEX,
    is_from_recharge_act,
    isSwitchRechargeMethod,
  ])

  const TYPE = RECHARGE_BY_POST ? 'POST' : 'GET'
  const { isLoading: rechargeargsLoading } = useRechargeArgsQuery({
    user_id,
    token,
    type: TYPE,
    currency: CURRENCY,
  })
  const { isLoading: rechargeInfoLoading } = useGetPlayerRechargeInfoQuery(
    {},
    { skip: !rechargeInfoNeed || !user_id || !token },
  )
  const {
    trigger: recharge,
    result: { isLoading: isRecharging },
  } = useRecharge()

  useEffect(() => {
    walletDurationLoaded && !is_from_recharge_act && checkAmount(rechargeAmount)
  }, [
    rechargeParams?.typ,
    rechargeAmount,
    walletDurationLoaded,
    MIN_RECHARGE_AMOUNT,
    MAX_RECHARGE_AMOUNT,
    is_from_recharge_act,
  ])

  /** 处理错误信息表现方式 */
  const handleErrorInfo = useCallback(
    (errorText: string) => {
      setAmountError(errorText || '')
      return
      if (is_from_recharge_act) {
        !!errorText && toast({ status: 'error', title: errorText || '' })
      } else {
        setAmountError(errorText || '')
      }
    },
    [is_from_recharge_act],
  )

  const checkAmount = (value: number) => {
    if (value === 0) {
      handleErrorInfo(t('DEPOSIT_AMOUNT_EMPTY2') || '')
      return false
    }

    const max_recharge =
      MAX_RECHARGE_AMOUNT[
        is_from_recharge_act ? 'DEFAULT' : rechargeParams.typ
      ] || MAX_RECHARGE_AMOUNT['DEFAULT']
    const min_recharge =
      MIN_RECHARGE_AMOUNT[
        is_from_recharge_act ? 'DEFAULT' : rechargeParams.typ
      ] || MIN_RECHARGE_AMOUNT['DEFAULT']

    if (value > max_recharge) {
      handleErrorInfo(
        t('DESPOSIT_AMOUNT_MAX_SHORT_TIPS', { max_amount: max_recharge }) || '',
      )
      return false
    } else if (value < min_recharge) {
      handleErrorInfo(
        t('DESPOSIT_AMOUNT_LESS_SHORT_TIPS', { min_amount: min_recharge }) ||
          '',
      )
      return false
    } else {
      handleErrorInfo('')
      return true
    }
  }

  // 充值金额
  const amountChange = (value: string) => {
    value = value?.replace(/[^\d]+/g, '') || ''
    if (isNaN(Number(value))) {
      value = 0
    }
    dispatch(setRechargeAmount(Number(value)))

    /** 来自于充值活动页面 */
    if (is_from_recharge_act) {
      checkAmount(Number(value))
    }
  }

  const handleAmountInputBlur: FocusEventHandler<HTMLInputElement> = e => {
    if (!rechargeAmount) {
      setShowInput(false)
    }
    let value = Number(e.target.value)
    if (isNaN(value)) {
      value = 0
    }
    // checkRechargeAmount(value)
    /**
     * 总体原则：
     * 九宫格有个运营配置的充值范围（即提供的数据最小最大值）
     * 每种充值方式有个最大最小充值金额
     * 用户有个输入的金额
     * 原则：取子集
     */
  }

  //if M-PESA
  const IS_M_PESA_RECHARGE = useMemo(() => {
    if (!rechargeParams?.typ) return false
    return rechargeParams?.typ.toLocaleUpperCase() === 'M-PESA'
  }, [rechargeParams])
  //pix
  const IS_PIX_RECHARGE = useMemo(() => {
    if (!rechargeParams?.typ) return false
    return rechargeParams?.typ.toLocaleUpperCase() === 'PIX'
  }, [rechargeParams])
  // pix qr code
  const IS_PIX_QRCODE_RECHARGE = useMemo(() => {
    if (!rechargeParams?.typ) return false
    return (
      rechargeParams?.typ.toLocaleUpperCase() === 'PIXQR' ||
      rechargeParams?.typ.toLocaleUpperCase() === 'PIXQRNEW'
    )
  }, [rechargeParams])
  //
  const IS_MXN_SPEI_RECHARGE = useMemo(() => {
    if (!rechargeParams?.typ) return false
    return (
      IS_MXN_RECAHRGE &&
      rechargeParams?.typ.replace(FORMAT_STR, '').toLocaleLowerCase() ===
        'speipersonnal'
    )
  }, [rechargeParams?.typ])
  // 发起充值 有渠道选择选择渠道 没有填写数据
  const handleRecharge = async () => {
    performance.mark(PerformanceMarkTag.RechargeStart)
    if (!checkAmount(rechargeAmount)) return

    if (modalData?.from === 'prompte_app_activity' && joinActFlag) {
      const device = getDeviceType()
      if (['apk', 'ios-desktop-icon'].indexOf(device) === -1) {
        dispatch(
          openModal({
            key: GlobalModalKey.IntroductionToDownloadModal,
            data: {
              modalCfg: {
                title: 'APPUSER_ONLY_TLTLE',
                reward: Number(activedItem?.reward?.coefficient_amount)
                  ? (
                      Number(activedItem?.reward?.coefficient_amount) * 100
                    ).toFixed(0) + '%'
                  : Number(activedItem?.reward?.fixed_amount) > 0
                  ? `+${activedItem?.reward?.fixed_amount}`
                  : '',
                content:
                  ['android', 'pc'].indexOf(device) > -1
                    ? 'APPUSER_ONLY'
                    : 'SCREENUSER_ONLY',
              },
            },
          }),
        )
        return
      }
    }

    /** col新增了充值信息保存 */
    if (
      rechargeInfoNeed &&
      Object.keys(rechargeParams).some(
        key => !['pay_method', 'typ'].includes(key),
      )
    ) {
      if (!rechargeInfo?.name) {
        dispatch(
          openModal({
            key: GlobalModalKey.WalletConfirmForm,
            data: {
              type: 'recharge',
              initialValues: {
                ...rechargeInfo,
                ...(rechargeInfo?.name ? {} : rechargeParams),
              },
              rechargeParams: {
                rechargeAmount,
              },
            },
          }),
        )
        return
      }
    }
    if (IS_M_PESA_RECHARGE) {
      /** 2024/2/21 k1注释该充值方式在站内填写手机号码 */
      /** 2024/3/18 k1该充值方式在站内填写手机号码 */
      dispatch(
        openModal({
          key: GlobalModalKey.MPesaModal,
          data: {
            rechargeParams: {
              rechargeAmount,
            },
          },
        }),
      )
      return
    }

    if ((options && options.length === 0) || PIX_RECHARGE) {
      // 填写数据充值
      // B系列新版充值 由于把充值参数返回在一个接口 需人工智能处理 兼容渠道选择&二维码
      //b系列充值默认使用提款信息的第一条数据且不弹出填写框 !IS_PIX_QRCODE_RECHARGE &&
      //
      if (
        !isEmpty(rechargeSaveInfo) &&
        rechargeSaveInfo?.pix &&
        IS_PIX_QRCODE_RECHARGE
      ) {
        if (
          !verifyPix({
            params: {},
            value: rechargeSaveInfo?.pix,
          }).verify
        ) {
          paramasErrorHandal()
          return
        }
        const params: any = {
          ...rechargeParams,
          ...rechargeSaveInfo,
          name: rechargeSaveInfo?.name.replace(/\s+/g, ''),
        }
        delete params?.id
        delete params?.is_default
        recharge(params)
        return
      }
      if (IS_PIX_RECHARGE) {
        recharge({ ...rechargeParams })
        return
      }
      //  //b系列充值默认使用提款信息的第一条数据
      dispatch(setHandleType('recharge'))
      dispatch(openParamsModal())
      return
    }
    dispatch(setQuestType('request'))
    if (IS_MXN_SPEI_RECHARGE) {
      dispatch(setShowMxnRecahrgeEmail(true))
      return
    }
    recharge({
      ...rechargeParams,
      ...(rechargeInfoNeed &&
      Object.keys(rechargeParams).some(
        key => !['pay_method', 'typ'].includes(key),
      )
        ? rechargeInfo
        : {}),
    })
  }

  useEffect(() => {
    getRechargInfoList()
  }, [getRechargInfoList])

  /** 渲染充值按钮 */
  const renderRechargeBtn = () => {
    return (
      <Box marginTop='24px'>
        <WalletButton
          onClick={handleRecharge}
          loading={isRecharging}
          disabled={rechargeargsLoading || rechargeInfoLoading}
          colorScheme='fill-prim'
        >
          {t('DEPOSIT_RECHARGE')}
        </WalletButton>
      </Box>
    )
  }

  /** 是否参加活动单选框 */
  const renderActCheckbox = () => {
    const showCheckbox =
      recharge_btn_rule_config?.btn_non_participation === 1 &&
      !is_from_recharge_act
    if (!showCheckbox) return null
    return (
      <Flex alignItems='center' width='100%' marginBottom='8px'>
        <Checkbox
          isChecked={!joinActFlag}
          borderColor='gray.500'
          onChange={e => dispatch(setJoinActFlag(!e.target.checked))}
        >
          <Text textStyle='text6' color='gray.700'>
            {t('REACHRGA_TASK_MODAL_NO_CHOOSE')}
          </Text>
        </Checkbox>
      </Flex>
    )
  }

  /** 渲染充值活动卡片 */
  const renderRechargeActCard = () => {
    const showActCard =
      (joinActFlag && !!rechargeAmount && rechargeJoinActType) ||
      is_from_recharge_act

    if (!showActCard) return null
    const _rechargeJoinActType: 'text' | 'pic' = is_from_recharge_act
      ? 'text'
      : rechargeJoinActType
    return (
      <WalletJoinAct
        type={_rechargeJoinActType}
        amount={rechargeAmount}
        isFromRechargeAct={is_from_recharge_act}
      />
    )
  }

  /** 渲染快捷金额九宫格 */
  const renderAmountGrid = () => {
    const btn_num = is_from_recharge_act
      ? 3
      : recharge_btn_rule_config?.btn_num || 3
    return (
      <SimpleGrid
        columns={btn_num}
        columnGap='6.5px'
        rowGap='1.5'
        my={is_from_recharge_act ? '1' : '5'}
      >
        {AMOUNT_LIST.map((item, index: number) => {
          return (
            <WalletAmountItem
              key={item.value}
              btn_num={btn_num}
              {...item}
              index={index}
              active={activedQuickRechargeItemIndex}
              onClick={() => {
                setShowInput(false)
                dispatch(setRechargeAmount(item.value))
              }}
            />
          )
        })}
        {is_from_recharge_act && (
          <Box
            as={Button}
            h='60px'
            role='group'
            cursor='pointer'
            position='relative'
            borderRadius='lg'
            overflow='hidden'
            padding={'0px'}
            colorScheme={'fill-seco-unactive-amount'}
            onClick={() => {
              setShowInput(true)
            }}
          >
            <LazyImage
              src='/assets/images/login-reward-modal/light.png'
              alt='amount-bg'
              position={'absolute'}
              top='50%'
              left='50%'
              transform='translate(-50%,-50%)'
            />
            {!showInput ? (
              <Box
                display={'flex'}
                cursor={'pointer'}
                flexDirection={'column'}
                alignItems={'center'}
                padding={'0px 30px'}
              >
                <Text
                  fontSize={'14px'}
                  h='full'
                  sx={{ textWrap: 'wrap' }}
                  lineHeight={'normal'}
                >
                  {t('BENEFITS_PLACEHOLDER_AT')}
                </Text>
              </Box>
            ) : (
              <Box
                position='relative'
                display={'flex'}
                h='30px'
                alignItems={'center'}
                padding={'0px 10px'}
              >
                <Box color='#ffffff'>{CURRENCY_SYMBOL}</Box>
                <Input
                  value={rechargeAmount}
                  onChange={e => {
                    amountChange(e?.target?.value || '')
                  }}
                  onBlur={handleAmountInputBlur}
                  textAlign='center'
                  background={'none'}
                  padding={'0px'}
                  flex={1}
                  h='100%'
                  sx={{
                    border: 'none',
                    color: '#ffffff',
                    fontSize: '18px',
                    borderBottom: '1px solid #ffffff',
                    borderRadius: '0px',
                  }}
                  _focus={{
                    borderBottom: '1px solid #ffffff',
                  }}
                />
              </Box>
            )}
          </Box>
        )}
      </SimpleGrid>
    )
  }

  /** 渲染金额输入框 */
  const renderInput = () => {
    const currencySymbol = joinActFlag
      ? `${formatLocalMoney(getBonusByAmount(rechargeAmount), CURRENCY_SYMBOL)}`
      : `${formatLocalMoney(getBonusByAmount(0), CURRENCY_SYMBOL)}`
    return (
      <Box position='relative'>
        <FormItem
          type={FormType.number}
          currencySymbol={t('PURCHASE_EXTRA_WORD') + ' ' + currencySymbol}
          showCurrency={true}
          isInvalid={!!amountError}
          hideLabel={true}
          value={String(rechargeAmount)}
          onChange={amountChange}
          error={amountError || null}
          preStyle={{
            width: 'auto',
            paddingLeft: '15px',
            textAlign: 'left',
          }}
        />
      </Box>
    )
  }

  /** 渲染充值方式选择 */
  const renderPaySelect = () => {
    const boundaryShadow = {}
    if (is_from_recharge_act) {
      Object.assign(boundaryShadow, {
        left: {
          background: 'none',
        },
        right: {
          background: 'none',
        },
      })
    }
    return (
      <Box marginTop='10px' position='relative' zIndex='3'>
        {options && options.length > 0 && (
          <>
            {(() => {
              if (is_from_recharge_act) {
                return (
                  <Flex
                    position='relative'
                    alignItems='center'
                    justifyContent='space-between'
                    margin='0 auto'
                    marginTop='24px'
                    marginBottom='12px'
                    fontSize={14}
                    lineHeight='18px'
                    color='#fff'
                    textAlign={'center'}
                  >
                    <SplitLine position='right' />
                    {t('RECHARGE_TYPE')}
                    <SplitLine position='left' />
                  </Flex>
                )
              } else {
                return (
                  <Flex
                    marginBottom='12px'
                    marginTop='24px'
                    fontSize='14px'
                    color='gray.700'
                    cursor='default'
                    alignItems={'center'}
                  >
                    {t('RECHARGE_TYPE')}
                    {showRechargeTutorial && (
                      <IconButton
                        ml={'7px'}
                        aria-label='info'
                        variant='unstyledIcon'
                        verticalAlign='middle'
                        onClick={() => {
                          openTutorialModal('recharge')
                        }}
                        icon={<Icon as={SharedIcon.Question} boxSize='4' />}
                      />
                    )}
                  </Flex>
                )
              }
            })()}
            <PaymentSelect
              options={options}
              rechargeParams={rechargeParams}
              styles={{ boundaryShadow }}
              onSelect={() => {
                setSwitchRechargeMethod(true)
              }}
            />
          </>
        )}
      </Box>
    )
  }

  /** 来自新的充值活动 */
  if (is_from_recharge_act) {
    return (
      <>
        <Flex justifyContent={'center'} mt={'-5px'} mb={'10px'}>
          <Text textAlign={'center'}>
            {t('INDULGE_SET_DEPOSIT')}
            {showRechargeTutorial && (
              <IconButton
                ml={'7px'}
                aria-label='info'
                variant='unstyledIcon'
                verticalAlign='middle'
                onClick={() => {
                  openTutorialModal('recharge')
                }}
                icon={<Icon as={SharedIcon.Question} boxSize='4' />}
              />
            )}
          </Text>
        </Flex>
        {renderAmountGrid()}
        {amountError && (
          <Text textAlign={'center'} fontSize={'10px'} color='red' mt='15px'>
            <InfoOutlineIcon boxSize={'2.5'} color={'red'} mr='3px' />
            {amountError}
          </Text>
        )}
        {renderRechargeActCard()}
        {renderPaySelect()}

        <Box mt='20px'>
          <RechargeInfo
            rechargeInfoLoading={rechargeInfoLoading || rechargeargsLoading}
          />
        </Box>
        {renderRechargeBtn()}
        <WalletService />
      </>
    )
  }

  /** 常规充值 */
  return (
    <>
      {renderPaySelect()}
      <Flex
        marginBottom='8px'
        marginTop='16px'
        textStyle='text3'
        color='gray.700'
        cursor='default'
        justifyContent='space-between'
      >
        {t('DEPOSIT_RECHARGEAMOUNT')}

        <GcashTipsModal rechargeTyp={rechargeParams.typ} />
      </Flex>
      {renderInput()}
      {renderAmountGrid()}
      {renderRechargeActCard()}
      <RechargeInfo
        rechargeInfoLoading={rechargeInfoLoading || rechargeargsLoading}
      />
      {renderActCheckbox()}
      {/** 充值活动私域模块 */}
      {activedItem.id && joinActFlag && (
        <RechargeTaskPrivate
          activedItem={activedItem}
          rechargeAmount={rechargeAmount}
        />
      )}
      {renderRechargeBtn()}

      <MxnEmailModal />
    </>
  )
}

export default RechargePanel

export function HelpLink({ i18nKey, url }: { i18nKey: string; url: string }) {
  const { t } = useTranslation()
  return (
    <Flex
      alignItems='center'
      width='100%'
      mx='10px'
      fontSize='12px'
      color='link'
      marginBottom='0'
      marginTop='0'
    >
      <Icon as={SharedIcon.Youtube} fontSize='24px' />
      <Link href={url} target='_blank' rel='noreferrer' marginLeft='8px'>
        {t(i18nKey)}
      </Link>
    </Flex>
  )
}
