import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import LazyImage from '@/components/lazy-image/LazyImage'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { useCustomizedStyle } from '@/utils/hooks'
import { getColorModeValue } from '@/utils/tools'
import fallbackIcon from '@/websites/current/public/icon.png'

import type { TwentyFourHoursRankItemProps } from './types'
import { useTwentyFourHoursRank } from './useTwentyFourHoursRank'

const swiperProps = {
  modules: [Autoplay],
  grabCursor: true,
  autoplay: true,
  observer: true,
  observeParents: true,
  freeMode: true,
  loopFillGroupWithBlank: true,
}

type V24HoursRankVariant = 'three-column' | 'backdrop-image'
export function V24HoursRank(props: {
  variant?: V24HoursRankVariant | string
}) {
  const variant = (props.variant ?? 'three-column') as V24HoursRankVariant
  const { t } = useTranslation()
  const items = useTwentyFourHoursRank()

  const baseHeadingStyles = {
    textAlign: 'center',
    borderRadius: 'md',
    whiteSpace: 'nowrap',
    color: 'white',
    h: '9',
    textStyle: 'h5',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexShrink: 0,
  }

  const variants = {
    'three-column': {
      heading: {
        bg: 'prim.600',
        layerStyle: 'ls-seco-to-r',
      },
    },
    'backdrop-image': {
      heading: {
        layerStyle: 'ls-seco-to-r',
      },
    },
  }

  const styles = useCustomizedStyle(
    'V24HoursRank',
    variants[variant],
    ['heading'],
    { variant },
  )

  return (
    <>
      <Flex sx={{ ...baseHeadingStyles, ...styles.heading }}>
        <Text>{t('NEW_HOME_AWARD_TOP')}</Text>
        <Divider orientation='vertical' h='75%' borderColor='whiteAlpha.500' />
        <Text>{t('NEW_HOME_AWARD_TEXT')}</Text>
      </Flex>
      <VStack spacing='2.5' flex='1' alignItems='stretch' overflowY='auto'>
        {items.length === 0 ? null : (
          <Swiper
            direction='vertical'
            style={{ height: '100%', width: '100%' }}
            slidesPerView='auto'
            spaceBetween={9}
            {...swiperProps}
          >
            {items?.map((item, index: number) => {
              return (
                <SwiperSlide
                  key={index}
                  onClick={item.onClick}
                  style={{ height: 'var(--chakra-space-16)' }}
                >
                  <TwentyFourHoursRankItem {...item} variant={variant} />
                </SwiperSlide>
              )
            })}
          </Swiper>
        )}
      </VStack>
    </>
  )
}

function TwentyFourHoursRankItem(
  props: TwentyFourHoursRankItemProps & { variant: V24HoursRankVariant },
) {
  const { t } = useTranslation()
  const { gameCover, gameName, amountFormated, variant } = props

  const variants = {
    'three-column': {
      item: {
        h: '16',
        p: '2',
        borderRadius: 'md',
        justifyContent: 'flex-start',
        bg: 'bg.control',
      },
      gameName: {
        textStyle: 'h6',
        color: 'prim.600',
        textTransform: 'uppercase',
      },
      amount: {
        textStyle: 'h6',
        mb: '1',
      },
    },
    'backdrop-image': {
      item: {
        mb: '1',
        h: '16',
        pl: '5',
        borderRadius: 'md',
        alignItems: 'center',
        pr: '34%',
        textStyle: 'h6',
      },
      gameName: {
        textStyle: 'h6',
        color: 'white',
        textTransform: 'uppercase',
        mb: '0.5',
      },
      amount: {
        color: 'white',
      },
    },
  }

  const styles = useCustomizedStyle(
    'V24HoursRank',
    variants[variant],
    ['item', 'gameName', 'amount'],
    { variant },
  )

  if (variant === 'backdrop-image') {
    return (
      <Flex
        sx={styles.item}
        bg={`no-repeat center / 100% 100% url(${gameCover})`}
      >
        <Box>
          <Text sx={styles.gameName}>{gameName}</Text>
          <Box sx={styles.amount}>
            <Text as='span'>{`${t('WALLET_BONUS')}:  `}</Text>
            <Text as='span'>{amountFormated}</Text>
          </Box>
        </Box>
      </Flex>
    )
  }

  return (
    <HStack sx={styles.item}>
      <Box flexShrink='0'>
        <LazyImage
          src={gameCover || fallbackIcon}
          alt=''
          boxSize='12'
          objectFit='cover'
          objectPosition='bottom'
          borderRadius='md'
        />
      </Box>
      <Box flex='1'>
        <Box sx={styles.amount}>
          <Text as='span' color='text.accent' textTransform='uppercase'>
            {`${t('WALLET_BONUS')}: `}
          </Text>
          <Text
            as='span'
            color='var(--amount-font-color, var(--chakra-colors-text-money))'
          >
            {amountFormated}
          </Text>
        </Box>
        <Text sx={styles.gameName}>{gameName}</Text>
      </Box>
      <Center
        flexShrink='0'
        bg='prim.500'
        boxSize='7'
        borderRadius='full'
        color={getColorModeValue('white', 'text.accent')}
      >
        <Icon as={SharedIcon.Game} boxSize='4' />
      </Center>
    </HStack>
  )
}
