import { useCallback } from 'react'
import { useRecoilValue } from 'recoil'

import {
  decimalFormatByCode,
  getSignByCode,
} from '@/common/locale/currency-const'

import { walletInfoAtom } from '../atoms/common-state'

interface UseFormatReturn {
  /**
   * 货币格式化
   * @param value 需要格式化的值
   * @param sign 货币符号
   * @returns 格式化后的值
   */
  addSign: (value: string | number, sign: string) => string

  /**
   * 获取 格式化的 pattern
   * @param sign 货币符号
   * @returns
   */
  getAddSignPattern: (sign: string) => string
}

export function useFormat(): UseFormatReturn {
  const addSign = useCallback((value: string | number, sign: string) => {
    return sign + ` ${value}`
  }, [])

  const getAddSignPattern = useCallback((sign: string) => `${sign} `, [])

  return { addSign, getAddSignPattern }
}

export function useSelectCurrency() {
  const { currency: platFormCurrency } = useRecoilValue(walletInfoAtom)
  const curCurrency = useCallback(
    (currency: string, value: number) => {
      const _currency = currency || platFormCurrency.code
      const _value = Number(value) || 0
      const upperCurrency = _currency?.toUpperCase() || ''
      const formatValue = decimalFormatByCode(upperCurrency, _value)
      const _sign = getSignByCode(upperCurrency)
      return {
        pay_amount: formatValue,
        sign: _sign,
      }
    },
    [platFormCurrency.code],
  )
  return { curCurrency }
}
