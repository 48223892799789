import { atom } from 'recoil'

export type PlinkColors = 'blue' | 'green' | 'red'
export type PlinkColorId = 0 | 1 | 2
export const PLINK_COLOR_MAP: Record<PlinkColors, PlinkColorId> = {
  blue: 0,
  green: 1,
  red: 2,
}

export const blueInputAtom = atom<number>({
  key: 'blue',
  default: 0,
})

export const greenInputAtom = atom<number>({
  key: 'green',
  default: 0,
})

export const redInputAtom = atom<number>({
  key: 'red',
  default: 0,
})

export const blueAutoAtom = atom<boolean>({
  key: 'blueAutoAtom',
  default: false,
})

export const greenAutoAtom = atom<boolean>({
  key: 'greenAutoAtom',
  default: false,
})

export const redAutoAtom = atom<boolean>({
  key: 'redAutoAtom',
  default: false,
})

export const isAutoStartedBlue = atom<boolean>({
  key: 'isAutoStartedBlue',
  default: false,
})

export const isAutoStartedGreen = atom<boolean>({
  key: 'isAutoStartedGreen',
  default: false,
})

export const isAutoStartedRed = atom<boolean>({
  key: 'isAutoStartedRed',
  default: false,
})
