import { GameListItem } from '@/modules/game/gameInterface'
import GameCardRender from '@/modules/game/logic/game-list/GameCardRender'
import { useMemo } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { VIEW_ALL_GAMES_IMG } from '../constants'
import AtomGameCard from '@/modules/game/units/gamecard/AtomGameCard'
import { Link } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { RecordSwiperProps } from '../GameRecordsAndCollection.type'
import type { SwiperProps } from 'swiper/react'
import { FadingBox } from '@/modules/game/logic/game-list/recommend-game-groups/FadingBox'

const RecordsSwiper = ({
  onSwiperInit,
  games,
  onProgress,
  detailPagePath,
}: RecordSwiperProps) => {
  const swiperProps: SwiperProps = useMemo(() => {
    return {
      style: {
        width: '100%',
        height: '100%',
      },
      grabCursor: true,
      observer: true,
      observeParents: true,
      freeMode: true,
      slidesPerView: 6,
      spaceBetween: 12,
      slidesPerGroup: 6,
      simulateTouch: false,
    }
  }, [])

  const renderLoadMoreCard = () => {
    return (
      <SwiperSlide>
        <Link as={RouterLink} to={detailPagePath} display='block'>
          <AtomGameCard src={VIEW_ALL_GAMES_IMG} orientation={'portrait'} />
        </Link>
      </SwiperSlide>
    )
  }

  return (
    <FadingBox>
      <Swiper {...swiperProps} onInit={onSwiperInit} onProgress={onProgress}>
        {(games as GameListItem[])?.map((game, index) => {
          return (
            <SwiperSlide key={`game_row_${index}`}>
              <GameCardRender info={game} orientation={'portrait'} />
            </SwiperSlide>
          )
        })}
        {renderLoadMoreCard()}
      </Swiper>
    </FadingBox>
  )
}

export default RecordsSwiper
