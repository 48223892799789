import type { WidgetState } from '@livechat/widget-react'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

import { getVariable } from '@/common/env'
import { setIntegratedFeatSwitch } from '@/common/featureSwiitch'
import { pageConfigApi } from '@/services/jsonApi'

import type { ActionSystemProps } from '../app/home-page-config-types'
import { ACTION_SYSTEM_TYPE, STATUS_TYPE } from '../app/home-page-config-types'
import { startAppListening } from '../app/listenerMiddleware'
import type { RootState } from '../app/store'
import SaleSmartly from '../livechat/SaleSmartly'
import { notificationApi } from '../notification/api'
import type { Message } from '../notification/types'
import { serviceListApi } from '../service-list/api'
import { featSwitchApi } from './appApi'

enum STATE_TYPE {
  OFF,
  ON,
}

/**
 * 会员领奖日
 */
interface VipDayConfigProps {
  day: string
  is_timing_close: STATE_TYPE
  is_timing_open: STATE_TYPE
  timing_open: number
  timing_close: number
  state: STATE_TYPE
  url: string
}

export enum DrawerOpenStatus {
  Opened = 0,
  Opening = 1,
  Closing = 2,
  Closed = 3,
}

export enum DrawerKey {
  Left = 'Left',
  Right = 'Right',
  Top = 'Top',
}

function handleSearchMostNearestTimestamp(days: number[]) {
  const receivedDays = days.sort((a, b) => a - b)
  const receivedDaysTimestamp = receivedDays.map(day => ({
    start: dayjs().date(day).startOf('day').valueOf(),
    end: dayjs().date(day).endOf('day').valueOf(),
  }))
  const now = dayjs().startOf('day').valueOf()
  const res = receivedDaysTimestamp.reduce(
    (arrv, curv) => {
      if (now === curv.start) {
        arrv.isCanReceived = true
      } else {
        const difference = curv.start - now
        if (
          difference > 0 &&
          (arrv.difference === 0 || difference < arrv.difference)
        ) {
          arrv.difference = difference
          arrv.startTimestamp = curv.start
        }
      }
      return arrv
    },
    {
      isCanReceived: false,
      difference: 0,
      startTimestamp: 0,
    },
  )

  if (!res.startTimestamp && !res.isCanReceived) {
    return {
      isCanReceived: false,
      startTimestamp: dayjs()
        .add(1, 'month')
        .date(receivedDays[0])
        .startOf('day')
        .valueOf(),
    }
  }

  return res
}
function handleVipDayConfig(data: VipDayConfigProps) {
  const now = dayjs().valueOf()
  const closeTime =
    data.is_timing_close === STATE_TYPE.ON
      ? dayjs(data.timing_close * 1000 || undefined).valueOf()
      : now
  const openTime =
    data.is_timing_open === STATE_TYPE.ON
      ? dayjs(data.timing_open * 1000 || undefined).valueOf()
      : now

  if (
    data.is_timing_close === STATE_TYPE.ON &&
    data.is_timing_open === STATE_TYPE.ON
  ) {
    if (closeTime >= openTime && now >= closeTime) {
      return undefined
    }

    if (openTime >= closeTime && now < openTime) {
      return undefined
    }
  }

  if (
    data.state === STATE_TYPE.OFF ||
    (data.is_timing_close === STATE_TYPE.ON && now >= closeTime) ||
    (data.is_timing_open === STATE_TYPE.ON && now < openTime)
  ) {
    return undefined
  }

  const days = data.day
    ?.split(',')
    ?.filter((filterItem: string) => {
      const transformRes = Number(filterItem)
      return (
        transformRes.toString() !== 'NaN' && typeof transformRes === 'number'
      )
    })
    .map(mapItem => Number(mapItem))

  if (!days || days?.length === 0) {
    return undefined
  }

  return {
    ...handleSearchMostNearestTimestamp(days),
    linkUrl: data.url,
  }
}

/**
 * 服务端返回的特性开关字段
 */
export type FeatSwitchProps<T> = {
  param: T
  key: string
  desc: string
  enable: 0 | 1
}

export type AppLayoutState = {
  drawersStatus: Record<DrawerKey, DrawerOpenStatus>
  liveChatWigetVisibility: WidgetState['visibility']
  liveChatLicense: string
  actionSystemConfig?: Record<'IOS' | 'ANDROID' | 'WEB', ActionSystemProps>
  messageList: Message[]
  loginAfterRedirect?: string
  serviceEntry: {
    rechargeModalServiceType: string
    floatButtonServiceType: string
    isSaleSmartlyEnable?: boolean
  }
  vipDayConfig?: {
    linkUrl: string
    isCanReceived: boolean
    difference?: number
    startTimestamp: number
  }
  featSwitch: Record<string, FeatSwitchProps<Record<string, any>>>
}

const initialState: AppLayoutState = {
  drawersStatus: {
    [DrawerKey.Top]: DrawerOpenStatus.Closed,
    [DrawerKey.Left]: DrawerOpenStatus.Closed,
    [DrawerKey.Right]: DrawerOpenStatus.Closed,
  },
  liveChatLicense: '',
  liveChatWigetVisibility: 'hidden',
  messageList: [],
  loginAfterRedirect: '',
  serviceEntry: {
    rechargeModalServiceType: '',
    floatButtonServiceType: '',
    isSaleSmartlyEnable: false,
  },
  // 特性开关集合
  featSwitch: {},
}

const DEVICE_TYPE_TO_STRING = {
  [ACTION_SYSTEM_TYPE.WEB]: 'WEB',
  [ACTION_SYSTEM_TYPE.IOS]: 'IOS',
  [ACTION_SYSTEM_TYPE.ANDROID]: 'ANDROID',
}

export const appLayoutSlice = createSlice({
  name: 'appLayout',
  initialState,
  reducers: {
    openDrawerStart(state, action: PayloadAction<DrawerKey>) {
      const drawersStatus = state.drawersStatus[action.payload]
      if (DrawerOpenStatus.Closed === drawersStatus) {
        state.drawersStatus[action.payload] = DrawerOpenStatus.Opening
      }
    },
    openDrawerFinsh(state, action: PayloadAction<DrawerKey>) {
      const drawersStatus = state.drawersStatus[action.payload]
      if (DrawerOpenStatus.Opening === drawersStatus) {
        state.drawersStatus[action.payload] = DrawerOpenStatus.Opened
      }
    },
    closeDrawerStart(state, action: PayloadAction<DrawerKey>) {
      const drawersStatus = state.drawersStatus[action.payload]
      if (DrawerOpenStatus.Opened === drawersStatus) {
        state.drawersStatus[action.payload] = DrawerOpenStatus.Closing
      }
    },
    closeDrawerFinsh(state, action: PayloadAction<DrawerKey>) {
      const drawersStatus = state.drawersStatus[action.payload]
      if (DrawerOpenStatus.Closing === drawersStatus) {
        state.drawersStatus[action.payload] = DrawerOpenStatus.Closed
      }
    },
    toggleDrawerStart(state, action: PayloadAction<DrawerKey>) {
      const drawerStatus = state.drawersStatus[action.payload]
      if (DrawerOpenStatus.Opened === drawerStatus) {
        state.drawersStatus[action.payload] = DrawerOpenStatus.Closing
      } else if (DrawerOpenStatus.Closed === drawerStatus) {
        state.drawersStatus[action.payload] = DrawerOpenStatus.Opening
      }
    },
    drawerTransitionEnd(state, action: PayloadAction<DrawerKey>) {
      const drawerStatus = state.drawersStatus[action.payload]
      if (DrawerOpenStatus.Opening === drawerStatus) {
        state.drawersStatus[action.payload] = DrawerOpenStatus.Opened
      } else if (DrawerOpenStatus.Closing === drawerStatus) {
        state.drawersStatus[action.payload] = DrawerOpenStatus.Closed
      }
    },
    gridTransitionEnd(state) {
      const isInTransition = (status: DrawerOpenStatus) =>
        [DrawerOpenStatus.Opening, DrawerOpenStatus.Closing].includes(status)

      const { drawersStatus } = state
      const isLeftDrawerInTransition = isInTransition(drawersStatus.Left)
      const isRightDrawerInTransition = isInTransition(drawersStatus.Right)
      if (isLeftDrawerInTransition || isRightDrawerInTransition) {
        const key = isLeftDrawerInTransition ? DrawerKey.Left : DrawerKey.Right
        const status = state.drawersStatus[key]
        if (DrawerOpenStatus.Opening === status) {
          state.drawersStatus[key] = DrawerOpenStatus.Opened
        } else if (DrawerOpenStatus.Closing === status) {
          state.drawersStatus[key] = DrawerOpenStatus.Closed
        }
      }
    },
    setLiveChatWigetVisibility(
      state,
      action: PayloadAction<WidgetState['visibility']>,
    ) {
      state.liveChatWigetVisibility = action.payload
    },
    toggleLiveChatWigetVisibility(
      state,
      action: PayloadAction<string | undefined>,
    ) {
      if (state.serviceEntry.isSaleSmartlyEnable) {
        return state
      }
      if (state.liveChatWigetVisibility === 'hidden') {
        state.liveChatWigetVisibility = 'maximized'
        if (action.payload) {
          state.liveChatLicense = action.payload
        }
      } else {
        state.liveChatWigetVisibility = 'hidden'
      }
    },
    setLoginAfterRedirect(state, action: PayloadAction<string>) {
      state.loginAfterRedirect = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        pageConfigApi.endpoints.getHomePageConfig.matchFulfilled,
        (
          state,
          action: PayloadAction<
            any & Record<'vip_day_config', VipDayConfigProps>
          >,
        ) => {
          const data = action.payload || {}
          const { action_system_config } = data
          const actionSystemConfig = action_system_config?.reduce(
            (
              arr: Record<'IOS' | 'ANDROID' | 'WEB', ActionSystemProps>,
              cur: ActionSystemProps,
            ) => {
              if (cur.status === STATUS_TYPE.ON) {
                arr[
                  DEVICE_TYPE_TO_STRING[cur.type] as 'IOS' | 'ANDROID' | 'WEB'
                ] = cur
              }
              return arr
            },
            {},
          )

          state.vipDayConfig = data?.vip_day_config
            ? handleVipDayConfig(data.vip_day_config)
            : undefined

          state.actionSystemConfig = actionSystemConfig
        },
      )
      .addMatcher(
        notificationApi.endpoints.getMessages.matchFulfilled,
        (state, action: PayloadAction<any>) => {
          state.messageList =
            action.payload?.data?.sort(
              (a: any, b: any) => b.created_at - a.created_at,
            ) || []
        },
      )
      .addMatcher(
        serviceListApi.endpoints.serviceEntryConfig.matchFulfilled,
        (state, action) => {
          const { recharge_name, front_page_name } = action.payload?.data
            ?.list[0] ?? {
            recharge_name: '',
            front_page_name: '',
          }
          state.serviceEntry.floatButtonServiceType = front_page_name
          state.serviceEntry.rechargeModalServiceType = recharge_name
        },
      )
      .addMatcher(
        serviceListApi.endpoints.getIsSaleSmartlyEnable.matchFulfilled,
        (state, action) => {
          const { enable } = action.payload.data
          state.serviceEntry.isSaleSmartlyEnable = enable === 1
        },
      )
      // 旧版的特性开关列表
      .addMatcher(
        featSwitchApi.endpoints.getFeatSwitchList.matchFulfilled,
        (state, action) => {
          const data = action.payload?.data || []
          const featSwitchObj = {}
          try {
            for (let i = 0; i < data?.length; i++) {
              const featItem = data[i]
              if (featItem.key) {
                const param = JSON.parse(featItem?.param || '{}')
                Object.assign(featSwitchObj, {
                  [featItem.key]: {
                    ...(data[i] || {}),
                    param,
                  },
                })
              }
            }
          } catch (e) {
            //
          }
          state.featSwitch = featSwitchObj
        },
      )
      // 新版的特性开关列表
      .addMatcher(
        featSwitchApi.endpoints.getFeatSwitchListV2.matchFulfilled,
        (state, action) => {
          const data = action.payload?.data || {}
          setIntegratedFeatSwitch(data)
        },
      )
  },
})

export const selectAppLayout = (state: RootState) => state.appLayout

export const selectActionSystemConfig = (state: RootState) =>
  selectAppLayout(state).actionSystemConfig

export const createDrawerStatusSelector =
  (key: DrawerKey) => (state: RootState) =>
    state.appLayout.drawersStatus[key]

export const createDrawerOpenSelector =
  (key: DrawerKey) => (state: RootState) =>
    [DrawerOpenStatus.Opened, DrawerOpenStatus.Opening].includes(
      createDrawerStatusSelector(key)(state),
    )

export const createDrawerTransitioningSelector =
  (key: DrawerKey) => (state: RootState) =>
    [DrawerOpenStatus.Opening, DrawerOpenStatus.Closing].includes(
      createDrawerStatusSelector(key)(state),
    )

export const selectMessageList = createSelector(
  selectAppLayout,
  appLayout => appLayout.messageList,
)

export const selectVipDayConfig = createSelector(
  selectAppLayout,
  appLayout => appLayout.vipDayConfig,
)

/** feat switch obj */
export const selectFeatSwitch = createSelector(
  selectAppLayout,
  appLayout => appLayout.featSwitch,
)

/** feat switch for special Key */
export const selectFeatSwitchWithKey = (key: string) => (state: RootState) =>
  state.appLayout.featSwitch[key]

/**
 * 获取登录后跳转页面
 */
export const setelctLoginAfterRedirect = createSelector(
  selectAppLayout,
  appLayout => appLayout.loginAfterRedirect,
)
export const selectServiceEntryConfig = createSelector(
  selectAppLayout,
  appLayout => appLayout.serviceEntry,
)

export const selectIsSaleSmartlyEnable = createSelector(
  selectServiceEntryConfig,
  serviceEntryConfig => serviceEntryConfig.isSaleSmartlyEnable,
)

const LIVE_CHAT_LICENSE = getVariable('NEXT_PUBLIC_LIVE_CHAT_LICENSE')
export const selectLiveChatWidgetLicense = createSelector(
  selectAppLayout,
  ({ liveChatLicense }) => liveChatLicense || LIVE_CHAT_LICENSE,
)

// 首页通知列表
export const notificationList = createSelector(selectMessageList, messageList =>
  messageList.filter(item => !item.is_read).slice(0, 5),
)

export const {
  openDrawerStart,
  openDrawerFinsh,
  closeDrawerStart,
  closeDrawerFinsh,
  toggleDrawerStart,
  drawerTransitionEnd,
  gridTransitionEnd,
  setLiveChatWigetVisibility,
  toggleLiveChatWigetVisibility,
  setLoginAfterRedirect,
} = appLayoutSlice.actions

startAppListening({
  actionCreator: toggleLiveChatWigetVisibility,
  effect: (_, store) => {
    const _user = store?.getState()?.user || {}
    const userInfo = {
      user_id: _user?.userId || '',
      user_name: _user?.user?.nick_name || '',
    }
    if (selectIsSaleSmartlyEnable(store.getState())) {
      SaleSmartly.getInstance().toggleChat(userInfo)
    }
  },
})

export const appLayoutReducer = appLayoutSlice.reducer
