import { pick } from 'lodash-es'

import store from '@/modules/app/store'
import { selectUserId } from '@/modules/user/userSlice'

import { EventName, EventNameUtils } from '../const'
import type { Channel, EventMap, ITrackerBase } from './types'

export class TrackerShuShu implements ITrackerBase {
  channel: Channel = { name: 'shushu', id: '' }

  eventMap: EventMap = {
    [EventName.CompletePurchase]: ['first_recharge'],
    [EventName.CompleteRegistration]: ['register'],
  }

  channels: {
    channel: string
    channel_id: string
    extras?: Record<string, string>
  }[] = []

  constructor(channels: ITrackerBase[]) {
    this.channels = channels.map(({ channel }) => ({
      channel: channel.name,
      channel_id: channel.id,
    }))
    if (window.ta) {
      const userId = selectUserId(store.getState())
      if (userId) window.ta.login(userId)
    }
  }

  trackSingleEvent(event: string, eventName: EventName, data?: any) {
    if (!window.ta) return
    let propNames: string[] = []
    if (EventNameUtils.isFirstRecharge(eventName)) {
      propNames = ['hig_one', 'device_type', 'value', 'currency']
    } else if (EventNameUtils.isLoginOrRegister(eventName)) {
      propNames = ['hig_one', 'device_type', 'account_type', 'from']
    }
    window.ta.track('track_report', {
      report_event: event,
      report_channels: this.channels,
      ...(pick(data, propNames) ?? {}),
    })
  }
}
