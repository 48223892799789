export class FixedSizeArray<T> extends Array<T> {
  private _maxLength: number

  constructor(maxLength: number, initialValues?: T[]) {
    super()
    this._maxLength = maxLength

    if (initialValues) {
      initialValues.slice(0, maxLength).forEach(item => this.push(item))
    }
  }

  push(...items: T[]): number {
    for (const item of items) {
      if (this.length >= this._maxLength) {
        this.shift()
      }
      super.push(item)
    }
    return this.length
  }

  unshift(...items: T[]): number {
    for (const item of items) {
      if (this.length >= this._maxLength) {
        this.pop()
      }
      super.unshift(item)
    }

    return this.length
  }
}

export default FixedSizeArray
