import { atom } from 'recoil'

export const onlineTableAtom = atom<Array<unknown>>({
  key: 'onlineTableAtom',
  default: [],
})

export const mybetsTableAtom = atom<Array<unknown>>({
  key: 'mybetsTableAtom',
  default: [],
})

export const allbetsTableAtom = atom<Array<unknown>>({
  key: 'allbetsTableAtom',
  default: [],
})
