import { useCallback, useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { getMax as _getMax } from '../../common/utils'
import { balanceInfoAtom, gameInfoAtom } from '../atoms/common-state'

type GetMax = (autoMax?: number) => number
type GetMin = () => number

export function useFinalMaxMin() {
  const balance = useRecoilValue(balanceInfoAtom)
  const { config } = useRecoilValue(gameInfoAtom)

  const getMax: GetMax = useCallback(
    (autoMax?: number) => {
      const value = _getMax(balance, config.maxBetAmount, autoMax)
      return value < config.minBetAmount ? config.minBetAmount : value
    },
    [balance, config.maxBetAmount, config.minBetAmount],
  )

  const getMin: GetMin = useCallback(
    () => config.minBetAmount,
    [config.minBetAmount],
  )

  return useMemo(
    () => ({
      getMax,
      getMin,
    }),
    [getMax, getMin],
  )
}
