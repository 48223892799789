import { Center } from '@chakra-ui/react'

import AtomPageLoading from '@/components/atom-page-loading/AtomPageLoading'

export default function Loading() {
  return (
    <Center w='full' h='full' position='absolute'>
      <AtomPageLoading />
    </Center>
  )
}
