import { Button, Icon, Text, useToast, VStack } from '@chakra-ui/react'
import { useGoogleLogin } from '@react-oauth/google'
import { memo } from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { useTranslation } from 'react-i18next'
import { FaFacebook } from 'react-icons/fa'
import { FcGoogle } from 'react-icons/fc'

import { getVariable } from '@/common/env'
import { isFeatureEnabled } from '@/common/feature'
import {
  hideGlobalLoading,
  showGlobalLoading,
} from '@/components/global-loading/global-loading-slice'
import { isWithMessageObject } from '@/services/types'
import { getErrorMessage } from '@/utils/error'
import { useUserAuth } from '@/utils/hooks'
import { getFeatures, isFromApk, isFromVest } from '@/utils/tools'

import { useAppDispatch } from '../app/store'
import {
  Native3rdPartyLoginChannel,
  parseNative3rdSignResult,
} from '../login/GoogleLoginButton'
import { AccountType, useSetThirdBindMutation } from '../new-login/loginApi'
import {
  getGoogleUserInfo,
  useBindFacebookAccoutMutation,
  useBindGoogleAccoutMutation,
} from './userApi'

const isNewThirdPartLoginEnable = isFeatureEnabled('newThirdPartLogin')
const apply_fb_login = isFeatureEnabled('apply_fb_login')
const hideGoogleLogin = isFeatureEnabled('hideGoogleLogin')

const hide_third_login = isFeatureEnabled('hide_third_login')
const loginAndSignUpV3 = getFeatures('loginAndSignUpV3')

function _TripartitelBind(props: {
  isBindFacebook?: boolean
  isBindGoolge?: boolean
}) {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const toast = useToast()
  const [userId, token] = useUserAuth()

  /**
   * 新login服务绑定接口
   */
  const [bindThirdAccount] = useSetThirdBindMutation()

  const [bindGoogle] = useBindGoogleAccoutMutation()
  const [bindFacebook] = useBindFacebookAccoutMutation()
  const { isBindFacebook, isBindGoolge } = props

  // 绑定facebkook账号
  const facebookBind = async (response: any) => {
    const { accessToken } = response
    if (!accessToken) {
      return
    }
    try {
      if (loginAndSignUpV3) {
        await bindThirdAccount({
          account_type: AccountType.AT_FACEBOOK,
          account_value: accessToken,
        }).unwrap()
      } else {
        await bindFacebook({
          authorization_code: accessToken,
          token: token,
          user_id: userId,
        }).unwrap()
      }
      toast({ title: t('BINDING_SUCCEEDED'), status: 'success' })
      dispatch(hideGlobalLoading())
    } catch (error: unknown) {
      toast({
        title: isWithMessageObject(error) ? error.message : t('BIND_FAILED'),
        status: 'error',
      })
      dispatch(hideGlobalLoading())
    }
  }

  const getGoogleBindParams = async (accessToken: string) => {
    const result: Parameters<typeof bindGoogle>[0] = {
      token: token,
      user_id: userId,
    }
    if (isNewThirdPartLoginEnable) {
      result.authorization_code = accessToken
      result.redirect_uri = window.location.origin || ''
    } else {
      result.user_info = await getGoogleUserInfo(accessToken)
    }
    return result
  }

  // 授权
  const afterAuthSuccess = async (accessToken: string) => {
    try {
      const params = await getGoogleBindParams(accessToken)
      if (loginAndSignUpV3) {
        await bindThirdAccount({
          account_type: AccountType.AT_GOOGLE,
          account_value: params.authorization_code as string,
        }).unwrap()
      } else {
        await bindGoogle(params).unwrap()
      }
      toast({ title: t('BINDING_SUCCEEDED'), status: 'success' })
    } catch (error: unknown) {
      toast({ title: getErrorMessage(error, 'BIND_FAILED'), status: 'error' })
    } finally {
      dispatch(hideGlobalLoading())
    }
  }

  /**
   * 谷歌H5绑定
   */
  const googleBind = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (tokenResponse: any) => {
      const { code: accessToken } = tokenResponse
      afterAuthSuccess(accessToken)
    },
    onError: () => {
      dispatch(hideGlobalLoading())
    },
    onNonOAuthError: () => {
      dispatch(hideGlobalLoading())
    },
  })

  /**
   * 谷歌原生绑定
   */
  const googleBindNative = () => {
    window.on3rdSignResult = (result: string) => {
      const parsedResult = parseNative3rdSignResult(result)
      if (!parsedResult) return
      if (parsedResult.channel !== Native3rdPartyLoginChannel.Google) return
      const { isSigned, signResult } = parsedResult
      if (isSigned && signResult) {
        afterAuthSuccess(signResult.serverAuthCode)
      } else {
        dispatch(hideGlobalLoading())
      }
    }
    const clientId = getVariable('NEXT_PUBLIC_GOOGLE_CLICENT_ID') ?? ''
    window.AndroidWebView?.do3rdSignIn(
      Native3rdPartyLoginChannel.Google,
      JSON.stringify({ serverClientId: clientId }),
    )
  }

  const handleGoogleBindBtnClick = () => {
    dispatch(showGlobalLoading())
    if (isFromApk()) {
      googleBindNative()
    } else {
      googleBind()
    }
  }

  /**
   * 原生FB绑定
   */
  const on3rdSignResult = (result: string) => {
    const parsedResult = parseNative3rdSignResult(result)
    if (!parsedResult) return
    if (parsedResult.channel !== Native3rdPartyLoginChannel.Facebook) return
    const { isSigned, signResult } = parsedResult
    if (isSigned && signResult) {
      facebookBind({ accessToken: signResult.serverAuthCode })
    } else {
      dispatch(hideGlobalLoading())
    }
  }

  const fbLoginByNative = () => {
    if (!window) return
    window.on3rdSignResult = on3rdSignResult
    window.AndroidWebView?.do3rdSignIn(
      Native3rdPartyLoginChannel.Facebook,
      JSON.stringify({ '': '' }),
    )
  }

  const handleFacebookBindBtnClick = (renderProps: any) => {
    dispatch(showGlobalLoading())
    if (isFromApk()) {
      fbLoginByNative()
    } else {
      renderProps?.onClick()
    }
  }

  if (isFromVest() || hide_third_login) {
    return null
  }

  return (
    <VStack alignItems='stretch' spacing='2' w='full'>
      {(() => {
        if (isBindFacebook && apply_fb_login) {
          if (!isFromApk()) {
            return (
              <FacebookLogin
                appId={getVariable('NEXT_PUBLIC_FACEBOOK_APP_ID') ?? ''}
                autoLoad={false}
                xfbml={true}
                cookie={true}
                version='2.8'
                fields='name,email,picture'
                render={(renderProps: any) => {
                  return (
                    <Button
                      leftIcon={
                        <Icon
                          as={FaFacebook}
                          color='facebook'
                          boxSize='5'
                          mr='1'
                        />
                      }
                      width='full'
                      colorScheme='white'
                      justifyContent='flex-start'
                      onClick={renderProps.onClick}
                    >
                      <Text textStyle='text5' cursor='pointer'>
                        {t('PH_FACEBOOK_TEXT')}
                      </Text>
                    </Button>
                  )
                }}
                callback={facebookBind}
              />
            )
          } else {
            return (
              <Button
                leftIcon={
                  <Icon as={FaFacebook} color='facebook' boxSize='5' mr='1' />
                }
                width='full'
                colorScheme='white'
                justifyContent='flex-start'
                onClick={handleFacebookBindBtnClick}
              >
                <Text textStyle='text5' cursor='pointer'>
                  {t('PH_FACEBOOK_TEXT')}
                </Text>
              </Button>
            )
          }
        }
      })()}
      {isBindGoolge && !hideGoogleLogin && (
        <Button
          leftIcon={<Icon as={FcGoogle} boxSize='5' mr='1' />}
          width='full'
          colorScheme='white'
          justifyContent='flex-start'
          onClick={handleGoogleBindBtnClick}
        >
          <Text textStyle='text5'>{t('PH_GOOGLE_TEXT')}</Text>
        </Button>
      )}
    </VStack>
  )
}
export const TripartitelBind = memo(_TripartitelBind)
