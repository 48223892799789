import type { BoxProps } from '@chakra-ui/react'
import {
  Box,
  Checkbox,
  Flex,
  HStack,
  Icon,
  IconButton,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useEffect, useMemo, useRef, useState } from 'react'

// import { isFeatureEnabledV2 } from '@/common/featureSwiitch'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { localStorage } from '@/utils/localStorage'

import { useAppSelector } from '../app/store'
import { selectFeatSwitchWithKey } from '@/modules/app-layout/app-layout-slice'

const TIPS_MODAL_CONTENT: Record<
  string,
  {
    modalTitle: string
    title: string
    content: string[]
  }
> = {
  GCASHORIGIN: {
    modalTitle: 'GCASH_HINT_TITLE',
    title: 'GCASH_HINT_TITLE1',
    content: ['GCASH_HINT_TEXT1', 'GCASH_HINT_TEXT2'],
  },
}

let TIMER: NodeJS.Timer | undefined = undefined

export default function GcashTipsModal({
  rechargeTyp,
}: {
  rechargeTyp: string
}) {
  const gcashTipsSwitchStatus = useAppSelector(
    selectFeatSwitchWithKey('gcash_tips_opt'),
  )?.enable

  const [showTimes, setShowTimes] = useState(0)
  const [showCountdown, setShowCountdown] = useState(false)
  const [count, setCount] = useState(3)
  const [noRemind, setNoRemind] = useState(() => {
    const key = 'GcashTipsState'
    const storageItem = localStorage.getItem(key)
    if (!storageItem) return false
    const storage = JSON.parse(storageItem) || {}
    const today = dayjs().format('YYYY-MM-DD')
    if (storage[today]?.[rechargeTyp]) {
      return true
    }
    return false
  })

  const targetRef = useRef<HTMLParagraphElement>(null)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleCountdown = () => {
    clearInterval(TIMER)
    TIMER = setInterval(() => {
      setCount(val => {
        if (val > 0) {
          return (val -= 1)
        } else {
          onClose()
          clearInterval(TIMER)
          TIMER = undefined
          setShowCountdown(false)
          return val
        }
      })
    }, 1000)
  }

  const onChangeCheckbox = (checked: boolean) => {
    const today = dayjs().format('YYYY-MM-DD')
    localStorage.setItem(
      'GcashTipsState',
      JSON.stringify({
        [today]: {
          [rechargeTyp]: checked,
        },
      }),
    )
    setNoRemind(checked)
  }

  const modalWrapperStyle: BoxProps = useMemo(() => {
    const boundingClientRect = targetRef?.current?.getBoundingClientRect()
    if (isOpen) {
      return {
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        top: '0',
        left: '0',
        opacity: 1,
      }
    }

    return {
      position: 'fixed',
      left: boundingClientRect ? boundingClientRect.width : 0,
      top: boundingClientRect ? -boundingClientRect.height : 0,
      width: '100vw',
      height: '100vh',
      transform: 'scale(0)',
      opacity: 0,
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    if (TIPS_MODAL_CONTENT?.[rechargeTyp]) {
      setShowTimes(val => (val += 1))
    }
  }, [rechargeTyp])

  useEffect(() => {
    let isNoRemind = false
    try {
      const key = 'GcashTipsState'
      const storageItem = localStorage.getItem(key) || '{}'
      const storage = JSON.parse(storageItem)
      const today = dayjs().format('YYYY-MM-DD')
      isNoRemind = storage[today]?.[rechargeTyp]
    } catch (error) {
      //
    }

    if (showTimes === 0 && !isNoRemind && TIPS_MODAL_CONTENT?.[rechargeTyp]) {
      setShowCountdown(true)
      onOpen()
      handleCountdown()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTimes, rechargeTyp])

  if (
    !TIPS_MODAL_CONTENT?.[rechargeTyp] ||
    !gcashTipsSwitchStatus
    // !isFeatureEnabledV2('gcash_tips_opt')
  ) {
    return null
  }

  return (
    <Box position='relative'>
      <Flex justifyContent='flex-end' alignItems='center'>
        <Icon as={SharedIcon.Question} color='link' boxSize='20px' mr='2px' />
        <Text
          color='link'
          textDecoration='underline'
          fontWeight='600'
          fontSize='12px'
          lineHeight='16px'
          cursor='pointer'
          ref={targetRef}
          onClick={onOpen}
        >
          {t('GCASH_HINT_ENTRANCE')}
        </Text>
      </Flex>

      {isOpen && <ModalOverlay pointerEvents='none' />}
      <Box
        {...modalWrapperStyle}
        transition='all .5s'
        zIndex={9999999}
        onClick={onClose}
        overflow='hidden'
      >
        <Box
          position='absolute'
          top='50%'
          left='50%'
          transform='translate(-50%, -50%)'
          bg='#000000'
          zIndex={999999}
          borderRadius='16px'
          px='20px'
          py='18px'
          onClick={e => e.stopPropagation()}
          w='full'
          maxWidth='356px'
        >
          <IconButton
            // as={ModalCloseButton}
            icon={<Icon as={SharedIcon.Close} boxSize={7} color='text.base' />}
            aria-label='close'
            variant='unstyled'
            size='sm'
            display='flex'
            alignItems='center'
            justifyContent='center'
            position='absolute'
            top='18px'
            right='16px'
            onClick={onClose}
          />
          <Text
            textStyle='h4'
            color='gray.900'
            textAlign='center'
            mb='16px'
            mt='10px'
          >
            {t(TIPS_MODAL_CONTENT[rechargeTyp]?.modalTitle)}
          </Text>
          <Text textStyle='h5' color='gray.900'>
            {t(TIPS_MODAL_CONTENT[rechargeTyp]?.title)}
          </Text>
          {TIPS_MODAL_CONTENT[rechargeTyp]?.content?.map((item, index) => (
            <Text
              key={`${rechargeTyp}_content_${index}`}
              mt='10px'
              textStyle='text4'
              color='gray.800'
              dangerouslySetInnerHTML={{
                __html: t(item) || '',
              }}
            />
          ))}

          <HStack
            sx={{
              alignItems: 'center',
              color: 'white',
            }}
            w='100%'
            mt='16px'
          >
            <Checkbox
              isChecked={noRemind}
              variant='actModal'
              onChange={e => onChangeCheckbox(e.target.checked)}
            >
              {t('GCASH_HINT_OPTION')}
            </Checkbox>
          </HStack>

          {showCountdown && (
            <Text
              textStyle='text5'
              color='gray.800'
              textAlign='center'
              mt='10px'
            >
              {t('GCASH_HINT_TIME', { time: count })}
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  )
}
