import axios from 'axios'

import { getVariable } from '@/common/env'
import { isFeatureEnabled } from '@/common/feature'
import { isFeatureEnabledV2 } from '@/common/featureSwiitch'
import { api } from '@/services/api'
import type { Response } from '@/services/types'

import { type GoogleUserInfo, RegisterFrom } from '../user/types'

export enum HostType {
  Google = 3,
  Facebook = 4,
}

type TargetHostsMap = Record<HostType, string>

type RecaptchaSwitcher = 0 | 1 | 2 | boolean // 0 关闭 1 隐式验证 2 显式验证

export type AuthConfig = {
  login: {
    /** 是否开启手机号+密码登录 */
    enable_phone_password: boolean
    /** 是否开启手机号+验证码登录 */
    enable_phone_verify_code: boolean
    /** 是否开启邮箱+密码登录 */
    enable_email_password: boolean
    enable_recaptcha_visible: RecaptchaSwitcher
  }
  register: {
    /** 是否开启手机号注册 */
    enable_phone_password: boolean
    /** 是否开启手机号+验证码注册 */
    enable_phone_verify_code: boolean
    /** 是否开启邮箱+密码注册 */
    enable_email_password: boolean
    /** 是否开启邮箱+验证码注册 */
    enable_email_verify_code: boolean
    /** 是否开启邮箱+手机号+验证码注册 */
    enable_email_phone_verify: boolean
    /** 是否开启邮箱+验证码+手机号+验证码注册 */
    enable_email_verify_phone_verify: boolean
    /** 是否开启谷歌 "我不是机器人" 验证 */
    enable_recaptcha_visible: RecaptchaSwitcher
  }
  third_login: {
    enable_google: boolean
    enable_facebook: boolean
    target_hosts: TargetHostsMap | null
  }
}

export enum UnloginVerifyCodeType {
  /** 登录短信验证 */
  vctLoginPhone = 1,
  /** 登录邮箱验证 */
  vctLoginEmail = 2,
  /** 注册短信验证 */
  vctRegisterPhone = 3,
  /** 注册邮箱验证 第一步 */
  vctRegisterEmail = 4,
  /** 重置密码短信验证 */
  vctPasswordResetPhone = 5,
  /** 重置密码邮箱验证 */
  vctPasswordResetEmail = 6,
  /** 注册邮箱+手机号验证 第二步 */
  vctRegisterEmailPhoneVerify = 7,
}

export enum LoginVerifyCodeType {
  /** 更新手机号，验证旧手机 */
  uvctPhoneOld = 1,
  /** 更新手机号，验证新手机 */
  uvctPhoneNew = 2,
  /** 更新邮箱，验证旧邮箱 */
  uvctEmailOld = 3,
  /**  更新邮箱，验证新邮箱 */
  uvctEmailNew = 4,
  /** 验证手机号 */
  uvctVerifyPhone = 5,
  /** 验证邮箱 */
  uvctVerifyEmail = 6,
  /** 绑定手机号 */
  uvctBindingPhone = 7,
  /** 绑定邮箱 */
  uvctBindingEmail = 8,
  /** 更新密码手机验证 */
  uvctUpdatePasswordPhone = 9,
  /** 更新密码邮箱验证 */
  uvctUpdatePasswordEmail = 10,
}

export enum AccountType {
  AT_PHONE = 1,
  AT_EMAIL = 2,
  AT_GOOGLE = 3,
  AT_FACEBOOK = 4,
}

export interface LoginArgs {
  account_type: AccountType
  account_value: string
  password?: string
  flow_id?: string
  verify_code?: string
  extra?: Record<string, string>
  invite_code?: string
  from?: RegisterFrom | null
}

export interface AuthQueryArgs {
  token: string
  user_id: string
}
const areaCode = getVariable('PHONE_AREA_CODE')

/**
 * 处理data手机号
 */
const handlePhoneAccountValue = (
  data: any,
  verifiedType?: 'login' | 'unlogin',
) => {
  if (
    data?.account_type === AccountType.AT_PHONE ||
    (verifiedType === 'unlogin' &&
      [
        UnloginVerifyCodeType.vctLoginPhone,
        UnloginVerifyCodeType.vctPasswordResetPhone,
        UnloginVerifyCodeType.vctRegisterPhone,
        UnloginVerifyCodeType.vctRegisterEmailPhoneVerify,
        UnloginVerifyCodeType.vctLoginPhone,
      ]?.includes(data?.verify_type)) ||
    (verifiedType === 'login' &&
      [
        LoginVerifyCodeType.uvctBindingPhone,
        LoginVerifyCodeType.uvctPhoneNew,
        LoginVerifyCodeType.uvctPhoneOld,
        LoginVerifyCodeType.uvctUpdatePasswordPhone,
        LoginVerifyCodeType.uvctVerifyPhone,
      ]?.includes(data?.verify_type))
  ) {
    return {
      ...data,
      account_value: `${areaCode}|${data?.account_value}`,
    }
  }

  return data
}

// 获取谷歌账户的用户信息
export const getGoogleUserInfo = async (accessToken: string) => {
  const rawResponse = await axios.get<GoogleUserInfo>(
    'https://www.googleapis.com/oauth2/v2/userinfo',
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  )
  return rawResponse.data
}

export type LoginResponse = Response<{
  is_register: boolean
  token: string
  user_id: string
}>

export const getRegisterFrom = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const fromActivity = searchParams.get('fromActivity')
  /** 是否开启了转盘活动 */
  if (isFeatureEnabled('luckyWheel') && fromActivity === 'luckyWheel') {
    return RegisterFrom.ActivityRaffle
  }
  /**是否开启了抽奖活动 */
  if (isFeatureEnabledV2('draw_activity') && fromActivity === 'drawActivity') {
    return RegisterFrom.ActivityDraw
  }
  if (searchParams.get('fromAd')) {
    return RegisterFrom.AdvApk
  }
  return RegisterFrom.Empty
}

export const loginApi = api.injectEndpoints({
  endpoints: builder => ({
    /**
     * 登录
     */
    newLogin: builder.mutation<LoginResponse, LoginArgs>({
      query: data => {
        const from = getRegisterFrom()
        const actualData: Record<string, any> = {
          ...data,
          redirect_uri: location.origin,
          from,
        }
        actualData['extra'] = { ...actualData['extra'], from: from }
        return {
          url: '/gw/login/login',
          method: 'POST',
          data: handlePhoneAccountValue(actualData),
          grecaptchaTokenStr: 'login',
        }
      },
      invalidatesTags: ['Auth', 'Login'],
    }),
    /**
     * 注册
     */
    register: builder.mutation<LoginResponse, LoginArgs>({
      query: data => {
        const actualData = { ...data }
        if (!actualData.invite_code) delete actualData.invite_code
        const from = getRegisterFrom()
        actualData['extra'] = { ...actualData['extra'], from }
        return {
          url: '/gw/login/register',
          method: 'POST',
          data: handlePhoneAccountValue(actualData),
          grecaptchaTokenStr: 'register',
        }
      },
      invalidatesTags: ['Auth', 'Login'],
    }),
    /**
     * 修改密码
     */
    resetPasswordByPassword: builder.mutation<
      Response<unknown>,
      { new_password: string; old_password: string }
    >({
      query: data => ({
        url: '/gw/login/update_password',
        method: 'POST',
        data,
        grecaptchaTokenStr: 'reset_password',
      }),
    }),
    /**
     * 重置密码
     */
    resetPasswordByCode: builder.mutation<
      Response<unknown>,
      { password: string; flow_id: string }
    >({
      query: data => ({
        url: '/gw/login/reset_password',
        method: 'POST',
        data,
        grecaptchaTokenStr: 'reset_password',
      }),
    }),
    /**
     * 未登录获取验证码
     */
    sendCodeUnlogin: builder.mutation<
      Response<unknown>,
      {
        verify_type: UnloginVerifyCodeType
        account_value: string
        flow_id?: string
        from?: 'profile' | 'withdraw'
      }
    >({
      query: data => ({
        url: '/gw/login/send_code',
        method: 'POST',
        data: handlePhoneAccountValue(data, 'unlogin'),
        grecaptchaTokenStr: 'send_code',
      }),
    }),
    /**
     * 未登录校验验证码
     */
    verifyCodeUnlogin: builder.mutation<
      Response<unknown>,
      {
        verify_type: UnloginVerifyCodeType
        verify_code: string
        flow_id: string
      }
    >({
      query: data => ({
        url: '/gw/login/verify_code',
        method: 'POST',
        data: handlePhoneAccountValue(data, 'unlogin'),
        grecaptchaTokenStr: 'verify_code',
      }),
    }),
    /**
     * 登录获取验证码
     */
    sendCodeLogin: builder.mutation<
      Response<unknown>,
      {
        flow_id?: string
        verify_type: LoginVerifyCodeType
        account_value: string
        from?: 'profile' | 'withdraw'
      }
    >({
      query: data => ({
        url: '/gw/login/send_update_code',
        method: 'POST',
        data: handlePhoneAccountValue(data, 'login'),
        grecaptchaTokenStr: 'send_update_code',
      }),
    }),
    /**
     * 登录校验验证码
     */
    verifyCodeLogin: builder.mutation<
      Response<{ re_login: boolean }>,
      {
        verify_type: LoginVerifyCodeType
        verify_code: string
        flow_id: string
      }
    >({
      query: data => ({
        url: '/gw/login/verify_update_code',
        method: 'POST',
        data: handlePhoneAccountValue(data, 'login'),
        grecaptchaTokenStr: 'verify_update_code',
      }),
      invalidatesTags: ['NewBindInfo'],
    }),
    /**
     * 获取三方授权链接
     */
    getThirdAuthUrl: builder.mutation<
      Response<unknown>,
      {
        /**
         * 3: google
         * 4: facebook
         */
        account_type: 3 | 4
      }
    >({
      query: data => ({
        url: '/gw/login/get_auth_url',
        method: 'POST',
        data,
        grecaptchaTokenStr: 'get_auth_url',
      }),
    }),
    /**
     * 绑定邮箱&手机号
     */
    setAccount: builder.mutation<
      Response<{ re_login: boolean }>,
      {
        verify_type?: LoginVerifyCodeType
        flow_id: string
        verify_code: string
      }
    >({
      query: data => ({
        url: '/gw/login/set_account',
        method: 'POST',
        data: handlePhoneAccountValue(data, 'login'),
        grecaptchaTokenStr: 'set_account',
      }),
      invalidatesTags: ['NewBindInfo'],
    }),
    /**
     * 三方绑定
     */
    setThirdBind: builder.mutation<
      Response<unknown>,
      {
        /**
         * 3: google
         * 4: facebook
         */
        account_type: 3 | 4
        account_value: string
      }
    >({
      query: data => ({
        url: '/gw/login/binding',
        method: 'POST',
        data: {
          ...data,
          redirect_uri: location.origin,
        },
        grecaptchaTokenStr: 'binding',
      }),
      invalidatesTags: ['NewBindInfo'],
    }),
    /**
     * 获取账号信息
     */
    getAccountsInfo: builder.query<
      Response<{
        account_tokens: {
          account_type: AccountType
          account_value: string
          verified: boolean
        }[]
      }>,
      void
    >({
      query: data => ({
        url: '/gw/login/get_accounts',
        method: 'POST',
        data,
        grecaptchaTokenStr: 'get_accounts',
      }),
      providesTags: ['NewBindInfo', 'UserInfo', 'Auth'],
    }),
    /**
     * 获取登录注册配置
     */
    getLoginAndSignUpConfigV2: builder.query<Response<AuthConfig>, void>({
      query: data => {
        return {
          url: `/gw/login/get_auth_config`,
          method: 'POST',
          data,
        }
      },
    }),
  }),
})

export const {
  useNewLoginMutation,
  useRegisterMutation,
  useResetPasswordByPasswordMutation,
  useGetThirdAuthUrlMutation,
  useResetPasswordByCodeMutation,
  useSendCodeLoginMutation,
  useSendCodeUnloginMutation,
  useVerifyCodeLoginMutation,
  useVerifyCodeUnloginMutation,
  useSetAccountMutation,
  useSetThirdBindMutation,
  useGetAccountsInfoQuery,
  useGetLoginAndSignUpConfigV2Query,
} = loginApi
