import { lazy } from 'react'
import type { RouteObject } from 'react-router-dom'

import { isFeatureEnabled } from '@/common/feature'
import { AuthRoute } from '@/components/auth-route/AuthRoute'
import CustomSuspense from '@/modules/app/CustomSuspense'

// import RewardCenter from '@/modules/rewardCenter'
// import Sport from '@/modules/sport/Sport'
// import DailyTasks from '@/modules/task/DailyTasks'
// import RechargeTasks from '@/modules/task/RechargeTasks'
// import TransactionHistory from '@/modules/transaction-history/TransactionHistory'
// import PersonalCenter from '@/pages/personal-center'
import Loading from './Loading'

const PersonalCenter = lazy(
  () => import('@/modules/person-center/PersonCenter'),
)
const TransactionHistory = lazy(
  () => import('@/modules/transaction-history/TransactionHistory'),
)
const RechargeTasks = lazy(() => import('@/modules/task/RechargeTasks'))
const DailyTasks = lazy(() => import('@/modules/task/DailyTasks'))
const Sport = lazy(() => import('@/modules/sport/Sport'))
const RewardCenter = lazy(() => import('@/modules/rewardCenter'))

const newGameHistory = isFeatureEnabled('newGameHistory')

const childRoutes: RouteObject[] = [
  {
    path: '/user/profile',
    element: <PersonalCenter />,
  },
  {
    path: '/user/tasks/daily',
    element: <DailyTasks />,
  },
  {
    path: '/user/tasks/recharge',
    element: <RechargeTasks />,
  },
  {
    path: '/user/transaction-history',
    element: <TransactionHistory />,
  },
  {
    path: '/user/sport',
    element: <Sport />,
  },
  {
    path: '/user/reward-center',
    element: <RewardCenter />,
  },
]

if (isFeatureEnabled('gameCollection')) {
  const AsyncMyGames = lazy(() => import('@/modules/my-games/MyGames'))
  const FavoriteGames = lazy(() => import('@/modules/my-games/FavoriteGames'))
  const Recently = lazy(() => import('@/modules/my-games/RecentlyPlayedGames'))
  const GameHistory = lazy(() =>
    newGameHistory
      ? import('@/modules/game-history/GameHistory')
      : import('@/modules/game-history/GameHistoryOld'),
  )
  childRoutes.push({
    path: 'my-games',
    element: (
      <CustomSuspense fallback={<Loading />}>
        <AsyncMyGames />
      </CustomSuspense>
    ),
    children: [
      {
        path: 'favorite',
        element: <FavoriteGames />,
      },
      {
        path: 'recently',
        element: <Recently />,
      },
      {
        path: 'history',
        element: <GameHistory pt='0' />,
      },
      {
        path: '',
        element: <FavoriteGames />,
      },
    ],
  })
} else {
  const AsyncGameHistory = lazy(() =>
    newGameHistory
      ? import('@/modules/game-history/GameHistory')
      : import('@/modules/game-history/GameHistoryOld'),
  )
  childRoutes.push({
    path: '/user/game-history',
    element: (
      <CustomSuspense fallback={<Loading />}>
        <AsyncGameHistory />
      </CustomSuspense>
    ),
  })
}

export const userRouter: RouteObject = {
  path: '/user',
  element: <AuthRoute />,
  children: childRoutes,
}
