import { useRef, useState } from 'react'
import type { SwiperClass } from 'swiper/react'
import RecordsSwiper from './RecordsSwiper'
import { Flex, HStack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { AtomSectionHeading } from '@/components/atom-section-heading/AtomSectionHeading'
import IconLinkBtn from '@/modules/base-components/IconLinkBtn'
import { useLargerThanMobile } from '@/utils/hooks'
import { PagniationBtns } from '@/modules/game/logic/game-list/recommend-game-groups/DesktopGroupGame'
import { GameListItem } from '@/modules/game/gameInterface'
import get from 'lodash-es/get'
import { useGetRecordsAndCollectionsDataQuery } from '@/modules/my-games/api'
import { isEmpty } from 'lodash-es'
import favoriteIcon from '@/assets/images/favorite-icon.png'

const GameRecordsAndCollection = () => {
  // const [data, setData] = useState([])
  const { data } = useGetRecordsAndCollectionsDataQuery(
    {},
    { refetchOnMountOrArgChange: true },
  )
  const [progress, setProgress] = useState(0)
  // const navigate = useNavigate()
  // const {data: a} = useGetSomeQuery()
  const swiperRef = useRef<SwiperClass | null>(null)
  const { t } = useTranslation()
  const isLargerThanMobile = useLargerThanMobile()
  const collectionGameList: GameListItem[] = get(
    data,
    'data.collection_game_list',
    [],
  )

  const recordGameList: GameListItem | null = get(
    data,
    'data.record_game_list[0]',
    null,
  )

  let _collectionGameList = [...collectionGameList]

  if (recordGameList) {
    _collectionGameList = [
      {
        ...(recordGameList as GameListItem),
        extraText: t('GAME_RECORDS_SIGN1'),
      },
      ..._collectionGameList,
    ]
  }

  const onSwiperInit = (swiper: SwiperClass) => {
    swiperRef.current = swiper
  }

  const onPrevClick = () => {
    swiperRef.current?.slidePrev()
  }

  const onNextClick = () => {
    swiperRef.current?.slideNext()
  }

  const onProgress = (instance: SwiperClass, progress: number) => {
    setProgress(progress)
  }

  if (isEmpty(collectionGameList) && !recordGameList) {
    return <></>
  }

  const getPath = () => {
    if (recordGameList) {
      return '/user/my-games/recently'
    }
    return '/user/my-games/favorite'
  }

  return (
    <Flex direction='column' gap={[3, 5]}>
      <HStack justifyContent='space-between' spacing='4'>
        <AtomSectionHeading icon={favoriteIcon} useImage={true}>
          {t('HOMEPAGE_TITLE1')}
        </AtomSectionHeading>

        <Flex gap={2}>
          {isLargerThanMobile && (
            <PagniationBtns
              onNext={onNextClick}
              onPrev={onPrevClick}
              isNextDisabled={progress === 1}
              isPrevDisabled={progress === 0}
            />
          )}
          <IconLinkBtn
            text={t('HOMEPAGE_COLLECTION_BOTTON')}
            to={getPath()}
            extra={''}
          />
        </Flex>
      </HStack>

      <RecordsSwiper
        games={_collectionGameList}
        onSwiperInit={onSwiperInit}
        onProgress={onProgress}
        detailPagePath={getPath()}
      />
    </Flex>
  )
}

export default GameRecordsAndCollection
