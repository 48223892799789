import { lazy } from 'react'
import type { RouteObject } from 'react-router-dom'

import CustomSuspense from '@/modules/app/CustomSuspense'

import Loading from './Loading'

const Fairness = lazy(() => import('@/modules/help/Fairness'))
const Faq = lazy(() => import('@/modules/help/Faq'))
const Kyc = lazy(() => import('@/modules/help/Kyc'))
const Legal = lazy(() => import('@/modules/help/Legal'))
const PrivatePolicy = lazy(() => import('@/modules/help/Privacy'))
const Responsibility = lazy(() => import('@/modules/help/Responsibility'))
const Terms = lazy(() => import('@/modules/help/Terms'))

export const helpRouter: RouteObject[] = [
  {
    path: '/help/kyc',
    element: (
      <CustomSuspense fallback={<Loading />}>
        <Kyc />
      </CustomSuspense>
    ),
  },
  {
    path: '/help/terms_and_condition',
    element: (
      <CustomSuspense fallback={<Loading />}>
        <Terms />
      </CustomSuspense>
    ),
  },
  {
    path: '/help/privacy',
    element: (
      <CustomSuspense fallback={<Loading />}>
        <PrivatePolicy />
      </CustomSuspense>
    ),
  },
  {
    path: '/help/responsible_gambling',
    element: (
      <CustomSuspense fallback={<Loading />}>
        <Responsibility />
      </CustomSuspense>
    ),
  },
  {
    path: '/help/fairness',
    element: (
      <CustomSuspense fallback={<Loading />}>
        <Fairness />
      </CustomSuspense>
    ),
  },
  {
    path: '/help/faq',
    element: (
      <CustomSuspense fallback={<Loading />}>
        <Faq />
      </CustomSuspense>
    ),
  },
  {
    path: '/help/legal',
    element: (
      <CustomSuspense fallback={<Loading />}>
        <Legal />
      </CustomSuspense>
    ),
  },
]
