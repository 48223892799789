import { post, postWS } from '@/modules/inhouse/common/api'
import { NOWS } from '@/modules/inhouse/common/env'
import type { RequestRes } from '@/modules/inhouse/common/request'
import { hasWindowGameInstance } from '@/modules/inhouse/common/utils/hasWindowGameInstance'
import { getUserId, getUserToken } from '@/modules/user/storage'

import type { PlinkColorId, PlinkColors } from './state'

export interface BetPlinkParams {
  amount: number
  color: PlinkColors
  currency: string
}

export async function betPlinko({
  amount,
  color,
  currency,
}: BetPlinkParams): Promise<RequestRes<any>> {
  const data = {
    bet_amount: amount,
    color,
    currency,
    token: getUserToken(),
    player_id: getUserId(),
  }

  let res = null
  if (NOWS) {
    res = post('/game/plinko/bet', data)
  } else {
    res = postWS('bet', 'plinko', data)
  }

  return res
}

export interface PlayPlinkoPayload {
  betRes: any
  color: PlinkColorId
}

export function playPlinko({ betRes, color }: PlayPlinkoPayload) {
  const strResult = betRes.f_seed[0].split('_')[0]
  const route = []
  for (const ch of strResult) {
    route.push(Number(ch))
  }
  const gameInstance = window['game']

  hasWindowGameInstance('plinko', gameInstance) &&
    gameInstance.play(color, route, betRes)
}
