import { atom } from 'recoil'

import type { BetInfo } from './common-state'

/** rules */
export const rulesModalAtom = atom({
  key: 'rulesModal',
  default: false,
})

export const rulesModalDefaultIndexAtom = atom({
  key: 'rulesModalDefaultIndexAtom',
  default: 0,
})

/** fairness */
export const fairnessModalAtom = atom({
  key: 'fairnessModal',
  default: false,
})

/** reward modal */
export const rewardModalAtom = atom<BetInfo[]>({
  key: 'rewardModal',
  default: [], // save modal number
})
