import type { DrawerProps } from '@chakra-ui/react'
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react'
import type { PropsWithChildren } from 'react'
import React from 'react'

export type IDrawer<P> = P & {
  isOpen: boolean
  onClose: () => void
} & DrawerProps

export type AtomDrawerProps = IDrawer<
  PropsWithChildren<{
    title: string | React.ReactNode
    action?: React.ReactNode
    padding?: boolean
    closeColor?: string
    contentBg?: string
  }>
> &
  DrawerProps

export default function TitledDialog(props: AtomDrawerProps) {
  return (
    <Drawer
      placement={'right'}
      {...props}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <DrawerOverlay />
      <DrawerContent
        overflowX='hidden'
        sx={{
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <DrawerHeader
          cursor='default'
          lineHeight='24px'
          padding={props?.padding ? '0px' : ''}
        >
          {props.title}
        </DrawerHeader>
        <DrawerCloseButton
          color={props?.closeColor ? props?.closeColor : 'inherit'}
          zIndex='2'
        />
        <DrawerBody
          padding={props?.padding ? '0px !important' : ''}
          bg={props?.contentBg}
        >
          {props.children}
        </DrawerBody>
        {props.action ? <DrawerFooter>{props.action}</DrawerFooter> : null}
      </DrawerContent>
    </Drawer>
  )
}
