import { Box, Center, Tab, TabList, Tabs } from '@chakra-ui/react'
import { t } from 'i18next'
import { memo } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import theme from '@/websites/current/styles/components/inhouse'

import { AutoBetConfigPanle } from '../../../common/components'
import type { AutoConfigItem } from '../../../common/components/auto-bet'
import { walletInfoAtom } from '../../atoms/common-state'
import {
  autoBetConfigAtom,
  autoBetInAutoAtom,
  autoBetInputDisabledAtom,
  isManualTabAtom,
} from './state'

const { layoutGap, tabMaxWidth } = theme
const items: AutoConfigItem[] = [
  {
    name: 'GAME_NUMBER_BETS',
    key: 'count',
  },
  {
    name: 'GAME_STOP_ON_PROFIT',
    key: 'stopWin',
  },
  {
    name: 'GAME_STOP_ON_LOSS',
    key: 'stopLoss',
  },
  {
    name: 'GAME_MAX_BET_AMOUNT',
    key: 'max',
  },
  {
    name: 'GAME_ON_WIN',
    key: 'raiseWin',
  },
  {
    name: 'GAME_ON_LOSS',
    key: 'raiseLoss',
  },
]
/**
 *
 * @param keys The name of key
 * @returns tab components
 */
export function getTabs<T extends string>(
  items: Readonly<{ key: T; value: string }[]>,
): React.ReactNode {
  return items.map(({ key, value }) => (
    <Tab key={key} w='50%'>
      {t(value)}
    </Tab>
  ))
}

export const configTabsKeys = [
  {
    key: 'manual',
    value: 'GAME_MANUAL',
  },
  {
    key: 'auto',
    value: 'GAME_AUTO',
  },
] as const

const AutoBetConfig = () => {
  const disabled = useRecoilValue(autoBetInputDisabledAtom)
  const [isManualTab, setIsManualTab] = useRecoilState(isManualTabAtom)
  const [autoBetConfig, setBetConfigState] = useRecoilState(autoBetConfigAtom)
  const isAutoMode = useRecoilValue(autoBetInAutoAtom)
  const { currency } = useRecoilValue(walletInfoAtom)

  const activeTabIndex = isManualTab ? 0 : 1

  const realDisabled = disabled || isAutoMode

  return (
    <>
      <Center>
        <Tabs
          mt={layoutGap}
          variant='solid-rounded'
          onChange={i => !realDisabled && setIsManualTab(i === 0)}
          index={activeTabIndex}
          w={['300px', 'full']}
        >
          <TabList m='auto' maxW={tabMaxWidth}>
            {getTabs(configTabsKeys)}
          </TabList>
        </Tabs>
      </Center>

      {!isManualTab && (
        <Box mt={layoutGap} cursor={realDisabled ? 'not-allowed' : 'pointer'}>
          <AutoBetConfigPanle
            disabled={realDisabled}
            items={items}
            sign={currency.sign}
            values={autoBetConfig}
            onChange={(k, v) => {
              setBetConfigState(state => ({
                ...state,
                [k]: v,
              }))

              if (k === 'count')
                setBetConfigState(state => ({
                  ...state,
                  initCount: v,
                }))
            }}
          />
        </Box>
      )}
    </>
  )
}

const MemoAutoBetConfig = memo(AutoBetConfig)
export default MemoAutoBetConfig
