// axios instance

import type { AxiosRequestConfig } from 'axios'
import axios from 'axios'

import { isFeatureEnabled } from '@/common/feature'
import { getUserId, getUserToken } from '@/modules/user/storage'
import getIsSupportPmsdk from '@/utils/features/get-is-support-pmsdk'
import { getDeviceType } from '@/utils/tools'

const TIMEOUT = 20 * 1000
const instance = axios.create({ timeout: TIMEOUT })

const DEVICE_TYPE = {
  apk: 'android_app',
  'android-desktop-icon': 'android_desktop',
  pc: 'pc',
  android: 'android_web',
  'ios-desktop-icon': 'ios_desktop',
  ios: 'ios_web',
  ios_app: 'ios_app',
}[getDeviceType()]

export const getAuthorization = () => {
  if (typeof window === 'undefined') {
    return null
  }
  const token = getUserToken() ?? ''
  const userId = getUserId() ?? ''
  return `${userId};${token}`
}

instance.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么
    if (config.headers) {
      const authorization = getAuthorization()
      if (authorization) {
        config.headers.authorization = authorization
      }

      if (isFeatureEnabled('loginAndSignUpV3')) {
        config.headers['U-DeviceType'] = DEVICE_TYPE
      }
    }
    ;(config as any)['request-startTime'] = performance.now()
    return config
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(response => {
  const currentTime = performance.now()
  const startTime = (response.config as any)['request-startTime']
  const cost = Math.ceil(currentTime - startTime)

  if (getIsSupportPmsdk(window.pmSdk)) {
    window.pmSdk.log(
      'clientmetrics_api_time_spent',
      {
        api: response.config.url,
        time_spent: cost,
      },
      {
        labels: ['api'],
        value: 'time_spent',
      },
    )
  }

  return response
})

export const request = instance
export declare type RequestMethod = AxiosRequestConfig['method']
export declare type RequestData = AxiosRequestConfig['data']
export declare type RequestParams = AxiosRequestConfig['params']

export default request
