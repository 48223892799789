import { Image, Text } from '@chakra-ui/react'

import VipLevelIcon from '../new-vip/components/VipLevelIcon'
import VipLevelNumIcon from '../new-vip/components/VipLevelNumIcon'
import getIsSupportLevelProtection from '../new-vip/features/vip-level-protection/get-is-support-level-protection'
import { getVipLevelIconSrc } from '../vip/utils'

interface Props {
  vipLevel: number
}
const Old = ({ vipLevel }: Props) => {
  const formatVipLevel = (n: number) => `VIP ${n}`
  return (
    <>
      <Image
        src={getVipLevelIconSrc(vipLevel)}
        alt='vip'
        objectFit='contain'
        w='102px'
      />
      <Text textStyle='h6' color='white'>
        {formatVipLevel(vipLevel)}
      </Text>
    </>
  )
}

const New = ({ vipLevel }: Props) => {
  return (
    <>
      <VipLevelIcon level={vipLevel} />
      <VipLevelNumIcon level={vipLevel} />
    </>
  )
}

export default function UserProfilePopoverLevelIcon(props: Props) {
  const support = getIsSupportLevelProtection()

  if (support) return <New {...props} />

  return <Old {...props} />
}
