import axios from 'axios'

import { getLocalConfigBaseUrl } from '@/common/env'
import { getCurrencyCode } from '@/common/locale/currency'
import { fatal } from '@/common/logger'
import type { types } from '@/utils/atom-shared'
import getHashUrl from '@/utils/get-hash-url'

let _games: Record<string, types.GameConfig> | null = null

export async function loadInhouseConfig(): Promise<void> {
  const currencyCode = getCurrencyCode() || ''
  axios
    .get(
      getHashUrl(getLocalConfigBaseUrl(currencyCode) + '/inhouse-config.json'),
    )
    .then(res => {
      if (res.status !== 200) {
        fatal(`load inhouse config failed: ${res.status}`)
        return
      }
      _games = res.data
    })
}

/** 读取游戏配置 */
export function getGameConfig(gameId: string): types.GameConfig {
  if (!_games) {
    throw new Error('inhouse config not loaded')
  }

  return _games?.[gameId] || {}
}
