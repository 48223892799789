import { useCallback, useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { userInfoAtom, walletInfoAtom } from '../atoms/common-state'

type Fn = <T>(fn: T) => T

export function useAuthenticFn(): {
  enhanceAuth: Fn
} {
  const { isLoggedIn, showLogin } = useRecoilValue(userInfoAtom)
  const { needBindCurrency, showBindCurrency } = useRecoilValue(walletInfoAtom)

  const fn: Fn = useCallback(
    f => {
      if (!isLoggedIn) {
        showLogin()
        return (() => null) as any
      } else {
        if (needBindCurrency) {
          showBindCurrency()
          return (() => null) as any
        } else {
          return f
        }
      }
    },
    [isLoggedIn, needBindCurrency, showBindCurrency, showLogin],
  )

  return useMemo(
    () => ({
      enhanceAuth: fn,
    }),
    [fn],
  )
}

export default useAuthenticFn
