import type { InputProps, SwitchProps } from '@chakra-ui/react'
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  SimpleGrid,
  Switch,
  Text,
  useNumberInput,
} from '@chakra-ui/react'
import { keyBy, toNumber } from 'lodash-es'
import type { FC } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useCustomizedStyle } from '@/utils/hooks'

useCustomizedStyle

export const PARTS = [
  'box',
  'label',
  'input',
  'switchbox',
  'switchtext',
] as const
export const NAME = 'ConfigPanel'

export type AutoConfig = {
  count: number

  /** stop if AutoStatus.total >=  stopWin */
  stopWin: number

  /** stop if AutoStatus.total <=  stopLoss */
  stopLoss: number

  max: number
  raiseWin: number
  raiseLoss: number
  raiseWinOn: boolean
  raiseLossOn: boolean
}
export type Items = keyof AutoConfig

export interface AutoConfigItem {
  name: string
  key: Items
}

export interface ConfigPanelProps {
  items: AutoConfigItem[]
  values: AutoConfig
  disabled?: boolean
  sign?: string
  onChange?: (key: string, value: any) => void
}

export const AutoBetConfigPanle: FC<ConfigPanelProps> = ({
  items,
  values,
  sign,
  onChange,
  disabled,
}) => {
  const itemsMap = keyBy(items, 'key')
  const { t } = useTranslation()

  const {
    box: boxStyle,
    label: labelStyle,
    input: inputStyle,
  } = useCustomizedStyle(
    NAME,
    {
      box: {
        position: 'relative',
        padding: '15px',
        borderRadius: '10px',
        bgColor: '#fff',
        overflow: 'hidden',
      },
      label: {},
      input: {
        bgColor: '#edeff1',
        boxShadow: 'none',
        border: 'none',
        fontSize: '16px',
        fontWeight: '800',
        _focusVisible: {
          boxShadow: 'none',
        },
      },
    },
    PARTS,
  )

  return (
    <Box sx={boxStyle}>
      {disabled && (
        <Box
          position='absolute'
          w='100%'
          h='100%'
          top='0'
          left='0'
          zIndex='1'
          bgColor='rgba(255,255,255, .3)'
        />
      )}
      <FormControl>
        <SimpleGrid columns={[1, 2]} spacing='2.5'>
          <Box id='count' overflow='hidden'>
            <FormLabel sx={labelStyle} mx={['6px', 0]}>
              {t(itemsMap['count'].name)}
            </FormLabel>
            <ConfigPanelNumberInput
              value={isNaN(values.count) ? 0 : values.count}
              sx={inputStyle}
              suffixIcon={values.count === 0 && '∞'}
              onChange={v =>
                onChange && onChange('count', toNumber(v.target.value))
              }
            />
          </Box>

          <Box id='stopWin' overflow='hidden'>
            <FormLabel sx={labelStyle} mx={['6px', 0]}>
              {t(itemsMap['stopWin'].name)}
            </FormLabel>
            <ConfigPanelNumberInput
              value={isNaN(values.stopWin) ? 0 : values.stopWin}
              sx={inputStyle}
              prefixIcon={sign}
              onChange={v =>
                onChange && onChange('stopWin', toNumber(v.target.value))
              }
            />
          </Box>

          <Box id='stopLoss' overflow='hidden'>
            <FormLabel sx={labelStyle} mx={['6px', 0]}>
              {t(itemsMap['stopLoss'].name)}
            </FormLabel>
            <ConfigPanelNumberInput
              value={isNaN(values.stopLoss) ? 0 : values.stopLoss}
              sx={inputStyle}
              prefixIcon={sign}
              onChange={v =>
                onChange && onChange('stopLoss', toNumber(v.target.value))
              }
            />
          </Box>

          <Box id='max' overflow='hidden'>
            <FormLabel sx={labelStyle} mx={['6px', 0]}>
              {t(itemsMap['max'].name)}
            </FormLabel>
            <ConfigPanelNumberInput
              value={isNaN(values.max) ? 0 : values.max}
              sx={inputStyle}
              prefixIcon={sign}
              onChange={v =>
                onChange && onChange('max', toNumber(v.target.value))
              }
            />
          </Box>

          <Box id='raiseWin' overflow='hidden'>
            <FormLabel sx={labelStyle} mx={['6px', 0]}>
              {t(itemsMap['raiseWin'].name)}
            </FormLabel>

            <Flex overflow='hidden'>
              <ConfigPanelNumberSwitch
                offLabel={t('GAME_INCREASE')}
                onLabel={t('GAME_RESET')}
                isChecked={values.raiseWinOn}
                onChange={e =>
                  onChange && onChange('raiseWinOn', e.target.checked)
                }
              />
              <ConfigPanelNumberInput
                value={isNaN(values.raiseWin) ? 0 : values.raiseWin}
                sx={inputStyle}
                pr='6'
                suffixIcon='%'
                onChange={v =>
                  onChange && onChange('raiseWin', toNumber(v.target.value))
                }
              />
            </Flex>
          </Box>

          <Box id='raiseLoss' overflow='hidden'>
            <FormLabel sx={labelStyle} mx={['6px', 0]}>
              {t(itemsMap['raiseLoss'].name)}
            </FormLabel>
            <Flex>
              <ConfigPanelNumberSwitch
                isChecked={values.raiseLossOn}
                offLabel={t('GAME_INCREASE')}
                onLabel={t('GAME_RESET')}
                onChange={e =>
                  onChange && onChange('raiseLossOn', e.target.checked)
                }
              />

              <ConfigPanelNumberInput
                value={isNaN(values.raiseLoss) ? 0 : values.raiseLoss}
                sx={inputStyle}
                pr='6'
                suffixIcon='%'
                onChange={v =>
                  onChange && onChange('raiseLoss', toNumber(v.target.value))
                }
              />
            </Flex>
          </Box>
        </SimpleGrid>
      </FormControl>
    </Box>
  )
}

interface ConfigPanelNumberInputProps extends InputProps {
  prefixIcon?: string | React.ReactNode
  suffixIcon?: string | React.ReactNode
}

const ConfigPanelNumberInput: FC<ConfigPanelNumberInputProps> = ({
  value,
  prefixIcon,
  suffixIcon,
  ...restProps
}) => {
  const { getInputProps } = useNumberInput()
  const numberInputProps = getInputProps()

  let v
  if (typeof value === 'string') {
    if (value === '') {
      v = 0
    } else {
      v = parseInt(value) < 0 ? 0 : parseInt(value)
    }
  } else {
    v = value
  }

  return (
    <InputGroup>
      {prefixIcon && (
        <InputLeftElement
          pointerEvents='none'
          color='text.accent'
          fontSize='16px'
          fontWeight='800'
          w='10'
          h={'46px'}
        >
          {prefixIcon}
        </InputLeftElement>
      )}
      <Input {...numberInputProps} {...restProps} value={v} min={0} />
      {suffixIcon && (
        <InputRightElement
          pointerEvents='none'
          color='text.accent'
          fontSize='16px'
          fontWeight='600'
          w='8'
        >
          {suffixIcon}
        </InputRightElement>
      )}
    </InputGroup>
  )
}

interface ConfigPanelNumberSwitchProps extends SwitchProps {
  isChecked: boolean
  onLabel: string
  offLabel: string
}
const ConfigPanelNumberSwitch: FC<ConfigPanelNumberSwitchProps> = ({
  isChecked,
  onLabel,
  offLabel,
  onChange,
}) => {
  const { switchbox: switchboxStyle, switchtext: switchtextStyle } =
    useCustomizedStyle(
      NAME,
      {
        switchbox: {
          background: 'gray.100',
          borderRadius: '10px',
          marginRight: '14px',
          paddingX: '10px',
          _checked: {
            background: 'gray.100',
          },
        },
        switchtext: {},
      },
      PARTS,
    )

  return (
    <HStack sx={switchboxStyle}>
      <Box>
        <Switch
          variant='auto'
          isChecked={isChecked}
          transform='rotate(-90deg)'
          transformOrigin='50%'
          onChange={e => onChange && onChange(e)}
        />
      </Box>

      <Box
        marginRight='5px'
        fontSize='12px'
        maxW='62px'
        overflow='hidden'
        whiteSpace='nowrap'
        textOverflow='ellipsis'
      >
        <Text sx={switchtextStyle} aria-checked={isChecked}>
          {onLabel}
        </Text>
        <Text sx={switchtextStyle} aria-checked={!isChecked}>
          {offLabel}
        </Text>
      </Box>
    </HStack>
  )
}
