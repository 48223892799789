import { useLargerThanMobile } from '@/utils/hooks/useLargerThanMobile'

import DesktopRecordsSwiper from './RecordsSwiper.desktop'
import MobileRecordsSwiper from './RecordsSwiper.mobile'
import { RecordSwiperProps } from '../GameRecordsAndCollection.type'

export default function RecordsSwiper(props: RecordSwiperProps) {
  const isLargerThanMobile = useLargerThanMobile()

  if (isLargerThanMobile) {
    return <DesktopRecordsSwiper {...props} />
  } else {
    return <MobileRecordsSwiper {...props} />
  }
}
