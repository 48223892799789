import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'

import { getCurrencyCode } from '@/common/locale/currency'
import logRechargeApiFailed from '@/common/pmsdk/logic/log-recharge-api-failed'
import logRechargeApiSuccess from '@/common/pmsdk/logic/log-recharge-api-success'
import logRechargeFailed from '@/common/pmsdk/logic/log-recharge-failed'
import type {
  ActInfoProps,
  FormatVirtualOrder,
  RechargSelectProps,
  WalletState,
  WithdrawalMethodDetail,
  WithdrawArgs,
} from '@/modules/wallet/interface'
import {
  DEFAULT_ACT_INFO,
  DEFAULT_USER_SAVE_INFO,
  DEFAULT_VIRTUAL_ITEM,
} from '@/modules/wallet/interface'
import { pageConfigApi } from '@/services/jsonApi'
import { localStorage } from '@/utils/localStorage'

import type { RootState } from '../app/store'
import { closeModal, openModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import { loginRewardApi } from '../login-reward-modal/loginRewardModalApi'
import { selecVipConfigList } from '../new-vip/newVipSlice'
import {
  getOpenModal,
  getRechargeAmount,
  setRechargeTyp,
  STORAGE_KEY_RECHARGE_TYPE,
  switchRecahrgeActModal,
} from '../recharge-activity/rechargeActSlice'
import { selectUserVipInfo } from '../user/userSlice'
import { handleRemovePhoneWithAreaCode, handleWalletConfig } from './utils'
import { walletApi } from './walletApi'
import type { AmountRechargeProp } from './walletContansts'
import {
  // AMOUNT_LIST,
  // CODE_MAP,
  CURRENCY_RATE,
  DEFAULT_AMOUNT,
  DEFAULT_INDEX,
  options as payChannelMap,
  WITHDRAW_WAYS_TEXT,
} from './walletContansts'
import type { WalletConfigProps } from './walletInterface'

export enum WalletModalStatus {
  open = 'open',
  close = 'close',
}

export enum WalletModalTab {
  recharge = 'recharge',
  withdraw = 'withdraw',
}
export enum WalletModalTabIndex {
  recharge = 0,
  withdraw = 1,
}

export enum WalletWithdrawType {
  pix = 'pix',
  electronic_wallet = 'electronic_wallet',
  mex = 'mex',
  bank = 'bank',
}

export const selectWallet = (state: RootState) => state.wallet

const CURRENCY = getCurrencyCode()

const initialState: WalletState = {
  activeTab: WalletModalTab.recharge,
  isParamsDialogOpen: false,
  actInfo: DEFAULT_ACT_INFO,
  withdrawAmount: 0,
  rechargeAmount: DEFAULT_AMOUNT,
  activedQuickRechargeItemIndex: DEFAULT_INDEX,
  useSaveInfo: DEFAULT_USER_SAVE_INFO,
  showSelectWays: false,
  activedQuickPayMethodId: '',
  activedQuickPayChannel: '',
  saveList: [],
  //虚拟支付
  showVirtualModal: false,
  showVirtualTipsModal: false,
  virtualOrderList: [],
  virtualItemIndex: -1,
  currentVirtualItem: DEFAULT_VIRTUAL_ITEM,
  withdrawExchangeRates: CURRENCY_RATE[CURRENCY],
  withdrawWays: '',
  withdrawWaysList: [], // 提现方式 多种
  withdrawFeilds: {}, // 提现信息表单字段
  withdrawCurrency: '', //大代理提现币种
  withdrawParams: {},
  defalutWithdrawParams: {},
  rechargeParams: {},
  recharge_amount_list: [],
  showAmountDetail: false, //打码量
  amountList: [], //打码量列表
  userAssets: {}, // 用户钱包
  rechargeList: [], //充值任务
  handleType: 'withdraw',
  joinActFlag: true,
  //二维码充值
  showPixQrCode: false,
  pixQrcodeInfo: {
    pix: '',
    name: '',
    email: '',
    phone: '',
    qrcode: '',
  },
  showAmountDetails: false, //是否显示打码量列表
  questType: 'request',
  showFullPageLoading: false,
  fullPageText: '',
  // 后台快捷充值取得配置
  walletConfig: {
    recharge_btn_rule_config: {
      btn_num: 3,
      btn_non_participation: 1,
    },
    recharge_quicker_btn_config: [],
    all_recharge_quicker_btn_config: [],
  },
  // 充提限额获取配置
  walletDurationConfig: {
    min_recharge: 0,
    min_withdraw: 0,
    max_recharge: 0,
    max_withdraw: 0,
    currency: '',
    currency_name: '',
    start_withdraw_time: '',
    end_withdraw_time: '',
  },
  walletDurationLoaded: false,
  //
  rechargeInfoList: {},
  mxnRecahrgeEmail: '',
  showMxnRecahrgeEmail: false,

  // 充值方式options
  rechargePaymentOptions: [],

  // 是否来自充值活动
  is_from_recharge_act: false,
  /** 实际发起充值金额 */
  rechargedAmount: 0,
  /** 提现信息是否允许修改 */
  withdrawToast: false,
  /** 充值信息 */
  rechargeInfo: undefined,
  /** 充值信息表单 */
  rechargeForm: undefined,

  /** gcash充值提示开关 */
  gcashTipsOptStatus: false,
}
export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    changeTabs: (state, action: PayloadAction<WalletModalTab>) => {
      state.activeTab = action.payload
      state.useSaveInfo = DEFAULT_USER_SAVE_INFO
    },
    changeAmountModal: state => {
      state.showAmountDetail = !state.showAmountDetail
    },
    setWithdrawWays: (state, action: PayloadAction<string>) => {
      state.withdrawWays = action.payload
    },
    setJoinAct: (state, action: PayloadAction<ActInfoProps>) => {
      const { actInfo } = action.payload
      state.actInfo = actInfo
    },
    setRechargeAmountList: (
      state,
      action: PayloadAction<AmountRechargeProp[]>,
    ) => {
      const list = action.payload || []
      state.recharge_amount_list = list
      const index = list.findIndex(item => item.value === state.rechargeAmount)
      state.activedQuickRechargeItemIndex = index
    },
    setDefaultRechargeAmount: (
      state,
      action: PayloadAction<{ default_amount: number; default_index: number }>,
    ) => {
      state.rechargeAmount = action.payload.default_amount
      state.activedQuickRechargeItemIndex = action.payload.default_index
    },
    setSaveInfo: (
      state,
      action: PayloadAction<{
        use?: boolean
        id: string
        item?: WithdrawalMethodDetail
      }>,
    ) => {
      const { use, id, item } = action.payload
      const withdrawWays = item?.pay_method || ''
      state.useSaveInfo = {
        use: !!use,
        id,
        item: item ?? {},
        ways: withdrawWays,
      }
      // state.withdrawWays = withdrawWays
      state.isParamsDialogOpen = true
    },
    setShowSelecWays: (state, action: PayloadAction<boolean>) => {
      state.showSelectWays = action.payload
    },
    //虚拟支付
    switchVirtualModal: state => {
      state.showVirtualModal = !state.showVirtualModal
    },
    // 关闭提示框
    switchVirtualModalTips: (
      state,
      action: PayloadAction<{
        showVirtualModal: boolean
        showVirtualTipsModal: boolean
      }>,
    ) => {
      const { showVirtualModal, showVirtualTipsModal } = action.payload
      state.showVirtualTipsModal = showVirtualTipsModal
      state.showVirtualModal = showVirtualModal
    },
    // 增加虚拟列表
    addVirtualOrder(state, action: PayloadAction<FormatVirtualOrder>) {
      state.virtualOrderList.unshift(action.payload)
      state.virtualItemIndex = 0
    },
    //删除虚拟列表
    deleteVirtualOrder(state, action: PayloadAction<{ index: number }>) {
      state.virtualOrderList.splice(action.payload, 1)
      localStorage.setItem(
        'virtualOrderList',
        JSON.stringify(state.virtualOrderList),
      )
    },
    //虚拟支付订单
    setVirtualOrderList: (
      state,
      action: PayloadAction<{
        list: FormatVirtualOrder[]
        index: number
      }>,
    ) => {
      const { list, index } = action.payload
      state.virtualOrderList = list
      state.virtualItemIndex = index
    },
    //虚拟支付
    setVirtualItemIndex: (
      state,
      action: PayloadAction<{
        index: number
      }>,
    ) => {
      const { index } = action.payload
      state.virtualItemIndex = index
    },
    setCurrentVirtualItem: (
      state,
      action: PayloadAction<FormatVirtualOrder>,
    ) => {
      state.currentVirtualItem = action.payload
    },
    setRechargeAmount: (state, action: PayloadAction<number>) => {
      const { payload: amount } = action
      state.rechargeAmount = amount
      const index = state.recharge_amount_list.findIndex(
        item => item.value === amount,
      )
      state.activedQuickRechargeItemIndex = index
    },
    setWithdrawAmount: (state, action: PayloadAction<number>) => {
      state.withdrawAmount = action.payload
    },
    setWithdrawFormParams: (
      state,
      action: PayloadAction<{
        paymentMethod: string
        data: Record<string, string>
      }>,
    ) => {
      const { paymentMethod, data } = action.payload
      state.withdrawParams[paymentMethod] = data
    },
    setRechargeFormParams: (
      state,
      action: PayloadAction<{
        data: Record<string, string>
      }>,
    ) => {
      const { data } = action.payload
      state.rechargeParams = data
    },
    setParamValue: (
      state,
      action: PayloadAction<{ key: string; value: string }>,
    ) => {
      const { key, value } = action.payload
      const { activeTab, withdrawWays } = state
      if (activeTab === WalletModalTab.recharge) {
        state.rechargeParams[key] = value
      } else if (activeTab === WalletModalTab.withdraw) {
        state.withdrawParams[withdrawWays][key] = value
      }
    },
    setWithdrawParamValue: (
      state,
      action: PayloadAction<{ [key: string]: any }>,
    ) => {
      const { withdrawWays } = state
      state.withdrawParams[withdrawWays] = action.payload
    },
    openParamsModal: state => {
      if (state.activeTab === WalletModalTab.recharge) {
        state.isParamsDialogOpen = true
      } else if (state.saveList.length > 0) {
        // state.showSelectWays = true
        // state.activedQuickPayMethodId = ''
        state.isParamsDialogOpen = true
      } else {
        state.isParamsDialogOpen = true
      }
    },
    manageWithdrawWays: state => {
      //
      state.showSelectWays = true
      // if (state.saveList.length > 0 && state.activedQuickPayMethodId) {
      //   state.showSelectWays = true
      //   // state.activedQuickPayMethodId = ''
      // } else {
      //   state.isParamsDialogOpen = true
      // }
    },
    closeParamsModal: state => {
      state.isParamsDialogOpen = false
      state.paramsDialogRechargeParams = undefined
    },
    setHandleType: (state, action: PayloadAction<string>) => {
      state.handleType = action.payload
    },
    changeQuickPayMethod: (state, action: PayloadAction<string>) => {
      state.activedQuickPayMethodId = action.payload
    },
    changeQuickPayChannel: (state, action: PayloadAction<string>) => {
      state.activedQuickPayChannel = action.payload
    },
    //提现币种
    setWithdrawCurrency: (state, action: PayloadAction<string>) => {
      state.withdrawCurrency = action.payload
    },
    setJoinActFlag: (state, action: PayloadAction<boolean>) => {
      state.joinActFlag = action.payload
    },
    closeQrModal: state => {
      state.showPixQrCode = false
    },
    setQrcodeInfo: (
      state,
      action: PayloadAction<{ [key: string]: string }>,
    ) => {
      state.pixQrcodeInfo = action.payload
      state.showPixQrCode = true
    },
    setQuestType: (state, action: PayloadAction<string>) => {
      state.questType = action.payload
    },
    setShowFullPageLoading: (
      state,
      action: PayloadAction<{
        loading: boolean
        text: string
      }>,
    ) => {
      const { loading, text } = action.payload
      state.showFullPageLoading = loading
      state.fullPageText = text
    },
    setRechargeInfoList: (
      state,
      action: PayloadAction<WithdrawalMethodDetail[]>,
    ) => {
      state.rechargeInfoList = action.payload
    },
    setMxnRecahrgeEmail: (state, action: PayloadAction<string>) => {
      state.mxnRecahrgeEmail = action.payload
    },
    setIsFromRechargeAct: (state, action: PayloadAction<boolean>) => {
      state.is_from_recharge_act = action.payload
    },
    setShowMxnRecahrgeEmail: (state, action: PayloadAction<boolean>) => {
      state.showMxnRecahrgeEmail = action.payload
    },
    setParamsDialogRechargeParams: (
      state,
      action: PayloadAction<{
        rechargeAmount: number
        taskId: string
        taskGift?: number
        giftId?: number
      }>,
    ) => {
      state.paramsDialogRechargeParams = action.payload
    },
    setRechargedAmount: (state, action: PayloadAction<number | string>) => {
      state.rechargedAmount = action.payload
    },
    // showFullPageLoading
  },
  extraReducers: builder => {
    builder
      .addCase(openModal, (state, action) => {
        const { key, data } = action.payload
        if (
          key === GlobalModalKey.Wallet &&
          typeof data === 'object' &&
          data !== null &&
          'defaultActivedTabKey' in data
        ) {
          state.activeTab = data.defaultActivedTabKey as WalletModalTab
        }
      })
      .addCase(switchRecahrgeActModal, (state, action) => {
        const firstType = state.rechargePaymentOptions[0]?.value as string
        const typ = localStorage.getItem(STORAGE_KEY_RECHARGE_TYPE) ?? firstType
        if (typ) {
          if (state.rechargeParams) {
            state.rechargeParams.typ = typ
          } else {
            state.rechargeParams = { typ }
          }
        }
      })
      .addCase(closeModal, (state, action) => {
        const { key } = action.payload
        if (key === GlobalModalKey.Wallet) {
          state.actInfo = DEFAULT_ACT_INFO
        }
      })
      .addCase(setRechargeTyp, (state, action) => {
        if (state.rechargeParams) {
          state.rechargeParams.typ = action.payload
        } else {
          state.rechargeParams = { typ: action.payload }
        }
      })
      .addMatcher(
        walletApi.endpoints.withdrawalMethods.matchFulfilled,
        (state, action) => {
          const { data } = action.payload
          if (data && data.length > 0) {
            const _data = [] as any
            data.forEach((item: any) => {
              const _item = Object.assign({}, item)
              if (item.account) {
                _item.account = handleRemovePhoneWithAreaCode(_item.account)
              }
              _data.push(_item)
            })
            state.saveList = _data
            state.activedQuickPayMethodId = _data[0]?.id
            state.activedQuickPayChannel = _data[0]?.pay_method
            //舍弃使用提现默认第一条
            if (_data[0]?.pay_method === 'pix') {
              // state.rechargeParams = { ...state.rechargeParams, ..._data[0] }
            }
            const _item = _data.find(item => item?.is_default === 1)
            state.activedQuickPayMethodId = _item?.id || _data[0]?.id
            state.activedQuickPayChannel =
              _item?.pay_method || _data[0]?.pay_method
          } else {
            state.saveList = []
            state.activedQuickPayMethodId = ''
            state.activedQuickPayChannel = ''
            state.useSaveInfo = DEFAULT_USER_SAVE_INFO
            // if (state.withdrawWays) {
            //   state.withdrawParams[state.withdrawWays] =
            //     state.defalutWithdrawParams[state.withdrawWays]
            // }
          }
        },
      )
      .addMatcher(
        walletApi.endpoints.withdrawalMethods.matchRejected,
        state => {
          state.saveList = []
        },
      )
      .addMatcher(
        walletApi.endpoints.withdrawExchangeRates.matchFulfilled,
        (state, action) => {
          const { data } = action.payload
          if (data.mid) {
            state.withdrawExchangeRates = data.mid
          }
        },
      )
      .addMatcher(
        walletApi.endpoints.rechargeArgs.matchFulfilled,
        (state, action) => {
          const { data } = action.payload
          state.rechargeParams = convertArgsToFormData(data?.args ?? [])
          state.rechargeForm = data.args.filter(item => item.name !== 'typ')
          state.rechargeParams.pay_method = data.pay_method
          const result: RechargSelectProps[] = []

          // 支付方式
          if (data?.args) {
            data.args
              .filter(filterItem => filterItem.name === 'typ')?.[0]
              ?.data?.forEach((item: string | Record<string, any>) => {
                if (typeof item === 'string') {
                  const key = item.toLocaleLowerCase()
                  if (key in payChannelMap) {
                    const { label, icon } = payChannelMap[key] ?? {}
                    result.push({ label, icon, value: item })
                  }
                } else {
                  result.push({
                    label: item.value,
                    value: item.value,
                    icon: item?.img || '',
                    markImg: item?.mark_img || '',
                    min_recharge: item?.min_recharge || 0,
                    max_recharge: item?.max_recharge || 0,
                  })
                }
              })
          }
          state.rechargePaymentOptions = result
        },
      )
      .addMatcher(
        walletApi.endpoints.withdrawArgs.matchFulfilled,
        (state, action) => {
          const { data } = action.payload
          const payWays: { value: string; label: string }[] = []
          state.withdrawParams = data?.reduce((acc, cur) => {
            const { pay_method, args } = cur
            payWays.push({
              value: pay_method,
              label: WITHDRAW_WAYS_TEXT[pay_method],
            })
            acc[pay_method] = convertArgsToFormData(args)
            return acc
          }, {} as Record<string, Record<string, string>>)
          state.defalutWithdrawParams = state.withdrawParams
          if (!state.withdrawWays) {
            state.withdrawWays = data[0].pay_method
          }
          state.withdrawWaysList = payWays
          state.withdrawWays = payWays[0].value
          const withdrawFeilds = {} as Record<string, string[]>

          data?.forEach(item => {
            withdrawFeilds[item.pay_method] = item?.args?.map(
              subItem => subItem.name,
            )
          })
          state.withdrawFeilds = withdrawFeilds
        },
      )
      .addMatcher(
        walletApi.endpoints.agentAllAsset.matchFulfilled,
        (state, action) => {
          const { data } = action.payload
          state.userAssets = data
        },
      )
      .addMatcher(
        isAnyOf(
          walletApi.endpoints.rechargeTaskList.matchFulfilled,
          loginRewardApi.endpoints.rechargeTask.matchFulfilled,
        ),
        (state, action) => {
          state.rechargeList = action.payload?.data || []
        },
      )
      .addMatcher(
        walletApi.endpoints.userGiftList.matchFulfilled,
        (state, action) => {
          if (state.showAmountDetails) return
          state.showAmountDetails = action.payload?.data.length > 0
        },
      )
      .addMatcher(
        pageConfigApi.endpoints.getHomePageConfig.matchFulfilled,
        (state, action: PayloadAction<WalletConfigProps>) => {
          const { recharge_btn_rule_config, recharge_quicker_btn_config } =
            action.payload

          state.walletConfig = {
            recharge_btn_rule_config,
            recharge_quicker_btn_config: handleWalletConfig(
              recharge_quicker_btn_config,
            ),
            all_recharge_quicker_btn_config: recharge_quicker_btn_config,
          }
        },
      )
      .addMatcher(
        walletApi.endpoints.getWalletConfigInfo.matchFulfilled,
        (state, action: PayloadAction<any>) => {
          const _config = action.payload?.data
          state.walletDurationConfig = _config
          state.walletDurationLoaded = true
        },
      )
      .addMatcher(
        walletApi.endpoints.getUserRechargeInfo.matchFulfilled,
        (state, action: PayloadAction<any>) => {
          const _data = action.payload?.data || {}
          state.rechargeInfoList = _data
          //
          state.rechargeParams = { ...state.rechargeParams, ..._data }
        },
      )
      .addMatcher(
        walletApi.endpoints.getWalletConfigInfo.matchRejected,
        state => {
          state.walletDurationLoaded = true
        },
      )
      .addMatcher(
        walletApi.endpoints.getWithdrawToastSwitch.matchFulfilled,
        (state, action) => {
          const { enable } = action?.payload?.data || {}
          state.withdrawToast = enable === 1
        },
      )
      .addMatcher(
        walletApi.endpoints.recharge.matchFulfilled,
        (state, { payload }) => {
          if (payload.code === 200) {
            /** pmSdk tag: count recharge api success*/
            logRechargeApiSuccess()
          } else {
            /** pmSdk tag: log when recharge api code !== 200 */
            logRechargeApiFailed(`${payload.code} - ${payload?.message}`)
          }
        },
      )
      .addMatcher(
        walletApi.endpoints.recharge.matchRejected,
        (state, { payload }) => {
          /** pmSdk tag: log when recharge api failed: timeout or code !== 200 or http error */
          logRechargeApiFailed(`${payload?.status} - ${payload?.message}`)

          if (window?.__count_recharge_api_error === undefined) {
            window.__count_recharge_api_error = 1
          } else {
            window.__count_recharge_api_error += 1
          }

          if (window.__count_recharge_api_error === 3) {
            /** pmSdk tag: Consider the whole as a complete entity and ignoring the retried requests */
            logRechargeFailed(`${payload?.status} - ${payload?.message}`)
            window.__count_recharge_api_error = undefined
          }
        },
      )
      .addMatcher(
        walletApi.endpoints.getPlayerRechargeInfo.matchFulfilled,
        (state, action) => {
          const _data = action.payload?.data || {}
          state.rechargeInfo = _data
        },
      )
      .addMatcher(
        walletApi.endpoints.getPlayerRechargeInfo.matchRejected,
        (state, action) => {
          state.rechargeInfo = undefined
        },
      )
      .addMatcher(
        walletApi.endpoints.getGcashTipsOpt.matchFulfilled,
        (state, { payload }) => {
          const statusSwitch = !!(payload.data.enable === 1)
          state.gcashTipsOptStatus = statusSwitch
        },
      )

    // getUserRechargeInfo
  },
})

export const convertArgsToFormData = (args: WithdrawArgs[]) => {
  const values: Record<string, string> = {}
  args?.forEach(item => {
    const { name, type, data } = item
    const defaultValue =
      type === 'select'
        ? typeof data[0] === 'string'
          ? data[0]
          : (data[0] as any)?.value || ''
        : ''
    values[name] = defaultValue
  })
  return values
}

export const {
  setRechargeInfoList,
  setHandleType,
  setJoinActFlag,
  changeTabs,
  setJoinAct,
  setSaveInfo,
  setWithdrawWays,
  setRechargeAmountList,
  setDefaultRechargeAmount,
  setParamValue,
  setRechargeAmount,
  setWithdrawAmount,
  setShowSelecWays,
  setWithdrawFormParams,
  setRechargeFormParams,
  switchVirtualModal,
  switchVirtualModalTips,
  addVirtualOrder,
  setVirtualOrderList,
  setVirtualItemIndex,
  setCurrentVirtualItem,
  openParamsModal,
  closeParamsModal,
  changeQuickPayMethod,
  changeQuickPayChannel,
  setWithdrawCurrency,
  changeAmountModal,
  manageWithdrawWays,
  setWithdrawParamValue,
  deleteVirtualOrder,
  closeQrModal,
  setQrcodeInfo,
  setQuestType,
  setShowFullPageLoading,
  setMxnRecahrgeEmail,
  setShowMxnRecahrgeEmail,
  setParamsDialogRechargeParams,
  setIsFromRechargeAct,
  setRechargedAmount,
} = walletSlice.actions

export const walletModalReducer = walletSlice.reducer

// fullPageText
export const getFullPageText = (state: RootState) =>
  selectWallet(state).fullPageText
// pixQrcodeInfo
export const getPixQrCodeInfo = (state: RootState) =>
  selectWallet(state).pixQrcodeInfo
// 是否显示qrcode
export const getShowPixQrCode = (state: RootState) =>
  selectWallet(state).showPixQrCode
export const getShowFullPageLoading = (state: RootState) =>
  selectWallet(state).showFullPageLoading
export const getQuestType = (state: RootState) => selectWallet(state).questType
// 是否显示打码量列表
export const getShowAmountDetails = (state: RootState) =>
  selectWallet(state).showAmountDetails

// 是否来自活动页面
export const selectIsFromAct = (state: RootState) =>
  selectWallet(state).is_from_recharge_act

// 是否参加活动
export const getJoinActFlag = (state: RootState) =>
  selectWallet(state).joinActFlag

//当前币种支持提现方式
export const getUserAssets = (state: RootState) =>
  selectWallet(state).userAssets
//当前提款界面操作方式
export const getHandleType = (state: RootState) =>
  selectWallet(state).handleType
//当前币种支持提现方式
export const getShowAmountDetail = (state: RootState) =>
  selectWallet(state).showAmountDetail
//当前币种支持提现方式
export const getWithdrawalCurrency = (state: RootState) =>
  selectWallet(state).withdrawCurrency
//当前币种支持提现方式
export const getWithdrawalMethods = (state: RootState) =>
  selectWallet(state).withdrawWaysList
// 提现变淡字段
export const getWithdrawFeilds = (state: RootState) =>
  selectWallet(state).withdrawFeilds
//用户当前选择提现方式
export const getWithdrawWays = (state: RootState) =>
  selectWallet(state).withdrawWays
export const getType = (state: RootState) => selectWallet(state).activeTab

export const getActInfo = (state: RootState) => selectWallet(state).actInfo

export const getUseSaveInfo = (state: RootState) =>
  selectWallet(state).useSaveInfo

export const getUseSaveInfoList = (state: RootState) =>
  selectWallet(state).saveList

export const getSelectShow = (state: RootState) =>
  selectWallet(state).showSelectWays

export const getShowVirtualModal = (state: RootState) =>
  selectWallet(state).showVirtualModal

export const getShowVirtualTipsModal = (state: RootState) =>
  selectWallet(state).showVirtualTipsModal

export const getVirtualOrderList = (state: RootState) =>
  selectWallet(state).virtualOrderList

export const getVirtualItemIndex = (state: RootState) =>
  selectWallet(state).virtualItemIndex

export const getCurrentVirtualItem = (state: RootState) =>
  selectWallet(state).currentVirtualItem

export const selectActivedQuickRechargeItemIndex = (state: RootState) =>
  selectWallet(state).activedQuickRechargeItemIndex ?? DEFAULT_INDEX

export const selectActivedQuickPayMethodId = (state: RootState) =>
  selectWallet(state).activedQuickPayMethodId ||
  getUseSaveInfoList(state)[0]?.id

export const selectActivedQuickPayChannel = (state: RootState) =>
  selectWallet(state).activedQuickPayChannel ||
  getUseSaveInfoList(state)[0]?.pay_method

export const selectRechargeAmount = (state: RootState) =>
  selectWallet(state).rechargeAmount ?? 0

/**
 * 充值金额
 */
export const selectRechargedAmount = createSelector(
  selectWallet,
  wallet => wallet.rechargedAmount,
)

/**
 * 充值信息
 */
export const selectRechargeInfo = createSelector(
  selectWallet,
  wallet => wallet.rechargeInfo,
)

/**
 * 充值表单
 */
export const selectRechargeForm = createSelector(
  selectWallet,
  wallet => wallet.rechargeForm,
)

export const selectWithdrawAmount = (state: RootState) =>
  selectWallet(state).withdrawAmount || ''

export const selectWithdrawExchangeRates = (state: RootState) =>
  selectWallet(state).withdrawExchangeRates ?? CURRENCY_RATE[CURRENCY]

export const getRechargeList = (state: RootState) =>
  selectWallet(state).rechargeList

export const getRechargePaymentOptions = (state: RootState) =>
  selectWallet(state).rechargePaymentOptions

export const selectWithdrawHandingFee = (state: RootState) => {
  const vipInfo = selectUserVipInfo(state)
  if (!vipInfo) return 0
  const vipConfigList = selecVipConfigList(state)
  const amount = selectWithdrawAmount(state)
  const currentVipConfig = vipConfigList[vipInfo.level]?.config
  if (!currentVipConfig) return 0
  const freeAmountUsed = Number(vipInfo?.withdraw_free_amount)
  // VIP用户每个月可用的免费额度
  const maxFreeAmount = Number(currentVipConfig.monthly_free_fee_quota)
  const canUseFree = maxFreeAmount - freeAmountUsed // 可用额度
  const result =
    Math.max(amount - canUseFree, 0) *
    Number(currentVipConfig.withdraw_fee_rate)

  return result
}

export const selectRechargeAmountCompatibleWithActivity = createSelector(
  selectRechargedAmount,
  getRechargeAmount,
  getOpenModal,
  (amountInWallet, amountInActivityModal, isActivityModalOpen) =>
    isActivityModalOpen ? Number(amountInActivityModal) : amountInWallet,
)

/** gcash充值提示开关 */
export const selectGcashTipsSwitchStatus = createSelector(
  selectWallet,
  wallet => wallet.gcashTipsOptStatus,
)

export const selectTaskId = (state: RootState) =>
  selectWallet(state).actInfo?.tid?.toString()

export const openWallet = (
  tab?:
    | 'recharge'
    | 'withdraw'
    | {
        type: 'recharge' | 'withdraw'
        index: number
      },
) =>
  openModal({
    key: GlobalModalKey.Wallet,
    data: {
      defaultActivedTabKey:
        typeof tab === 'string' ? tab : tab?.type ?? 'recharge',
    },
  })

// B端获取的快捷充值配置
export const selectWalletConfig = (state: RootState) =>
  selectWallet(state).walletConfig

// B端设置的充值提现范围
export const selectWalletDurationConfig = (state: RootState) =>
  selectWallet(state).walletDurationConfig

//b3充值信息
export const getRecahrgeList = (state: RootState) =>
  selectWallet(state).rechargeInfoList

export const getMxnRecahrgeEmail = (state: RootState) =>
  selectWallet(state).mxnRecahrgeEmail

export const getShowMxnRecahrgeEmail = (state: RootState) =>
  selectWallet(state).showMxnRecahrgeEmail
// 接口是否请求完毕
export const selectWalletDurationLoaded = (state: RootState) =>
  selectWallet(state).walletDurationLoaded

// 提现信息是否支持修改
export const selectWithdrawToast = (state: RootState) =>
  selectWallet(state).withdrawToast
