import type { SystemStyleObject } from '@chakra-ui/react'
import { Input, Text, useNumberInput } from '@chakra-ui/react'
import { floor, isNaN, toNumber, toString } from 'lodash-es'
import type { FC } from 'react'
import { memo, useEffect, useLayoutEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { getLimitedResult, getMax } from '@/modules/inhouse/common/utils'
import { useCustomizedStyle } from '@/utils/hooks'

import {
  balanceInfoAtom,
  gameInfoAtom,
  walletInfoAtom,
} from '../../atoms/common-state'
import { useFormat } from '../../hooks'
import { NAME, PARTS } from './AmountContro'
import { getColorAtoms } from './LogicBet'
import type { PlinkColors } from './state'

const input: SystemStyleObject = {
  textAlign: 'center',
  height: '22px',
  lineHeight: '22px',
  fontSize: '18px',
  fontWeight: '800',
  color: 'seco.500',
}

const AmountInput: FC<{ color: PlinkColors; styles: SystemStyleObject }> = ({
  color,
  styles,
}) => {
  const { amount: amountAtom, isAutoStarted: isAutoStartedAtom } =
    getColorAtoms(color)
  const disabled = useRecoilValue(isAutoStartedAtom)
  const balance = useRecoilValue(balanceInfoAtom)
  const { config } = useRecoilValue(gameInfoAtom)
  const maxValue = getMax(balance, config.maxBetAmount)
  const minValue = config.minBetAmount
  const integer = config.integer ?? true

  const [value, setValue] = useRecoilState(amountAtom)
  const [inputValue, setInputValue] = useState<string>(toString(value))

  const { currency } = useRecoilValue(walletInfoAtom)
  const { addSign } = useFormat()

  const { getInputProps } = useNumberInput({
    onChange: disabled ? undefined : setInputValue,
  })

  const updateInputValue = (v: number) => {
    const nextValue = toString(v)
    if (nextValue !== inputValue) setInputValue(currency.decimalFormat(v))
  }

  const updateAtomValue = (v: number) => {
    setValue(v)
  }

  const handleBlur = () => {
    const nextNumbericValue = toNumber(inputValue)

    if (isNaN(nextNumbericValue)) {
      updateInputValue(value)
    } else {
      let nextValue = getLimitedResult(nextNumbericValue, maxValue, minValue)
      nextValue = integer ? floor(nextValue) : nextValue
      if (nextValue !== value) {
        // 更新value， 由副作用再更新内部状态
        updateAtomValue(nextValue)
        updateInputValue(nextValue)
      } else {
        // 如果相等不更新， 要手动更新已经编辑过后的状态
        updateInputValue(value)
      }
    }
  }

  useLayoutEffect(() => {
    setValue(config.defaultBetAmount)
  }, [config.defaultBetAmount])

  useEffect(() => {
    updateInputValue(value)
  }, [value])

  return (
    <Input
      {...getInputProps()}
      variant='unstyled'
      value={addSign(inputValue, currency.sign)}
      onBlur={handleBlur}
      sx={styles}
    />
  )
}
const MemoAmountInput = memo(AmountInput)

const ConditionInput: FC<{
  color: PlinkColors
}> = ({ color }) => {
  const { input: inputStyle } = useCustomizedStyle(NAME, { input }, PARTS)
  const { amount: amountAtom, isAutoStarted: isAutoStartedAtom } =
    getColorAtoms(color)
  const disabled = useRecoilValue(isAutoStartedAtom)
  const amount = useRecoilValue(amountAtom)
  const { addSign } = useFormat()
  const { currency } = useRecoilValue(walletInfoAtom)
  const textAmount = addSign(amount, currency.sign)

  if (disabled)
    return (
      <Input
        sx={inputStyle}
        value={textAmount}
        variant='unstyled'
        onChange={() => null}
      />
    )

  return <MemoAmountInput color={color} styles={inputStyle} />
}

export default ConditionInput
